import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  button: {
    width: '56px',
    height: '56px',
    color: '#ff6f0b',
    backgroundColor: '#ffffff',
    marginTop: '40px',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#ff6f0b'
    },
    '&:active': {
      color: '#ffffff',
      backgroundColor: '#ff6f0b',
      boxShadow: 'none'
    }
  },
  buttonIcon: {
    color: 'inherit'
  },
  buttonText: {
    fontSize: '14px',
    marginTop: '10px'
  }
});

const ButtonMenu = function({ history, link, icon, text, disabled }) {
  const classes = useStyles();
  return (
    <Grid item xs={6} sm={4}>
      <Grid container>
        <Grid item xs={12}>
          <Fab disabled={disabled} className={classes.button} onClick={() => history.push(link)}>
            <Icon className={clsx(icon, classes.buttonIcon)}></Icon>
          </Fab>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.buttonText}>{text}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

ButtonMenu.propTypes = {
  history: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withRouter(ButtonMenu);
