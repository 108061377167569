import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import * as documentSelectors from './documentSelectors';
import * as documentOperations from './documentOperations';
import documentManager from '../documentManager';
import {
  setActivePolicy,
  setLoadingDocument,
  setActiveProposal,
  updateDocumentService
} from './documentActions';
import { documentStatus } from '../documentConstants';
import { getQuoteConverted } from '../documentShared';

export const useProposalItems = () => {
  const items = useSelector(state => documentSelectors.getProposalsForTable(state));
  return { items };
};

export const prepareProposal = async proposal => {
  for (let participant of proposal.participants) {
    if (!participant.name)
      participant.name = (await documentManager.getPersonById(participant)).name;
  }

  for (let commissionedAgent of proposal.commissionedAgents) {
    if (!commissionedAgent.name)
      commissionedAgent.name = (await documentManager.getPersonById(commissionedAgent)).name;
  }

  return {
    ...proposal,
    quote: getQuoteConverted(proposal.quote)
  };
};

export const useProposalDocumentStatus = () => {
  const proposalDocumentStatus = useSelector(state => state.document.status);
  return proposalDocumentStatus;
};

const proposalDetailsEffect = async (setProposal, store, proposalId) => {
  store.dispatch(setLoadingDocument(true));
  const inStateProposal = store
    .getState()
    .document.proposals.find(_proposal => _proposal.id === proposalId);

  try {
    const proposal = inStateProposal
      ? inStateProposal
      : await documentManager.getProposalById(proposalId);
    const preparedProposal = await prepareProposal(proposal);
    store.dispatch(setActiveProposal(preparedProposal));
    store.dispatch(setLoadingDocument(false));
    return setProposal(preparedProposal);
  } catch (error) {
    store.dispatch(updateDocumentService({ hasError: true }));
    store.dispatch(setLoadingDocument(false));
    setProposal(undefined);
  }
};

export const useProposalDetails = proposalId => {
  const [proposal, setProposal] = useState(null);
  const store = useStore();
  useEffect(() => {
    proposalDetailsEffect(setProposal, store, proposalId);
  }, [proposalId, store]);

  return proposal;
};

export const usePolicyItems = () => {
  const items = useSelector(state => documentSelectors.getPoliciesForTable(state));
  return { items };
};

export const useDocumentIsLoading = () => {
  const isLoading = useSelector(state => state.document.isLoading);
  return isLoading;
};

export const useDocumentServiceStatus = () => {
  const documentService = useSelector(state => state.document.status.documentService);
  return documentService;
};

export const useOperations = () => {
  const dispatch = useDispatch();

  const acceptProposal = useCallback(() => {
    dispatch(documentOperations.acceptProposal());
  }, [dispatch]);

  const downloadProposalPdfUrl = useCallback(() => {
    dispatch(documentOperations.downloadProposalPdfUrl());
  }, [dispatch]);

  const downloadProposalBoletoUrl = useCallback(() => {
    dispatch(documentOperations.downloadDocumentBoletoUrl());
  }, [dispatch]);

  return { acceptProposal, downloadProposalPdfUrl, downloadProposalBoletoUrl };
};

export const useTabState = () => {
  const dispatch = useDispatch();
  const tabState = useSelector(state => documentSelectors.getStateByPath(state));
  const setTabState = (...params) => dispatch(documentOperations.setTabState(...params));
  return { tabState, setTabState };
};

export const preparePolicy = async policy => {
  for (let participant of policy.participants) {
    if (!participant.name)
      participant.name = (await documentManager.getPersonById(participant)).name;
  }

  for (let commissionedAgent of policy.commissionedAgents) {
    if (!commissionedAgent.name)
      commissionedAgent.name = (await documentManager.getPersonById(commissionedAgent)).name;
  }

  return {
    ...policy,
    status: policy.issuanceAt ? documentStatus.DONE : documentStatus.PENDING,
    quote: getQuoteConverted(policy.quote)
  };
};

const policyEffect = async (setPolicy, store, policyId) => {
  const inStatePolicy = store.getState().document.policies.find(_policy => _policy.id === policyId);
  try {
    const policy = inStatePolicy ? inStatePolicy : await documentManager.getPolicyById(policyId);
    const preparedPolicy = await preparePolicy(policy);
    store.dispatch(setActivePolicy(preparedPolicy));
    return setPolicy({ loading: false, error: undefined, data: preparedPolicy });
  } catch (error) {
    setPolicy({ loading: false, error, data: undefined });
  }
};

export const usePolicy = policyId => {
  const [policy, setPolicy] = useState({ loading: true, error: undefined, data: undefined });
  const store = useStore();
  useEffect(() => {
    policyEffect(setPolicy, store, policyId);
  }, [policyId, store]);

  return policy;
};

export const usePolicyOwnerId = () => {
  const policyOwnerId = useSelector(state => state.document.policyOwnerId);
  return policyOwnerId;
};

export const useDocumentNumber = () => {
  const documentNumber = useSelector(state => state.document.documentNumber);
  return documentNumber;
};

export const usePolicyNumber = () => {
  const policyNumber = useSelector(state => state.document.policyNumber);
  return policyNumber;
};

export const usePolicySequentialNumber = () => {
  const policySequentialNumber = useSelector(state => state.document.policySequentialNumber);
  return policySequentialNumber;
};

export const useLabelName = () => {
  const labelName = useSelector(state => state.document.labelName);
  return labelName;
};

export const useLastProposalPolicyOwnerId = () => {
  const lastProposalPolicyOwnerId = useSelector(state => state.document.lastProposalPolicyOwnerId);
  return lastProposalPolicyOwnerId;
};

export const useLastPolicyPolicyOwnerId = () => {
  const lastPolicyPolicyOwnerId = useSelector(state => state.document.lastPolicyPolicyOwnerId);
  return lastPolicyPolicyOwnerId;
};
