import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    width: 40,
    height: 'auto'
  },
  message: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 70px'
    }
  }
}));

const MessageModalContainer = ({ message, icon }) => {
  const styles = useStyles();
  const Icon = icon;
  return (
    <>
      <Grid item xs={12}>
        <Grid className={styles.iconContainer} container justify="center" alignItems="center">
          <Grid item>{icon && <Icon className={styles.icon} />}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography className={styles.message} align="center">
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MessageModalContainer;
