import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const useFormInputStyles = makeStyles({
  formControl: {
    marginTop: 15
  },
  input: {
    width: '100%',
    fontWeight: 400,
    marginBottom: 0,
    marginTop: 10
  }
});

const validateInput = (send, field) => {
  return send && field === '';
};

const FormInput = function({ label, value, name, validateProposal, setAddress, required }) {
  const classes = useFormInputStyles();
  const error = validateInput(validateProposal, value);
  return (
    <Grid item xs={12} sm={5} className={classes.formControl}>
      <TextField
        label={label}
        value={value}
        error={required && error}
        onChange={e => {
          setAddress({ [name]: e.target.value });
        }}
        margin="normal"
        required={required}
        className={classes.input}
      />
      {required && error && <FormHelperText error={error}>Campo obrigatório</FormHelperText>}
    </Grid>
  );
};

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validateProposal: PropTypes.bool.isRequired,
  setAddress: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired
};

export default FormInput;
