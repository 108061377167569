import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { quoteReducer } from '../features/quote/redux';
import { proposalReducer } from '../features/proposal/redux';
import { documentReducer } from '../features/document/redux';
import { spreadsheetReducer } from '../features/spreadsheet/redux';

export default history =>
  combineReducers({
    router: connectRouter(history),
    quote: quoteReducer,
    proposal: proposalReducer,
    document: documentReducer,
    spreadsheet: spreadsheetReducer
  });
