import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { routes } from '../../../shared/constants/routes';
import Modal from '../../../shared/components/Modal';
import { muiIcons } from '../../../shared/constants/icons';
import { coveragesValidator } from '../redux/quoteOperations';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
  button: {
    width: '180px',
    fontSize: '12px',
    padding: '10px 0 10px 0',
    margin: '5px 0 5px 0',
    borderRadius: 8
  },
  lastButton: {
    color: 'inherit'
  }
});

const ReceiptCardButtons = function({ allowHiring, quoteId }) {
  const classes = useStyles();
  const { push } = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { coverages } = useSelector(state => state.quote.quote);

  const validator = (quoteId, push) => {
    const response = coveragesValidator(coverages, dispatch);
    response && push(`${routes.proposal.path}/${quoteId}`);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="center">
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={() => validator(quoteId, push)}
              disabled={!allowHiring}
            >
              Quero Contratar
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="center">
            <Button
              className={clsx(classes.button, classes.lastButton)}
              color="primary"
              onClick={() => setIsModalOpen(true)}
            >
              Nova Cotação
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        icon={{ component: muiIcons.WARNING }}
        message={
          'Deseja realmente sair da cotação atual? Todos os dados não salvos serão perdidos!'
        }
        actions={[
          {
            text: 'Cancelar',
            isConfirm: false,
            onClick: () => {
              setIsModalOpen(false);
            }
          },
          {
            text: 'Voltar para o início',
            isConfirm: true,
            onClick: () => {
              push(`${routes.newQuote.path}?new`);
              setIsModalOpen(false);
            }
          }
        ]}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

ReceiptCardButtons.propTypes = {
  allowHiring: PropTypes.bool,
  quoteId: PropTypes.string.isRequired
};

export default ReceiptCardButtons;
