import { routes } from '../../shared/constants/routes';

export const tabStates = {
  0: routes.proposalsDocuments.path,
  1: routes.policiesDocuments.path
};

export const tabPaths = {
  [routes.proposalsDocuments.path]: 0,
  [routes.policiesDocuments.path]: 1
};

export const documentStatus = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  DONE: 'DONE',
  ISSUED: 'ISSUED',
  EMITTING: 'EMITTING',
  REJECTED: 'REJECTED',
  READY: 'READY'
};
