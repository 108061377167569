import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CoverageCard from './CoverageCard';
import { routes } from '../../../shared/constants/routes';
import Modal from '../../../shared/components/Modal';
import { muiIcons } from '../../../shared/constants/icons';

const useStyles = makeStyles({
  coveragesContainer: {
    marginTop: 25
  }
});

const useModal = function() {
  const [modalProps, setModalProps] = useState({
    icon: { component: muiIcons.WARNING },
    action: [],
    message: '',
    isOpen: false
  });

  return { modalProps, setModalProps };
};

const CoverageCardGrid = function({
  coverages,
  getQuote,
  updateCoverage,
  isLoading,
  quoteServiceError,
  setQuoteService
}) {
  const classes = useStyles();
  const { modalProps, setModalProps } = useModal();

  const setModal = useCallback(
    ({ actions, message, icon, isOpen }) => {
      setModalProps({
        actions,
        message,
        icon,
        isOpen
      });
    },
    [setModalProps]
  );

  useEffect(() => {
    if (quoteServiceError) {
      setModal({
        actions: [
          {
            text: 'Ok',
            isConfirm: true,
            onClick: () => {
              setModalProps({ isOpen: false });
              setQuoteService({ hasError: false });
            }
          }
        ],
        message: 'Erro! Ocorreu algum problema interno. Tente novamente mais tarde.',
        icon: { component: muiIcons.ERROR },
        isOpen: true
      });
    }
  }, [quoteServiceError, setQuoteService, setModal, setModalProps]);

  if (_.isEmpty(coverages)) return <Redirect to={routes.home.path} />;
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            className={classes.coveragesContainer}
            container
            justify="center"
            alignItems="center"
            spacing={2}
          >
            {coverages.map(coverage => (
              <Grid item key={coverage.id}>
                <CoverageCard
                  coverage={coverage}
                  getQuote={getQuote}
                  updateCoverage={updateCoverage}
                  isLoading={isLoading}
                  quoteServiceError={quoteServiceError}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        icon={modalProps.icon}
        message={modalProps.message}
        actions={modalProps.actions}
        open={modalProps.isOpen}
        onClose={() => {
          setModalProps({ isOpen: false });
          setQuoteService({ hasError: false });
        }}
      />
    </>
  );
};

CoverageCardGrid.propTypes = {
  coverages: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  quoteServiceError: PropTypes.bool.isRequired,
  setQuoteService: PropTypes.func.isRequired
};

export default CoverageCardGrid;
