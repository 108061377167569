import axios from 'axios';
import { CPF_SIZE, CNPJ_SIZE } from '../../shared/constants/constants';

const getDocumentType = ({ documentNumber }) => {
  if (documentNumber.length <= CPF_SIZE) return 'cpf';
  else if (documentNumber.length > CPF_SIZE && documentNumber.length <= CNPJ_SIZE) return 'cnpj';
};

class ProposalService {
  getAddress = async cep => {
    const response = await axios.create().get(`https://viacep.com.br/ws/${cep}/json`);

    return response.data;
  };

  getPersonById = async id => {
    const response = await axios.get(`${window.env.REACT_APP_PERSON_ENDPOINT}/api/persons/${id}`);

    return response.data;
  };

  getPersonByDocument = async documentNumber => {
    const response = await axios.get(
      `${window.env.REACT_APP_PERSON_ENDPOINT}/api/persons/${getDocumentType({
        documentNumber
      })}/${documentNumber}`
    );

    return response.data;
  };

  createPerson = async ({ person }) => {
    const personResponse = await axios.post(`${window.env.REACT_APP_PERSON_ENDPOINT}/api/persons`, {
      ...person
    });

    return personResponse.data;
  };

  createProposal = async ({ proposal }) => {
    const proposalResponse = await axios.post(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals`,
      proposal
    );

    return proposalResponse.data;
  };

  createProposalPdf = async ({ proposalId }) => {
    const proposalPdf = await axios.post(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/${proposalId}/pdf`
    );
    return proposalPdf.data;
  };

  addPaymentProposal = async ({ proposalId, paymentPayload }) => {
    const paymentProposal = await axios.post(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/${proposalId}/payments`,
      paymentPayload
    );

    return paymentProposal.data;
  };

  createPolicy = async ({ policy }) => {
    const policyResponse = await axios.post(
      `${window.env.REACT_APP_ISSUANCE_ENDPOINT}/api/policies`,
      policy
    );

    return policyResponse.data;
  };

  acceptProposal = async ({ proposalId, acceptPayload }) => {
    const proposalResponse = await axios.post(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/${proposalId}/accept`,
      acceptPayload
    );

    return proposalResponse.data;
  };

  getDocumentUrl = async ({ documentFileName, templateKey }) => {
    const documentResponse = await axios.get(
      `${window.env.REACT_APP_DOCUMENT_ENDPOINT}/api/documents/storage/${documentFileName}?templateKey=${templateKey}`
    );

    return documentResponse.data;
  };

  getUserProfile = async ({ organizationId }) => {
    const response = await axios.get(
      `${window.env.REACT_APP_USERPROFILE_ENDPOINT}/api/organization-profiles/${organizationId}`
    );

    return response.data;
  };

  issuePolicy = async ({ policyId }) =>
    await axios.post(`${window.env.REACT_APP_ISSUANCE_ENDPOINT}/api/policies/${policyId}/issuance`);
}

export default new ProposalService();
