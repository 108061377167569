import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { proposalActions } from '../redux';
import PolicyPeriodBlock from './PolicyPeriodBlock';

const mapStateToProps = ({ proposal }) => {
  return {
    policyPeriod: { ...proposal.policyPeriod },
    validateProposal: proposal.validateProposal
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPolicyPeriod: proposalActions.setPolicyPeriod
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyPeriodBlock);
