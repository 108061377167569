import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as documentActions from '../../redux/documentActions';
import { getProposals } from '../../redux/documentOperations';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '../../../../shared/components/Modal';
import { muiIcons } from '../../../../shared/constants/icons';
import { palette } from '../../../../shared/styles/theme';
import { DocumentDetailsButton } from './DocumentDetailsButton';
import { documentStatus } from '../../documentConstants';

const useStyles = makeStyles({
  container: {
    margin: '30px 0px 20px 0',
    '&> *:not(:last-child)': {
      marginRight: 15
    }
  },
  gridItem: {
    marginLeft: 20
  },
  downloadButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: palette.primary.main,
    borderColor: palette.primary.main,
    border: '1px solid',
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#ff6f0b'
    },
    '&:disabled': {
      borderColor: 'transparent'
    }
  }
});

const shouldDisplayRightButton = status =>
  status !== documentStatus.ISSUED && status !== documentStatus.DONE;

const shouldDisplayBottomButtons = status => status !== documentStatus.REJECTED;

const isProposalAccepted = status => status === documentStatus.ACCEPTED;

const NextStatusButton = function({
  disabled,
  proposal,
  downloadProposalBoletoUrl,
  acceptProposal
}) {
  if (!shouldDisplayRightButton(proposal.status)) return <></>;

  const [handleClick, buttonText] = isProposalAccepted(proposal.status)
    ? [() => downloadProposalBoletoUrl(proposal.documentName), 'Baixar boleto']
    : [acceptProposal, 'Aceitar proposta'];

  return (
    <DocumentDetailsButton
      disabled={isProposalAccepted(proposal.status)}
      color="primary"
      variant="contained"
      onClick={handleClick}
    >
      {buttonText}
    </DocumentDetailsButton>
  );
};

const ProposalButtonsBlock = function({
  proposal,
  isLoading,
  acceptProposal,
  downloadProposalPdfUrl,
  downloadProposalBoletoUrl,
  documentService
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [modalProps, setModalProps] = useState({
    icon: { component: muiIcons.WARNING },
    action: [],
    message: '',
    isOpen: false
  });

  useEffect(() => {
    if (documentService.hadSucceeded && proposal.status === documentStatus.ACCEPTED) {
      setModalProps({
        actions: [
          {
            text: `OK`,
            isConfirm: true,
            onClick: async () => {
              dispatch(documentActions.updateDocumentService({ hadSucceeded: false }));
              setModalProps({ isOpen: false });
              dispatch(getProposals());
            }
          }
        ],
        message: 'A proposta foi aceita com sucesso!',
        icon: { component: muiIcons.CHECKED },
        isOpen: true
      });
    }
  }, [documentService, proposal.status, dispatch]);

  return (
    <>
      {shouldDisplayBottomButtons(proposal.status) && (
        <Grid container justify="flex-end" className={classes.container}>
          <DocumentDetailsButton
            disabled={isLoading || !proposal.documentName}
            className={shouldDisplayRightButton(proposal.status) && classes.downloadButton}
            color="primary"
            variant="contained"
            onClick={() => {
              downloadProposalPdfUrl(proposal.documentName);
            }}
            tooltip={
              !proposal.documentName
                ? { title: 'Documento não disponível.', placement: 'bottom' }
                : undefined
            }
          >
            Baixar proposta
          </DocumentDetailsButton>

          <NextStatusButton
            disabled={isLoading}
            proposal={proposal}
            downloadProposalBoletoUrl={downloadProposalBoletoUrl}
            acceptProposal={acceptProposal}
          />
        </Grid>
      )}

      <Modal
        icon={modalProps.icon}
        message={modalProps.message}
        actions={modalProps.actions}
        open={modalProps.isOpen}
        onClose={() => {
          setModalProps({ isOpen: false });
          if (documentService.hasError)
            dispatch(documentActions.updateDocumentService({ hasError: false }));
        }}
      />
    </>
  );
};

ProposalButtonsBlock.propTypes = {
  proposal: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  acceptProposal: PropTypes.func.isRequired,
  downloadProposalPdfUrl: PropTypes.func.isRequired,
  downloadProposalBoletoUrl: PropTypes.func.isRequired,
  documentService: PropTypes.object.isRequired
};

export default ProposalButtonsBlock;
