import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import ColorfulCheckbox from '../../../shared/components/ColorfulCheckbox';

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 14,
    display: 'inline'
  },
  link: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
    paddingLeft: 5
  }
}));

const ClientAwareBlock = function({ clientAware, setClienteAware }) {
  const classes = useStyles();

  return (
    <>
      <ColorfulCheckbox checked={clientAware} onChange={setClienteAware} />
      <Typography className={clsx(classes.text)}>
        Estou ciente das responsabilidades legais quanto ao envio das informaçõs acima.
      </Typography>
      <Link
        href={window.env.REACT_APP_KNOWN_MORE_PDF}
        target="_blank"
        className={clsx(classes.text, classes.link)}
      >
        Quero saber mais
      </Link>
    </>
  );
};

ClientAwareBlock.propTypes = {
  setClienteAware: PropTypes.func.isRequired
};

export default ClientAwareBlock;
