import { batch } from 'react-redux';
import spreadsheetManager from '../spreadsheetManager';
import * as spreadsheetActions from './spreadsheetActions';
import { splitDuplicateRows } from '../normalize/splitDuplicateRows';
import { fetchStatus } from '../../../shared/constants/constants';

export const createPolicies = async (rows, dispatch) => {
  dispatch(spreadsheetActions.cleanMessageLog());
  dispatch(spreadsheetActions.setLoading(true));
  rows = removeHeader(rows);
  rows = removeEmptyRows(rows);
  rows = indexRows(rows);
  const [uniqueRows, duplicateRows] = splitDuplicateRows(rows);
  const response = await spreadsheetManager.createPolicies(uniqueRows);

  const totalResults = concatResultsAndSortByIndex(duplicateRows, response.result);

  totalResults.forEach(item => {
    !item.result &&
      dispatch(spreadsheetActions.setMessageLog(`Linha ${item.rowNumber + 2}: ${item.errors}`));
  });

  dispatch(spreadsheetActions.setLoading(false));
  dispatch(spreadsheetActions.setModal(true));
};

export const getIntegrationStatus = async dispatch => {
  try {
    dispatch(spreadsheetActions.setLoadingIntegrationStatus(true));
    let response = await spreadsheetManager.getIntegrationStatus();

    batch(() => {
      dispatch(spreadsheetActions.setIntegrationStatusData(response));
      dispatch(spreadsheetActions.setIntegrationFetchStatus(fetchStatus.fulfilled));
    });
  } catch {
    dispatch(spreadsheetActions.setIntegrationFetchStatus(fetchStatus.rejected));
  } finally {
    dispatch(spreadsheetActions.setLoadingIntegrationStatus(false));
  }
};

export const indexRows = rows => {
  return rows.map((value, index) => {
    value['index'] = index;
    return value;
  });
};

export const concatResultsAndSortByIndex = (duplicateRows, uniqueRows) => {
  return duplicateRows
    .map(row => ({
      result: null,
      rowNumber: row[row.length - 1]
    }))
    .concat(uniqueRows || [])
    .sort((a, b) => a.rowNumber - b.rowNumber);
};

export const removeHeader = rows => {
  return rows.filter((item, index) => index);
};

export const removeEmptyRows = rows => {
  return rows.filter(row => row.length);
};
