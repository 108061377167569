import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import { ExcelRenderer } from 'react-excel-renderer';
import { spreadsheetOperations } from '../redux';
import Modal from '../../../shared/components/Modal';
import { muiIcons } from '../../../shared/constants/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as spreadsheetActions from '../redux/spreadsheetActions';
import IntegrationStatusContainer from './IntegrationStatusContainer';

const useStyles = makeStyles({
  bodyGrid: {
    height: '100%'
  },
  paper: {
    width: '800px',
    borderRadius: '6px'
  },
  pageContent: {
    textAlign: 'center',
    padding: '13px 0'
  },
  title: {
    fontSize: '26px',
    marginTop: '5px'
  },
  spacing: {
    margin: '25px 0'
  },
  error: {
    fontSize: '11px',
    color: 'red'
  },
  logImport: {
    maxHeight: '20rem',
    overflow: 'auto',
    listStyle: 'none',
    fontSize: '0.8em',
    color: 'red'
  },
  lineError: {
    textAlign: 'left',
    margin: 'auto 15%'
  }
});

const PageContent = function() {
  const classes = useStyles();

  const importFileRef = React.useRef();
  const [, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [modalProps, setModalProps] = useState({ isOpen: false });
  const dispatch = useDispatch();
  const { messagesLog } = useSelector(state => state.spreadsheet);
  const { loading } = useSelector(state => state.spreadsheet);

  const { modalIsOpen } = useSelector(state => state.spreadsheet);

  const fileHandler = event => {
    let fileObj = event.target.files[0];

    const formatFile = type => fileObj.name.indexOf(type);

    if (formatFile('.xlsx') !== -1 || formatFile('.xls') !== -1 || formatFile('.csv') !== -1) {
      ExcelRenderer(fileObj, (err, resp) => {
        if (!err) {
          setCols(resp.cols);
          setRows(resp.rows);
        }
      });

      setDisabled(false);
      setError(false);
    } else {
      setDisabled(true);
      setError(true);
    }
  };

  const handleModalImportData = () => {
    setModalProps({
      actions: [
        {
          text: 'Cancelar',
          isConfirm: true,
          onClick: () => {
            setModalProps({ isOpen: false });
          }
        },
        {
          text: 'Importar',
          isConfirm: true,
          onClick: () => {
            importData();
          }
        }
      ],

      message: 'Tem certeza que deseja importar os dados do arquivo selecionado?',
      icon: { component: muiIcons.ERROR },
      isOpen: true
    });
  };

  const importData = async () => {
    setDisabled(true);
    setModalProps({ isOpen: false });
    await spreadsheetOperations.createPolicies(rows, dispatch);
    await spreadsheetOperations.getIntegrationStatus(dispatch);
    importFileRef.current.value = '';
  };

  return (
    <>
      <Grid className={classes.pageContent} container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography className={classes.title}>Importação de planilhas</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.spacing}>
            Selecione o arquivo excel a ser importado:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <input
            type="file"
            id="importFile"
            onChange={fileHandler}
            ref={importFileRef}
            style={{ padding: '10px' }}
          />
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography className={classes.error}>Arquivo inválido</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          {messagesLog.length > 0 && (
            <Grid>
              <Typography>{messagesLog.length} erros encontrados</Typography>
            </Grid>
          )}
          <Grid className={classes.logImport}>
            {messagesLog.map(message => (
              <li className={classes.lineError} key={message}>
                {message}
              </li>
            ))}
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalImportData}
            className={classes.spacing}
            disabled={disabled || loading}
          >
            Importar dados
          </Button>
        </Grid>
      </Grid>

      <IntegrationStatusContainer />

      <Modal
        icon={modalProps.icon}
        message={modalProps.message}
        actions={modalProps.actions}
        open={modalProps.isOpen}
        onClose={() => {
          setModalProps({ isOpen: false });
        }}
      />

      <Modal
        icon={{ component: muiIcons.ERROR }}
        message={'Importação finalizada!'}
        actions={[
          {
            text: 'OK',
            isConfirm: true,
            onClick: () => dispatch(spreadsheetActions.setModal(false))
          }
        ]}
        open={modalIsOpen || false}
        onClose={() => {
          dispatch(spreadsheetActions.setModal(false));
        }}
      />
    </>
  );
};

export const SpreadsheetPage = function({ width }) {
  const classes = useStyles();

  return (
    <Grid className={classes.bodyGrid} container justify="center" alignItems="center">
      {width !== 'xs' ? (
        <Paper className={classes.paper} elevation={3}>
          <PageContent />
        </Paper>
      ) : (
        <Grid item xs={12}>
          <PageContent />
        </Grid>
      )}
    </Grid>
  );
};

export default withWidth()(SpreadsheetPage);
