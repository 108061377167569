import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { proposalActions, proposalOperations } from '../redux';
import UserBeneficiaryBlock from './UserBeneficiaryBlock';

const mapStateToProps = ({ proposal }) => {
  return {
    userBeneficiary: { ...proposal.userBeneficiary },
    personServiceError: proposal.status.personServiceError,
    validateProposal: proposal.validateProposal
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserBeneficiary: proposalActions.setUserBeneficiary,
      getBeneficiaryPerson: proposalOperations.getBeneficiaryPerson,
      setPersonServiceError: proposalActions.setPersonServiceError,
      cleanProposal: proposalActions.cleanProposal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBeneficiaryBlock);
