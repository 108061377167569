import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 10
  },
  title: {
    padding: '30px 35px 20px 35px'
  },
  itemTitleTypography: {
    marginLeft: 15,
    fontFamily: 'Inter, sans-serif'
  },
  titleTypography: {
    fontFamily: 'Inter, sans-serif'
  },
  divider: {
    padding: '0 35px'
  },
  children: {
    padding: '35px 65px 45px 65px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 35px 40px 35px'
    }
  }
}));

function CollapsableBlock({ title, icon, children }) {
  const classes = useStyles();

  const Icon = icon && icon.component;
  const iconStyle = icon ? icon.style : {};

  return (
    <Paper className={classes.paper} elevation={5}>
      <Grid container>
        <Grid item className={classes.title} xs={12}>
          <Grid container justify="flex-start" alignItems="center">
            <Grid item>{icon && <Icon className={classes.icon} style={iconStyle} />}</Grid>
            <Grid item className={classes.itemTitleTypography}>
              <Typography className={classes.titleTypography} variant="h6">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.divider}>
          <Divider />
        </Grid>
        <Grid item xs={12} className={classes.children}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
}

CollapsableBlock.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  children: PropTypes.element
};

export default CollapsableBlock;
