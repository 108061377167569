import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { proposalActions, proposalOperations } from '../redux';
import AddressBlock from './AddressBlock';

const mapStateToProps = ({ proposal }) => {
  return {
    address: { ...proposal.address },
    validateProposal: proposal.validateProposal
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAddress: proposalActions.setAddress,
      getAddress: proposalOperations.getAddress
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddressBlock);
