export const LIST_QUESTIONS = 'quote/LIST_QUESTIONS';
export const SET_ACTIVE_QUESTION = 'quote/SET_ACTIVE_QUESTION';
export const SET_ANSWER = 'quote/SET_ANSWER';
export const SET_QUOTE = 'quote/SET_QUOTE';
export const SET_PRODUCT = 'quote/SET_PRODUCT';
export const CLEAN_QUOTE = 'quote/CLEAN_QUOTE';
export const SET_QUOTE_SERVICE = 'quote/SET_QUOTE_SERVICE';
export const SET_LOADING_QUOTE = 'quote/SET_LOADING_QUOTE';
export const SET_USER_PROFILE = 'quote/SET_USER_PROFILE';

export const listQuestions = questions => ({
  type: LIST_QUESTIONS,
  payload: questions
});

export const setActiveQuestion = position => ({
  type: SET_ACTIVE_QUESTION,
  payload: position
});

export const setAnswer = answer => ({
  type: SET_ANSWER,
  payload: answer
});

export const setQuote = newQuote => ({
  type: SET_QUOTE,
  payload: newQuote
});

export const setProduct = product => ({
  type: SET_PRODUCT,
  payload: product
});

export const cleanQuote = () => ({
  type: CLEAN_QUOTE
});

export const setQuoteService = quoteService => ({
  type: SET_QUOTE_SERVICE,
  payload: quoteService
});

export const setLoadingQuote = isLoading => ({
  type: SET_LOADING_QUOTE,
  payload: isLoading
});

export const setUserProfile = userProfile => ({
  type: SET_USER_PROFILE,
  payload: userProfile
});
