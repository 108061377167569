import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DetailsBackground from './DetailsBackground';
import GeneralInfoBlock from './details/GeneralInfoBlock';
import AddressBlock from './details/AddressBlock';
import ParticipantsBlock from './details/ParticipantsBlock';
import PricePaymentBlock from './details/PricePaymentBlock';
import CoveragesBlock from './details/CoveragesBlock';
import { DocumentDetailsSkeleton } from './DocumentDetailsSkeleton';
import { useParams } from 'react-router-dom';
import {
  usePolicy,
  useDocumentIsLoading,
  useDocumentServiceStatus
} from '../redux/documentCustomHooks';
import { setActivePolicy, updateDocumentService } from '../redux/documentActions';
import documentManager from '../documentManager';
import { DocumentDetailsButton } from './details/DocumentDetailsButton';
import Modal from '../../../shared/components/GenericModal';
import { muiIcons } from '../../../shared/constants/icons';
import MessageModalContainer from '../../../shared/components/MessageModalContainer';
import CancelModalContainer from './CancelModalContainer';
import { isValid } from 'date-fns';

import * as documentOperations from '../redux/documentOperations';

const useErrorStyles = makeStyles({
  root: {
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)'
  }
});

const useAlertStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: '#FFF',
    padding: '25px 50px',
    fontFamily: 'Inter, sans-serif'
  },
  icon: {
    color: '#FFF !important'
  }
}));

const useButtonStyles = makeStyles(theme => ({
  displayNone: {
    display: 'none'
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.cancel.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.cancel.dark
    }
  },
  margin: {
    marginLeft: 10
  }
}));

const PolicyDetailsPage = () => {
  const dispatch = useDispatch();
  const { policyId } = useParams();
  const { loading, error, data: policy } = usePolicy(policyId);
  const snackbarClasses = useErrorStyles();
  const muiAlertClasses = useAlertStyles();
  const buttonStyles = useButtonStyles();
  const [modalProps, setModalProps] = useState({ isOpen: false, type: undefined });
  const [isSendCancellmentDisable, setIsSendCancellmentDisable] = useState(true);
  const [endorsementStartDate, setEndorsementStartDate] = useState(new Date());
  const [cancellmentReason, setCancellmentReason] = useState('');
  const isDocumentLoading = useDocumentIsLoading();
  const documentService = useDocumentServiceStatus();

  const handleDownloadPolicyPdf = useCallback(
    () =>
      documentManager.downloadPolicyPdf(policy?.documentName).catch(() => {
        setModalProps({
          actions: [
            {
              text: 'OK',
              isConfirm: true,
              onClick: () => {
                setModalProps({ isOpen: false });
              }
            }
          ],
          isOpen: true,
          type: 'MESSAGE',
          content: (
            <MessageModalContainer
              message="Erro! Ocorreu algum problema interno. Tente novamente mais tarde."
              icon={muiIcons.ERROR}
            />
          )
        });
      }),
    [policy]
  );

  const cleanCancellmentData = () => {
    setEndorsementStartDate(new Date());
    setCancellmentReason('');
  };

  const handleCancelPolicy = useCallback(async () => {
    cleanCancellmentData();
    dispatch(documentOperations.cancelPolicy(cancellmentReason, endorsementStartDate));
  }, [dispatch, cancellmentReason, endorsementStartDate]);

  const handleSetEndorsementStartDate = date => {
    const _date = isValid(date) ? new Date(date.setHours(0, 0, 0, 0)) : null;
    setEndorsementStartDate(_date);
  };

  const handleSetCancellmentReason = event => {
    setCancellmentReason(event?.target?.value);
  };

  const handleIsSendCancellmentDisable = useCallback(() => {
    const state = !(isValid(endorsementStartDate) && cancellmentReason !== '');
    setIsSendCancellmentDisable(state);
  }, [endorsementStartDate, cancellmentReason, setIsSendCancellmentDisable]);

  useEffect(() => {
    handleIsSendCancellmentDisable();
  }, [endorsementStartDate, cancellmentReason, handleIsSendCancellmentDisable]);

  const handleModalCancelPolicy = useCallback(() => {
    setModalProps({
      actions: [
        {
          text: 'Cancelar',
          isConfirm: false,
          onClick: () => {
            cleanCancellmentData();
            setIsSendCancellmentDisable(true);
            setModalProps({ isOpen: false });
          }
        },
        {
          text: 'Enviar',
          isConfirm: true,
          btnDisabled: isSendCancellmentDisable,
          onClick: handleCancelPolicy
        }
      ],
      isOpen: true,
      type: 'CANCELLMENT',
      content: (
        <CancelModalContainer
          endorsementStartDate={endorsementStartDate}
          cancellmentReason={cancellmentReason}
          handleSetEndorsementStartDate={handleSetEndorsementStartDate}
          handleSetCancellmentReason={handleSetCancellmentReason}
          setIsSendCancellmentDisable={setIsSendCancellmentDisable}
        />
      )
    });
  }, [isSendCancellmentDisable, handleCancelPolicy, endorsementStartDate, cancellmentReason]);

  useEffect(() => {
    if (modalProps.isOpen && modalProps.type === 'CANCELLMENT') handleModalCancelPolicy();
  }, [modalProps.isOpen, modalProps.type, handleModalCancelPolicy]);

  useEffect(() => {
    if (documentService.hasError) {
      setModalProps({
        actions: [
          {
            text: 'OK',
            isConfirm: true,
            onClick: () => {
              dispatch(updateDocumentService({ hasError: false, hadSucceeded: false }));
              setModalProps({ isOpen: false });
            }
          }
        ],
        isOpen: true,
        content: (
          <MessageModalContainer
            message="Erro! Ocorreu algum problema interno. Tente novamente mais tarde."
            icon={muiIcons.ERROR}
          />
        )
      });
    }

    if (documentService.hadSucceeded) {
      setModalProps({
        actions: [
          {
            text: 'OK',
            isConfirm: true,
            onClick: () => {
              dispatch(updateDocumentService({ hasError: false, hadSucceeded: false }));
              setModalProps({ isOpen: false });
            }
          }
        ],
        isOpen: true,
        content: (
          <MessageModalContainer
            message="Solicitação de cancelamento enviado com sucesso!."
            icon={muiIcons.SUCCESS}
          />
        )
      });
    }
  }, [documentService, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setActivePolicy({}));
    };
  }, [dispatch]);

  return (
    <DetailsBackground title="Detalhes da Apólice">
      {loading || isDocumentLoading ? (
        <DocumentDetailsSkeleton />
      ) : error ? (
        <Snackbar open autoHideDuration={6000} className={snackbarClasses.root}>
          <Alert severity="error" classes={muiAlertClasses}>
            Não foi possível carregar esta página. Talvez esta página não exista ou não está
            disponível no momento
          </Alert>
        </Snackbar>
      ) : (
        <>
          <GeneralInfoBlock
            document={policy}
            documentType="Apólice"
            documentNumber={policy.policyNumber}
          />
          <AddressBlock address={policy.attributes.riskLocation} />
          <ParticipantsBlock
            insured={policy.participants.find(participant => participant.role === 'Insured')}
            beneficiary={policy.participants.find(
              participant => participant.role === 'Beneficiary'
            )}
            brokers={policy.commissionedAgents.filter(agent => agent.role === 'Broker')}
          />
          <CoveragesBlock coverages={policy.coverages} />
          <PricePaymentBlock quote={policy.quote} />
          <Grid container justify="flex-end">
            <DocumentDetailsButton
              color="primary"
              variant="contained"
              onClick={handleModalCancelPolicy}
              className={`${buttonStyles.margin} ${
                policy.endorsementType ? buttonStyles.displayNone : buttonStyles.cancelButton
              }`}
            >
              Cancelar Apólice
            </DocumentDetailsButton>
            <DocumentDetailsButton
              color="primary"
              variant="contained"
              className={buttonStyles.margin}
              disabled={!policy.issuanceAt}
              onClick={handleDownloadPolicyPdf}
            >
              {policy.issuanceAt ? 'Baixar apólice' : 'Apólice em emissão'}
            </DocumentDetailsButton>
          </Grid>

          <Modal
            actions={modalProps.actions}
            open={modalProps.isOpen}
            content={modalProps.content}
            onClose={() => {
              setModalProps({ isOpen: false });
            }}
          />
        </>
      )}
    </DetailsBackground>
  );
};

export default PolicyDetailsPage;
