import React from 'react';
import PropType from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = zIndex =>
  makeStyles({
    logo: {
      display: 'inline-block',
      position: 'absolute',
      width: 'auto',
      height: 30,
      top: 38,
      left: 30,
      zIndex
    }
  });

const FloatingLogo = function({ zIndex }) {
  const classes = useStyles(zIndex)();
  return (
    <img
      className={classes.logo}
      alt="Logo Pottencial"
      src={`${process.env.PUBLIC_URL}/assets/logo_pottencial.svg`}
    ></img>
  );
};

FloatingLogo.propTypes = {
  zIndex: PropType.number.isRequired
};

export default FloatingLogo;
