import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const DocumentTableCell = withStyles({
  head: {
    backgroundColor: '#EEF3F9',
    color: '#5E6981',
    fontSize: 11,
    padding: '7px 16px',
    height: 23,
    fontWeight: 700,
    borderBottom: 0
  },
  body: {
    fontSize: 13,
    color: '#5E6981',
    height: 27,
    textTransform: 'capitalize',
    padding: '10px 12px'
  }
})(TableCell);

export default DocumentTableCell;
