import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  Avatar,
  Divider,
  Paper,
  Box,
  makeStyles,
  withStyles
} from '@material-ui/core';
import ReceiptCardButtons from './ReceiptCardButtons';
import ReceiptCardParticipationInput from './ReceiptCardParticipationInput';
import * as operations from '../redux/quoteOperations';
import BaseReceiptItem from '../../../shared/components/ReceiptItem';
import CurrencyFormat from '../../../shared/components/Currency';

const useStyles = makeStyles({
  paper: {
    width: '315px',
    marginBottom: '15px'
  },
  container: {
    padding: '20px 40px'
  },
  avatar: {
    width: '55px',
    height: '55px'
  },
  totalAmountLabel: {
    fontSize: '12px',
    marginTop: '10px'
  },
  totalAmountValue: {
    fontSize: '24px',
    marginTop: '5px'
  },
  divider: {
    margin: '20px 0'
  }
});

const Currency = ({ children, color = 'text.disabled' }) => (
  <Box component="span" color={color}>
    <CurrencyFormat>{children}</CurrencyFormat>
  </Box>
);

const ReceiptItem = withStyles({
  root: {
    '& >*:last-child': {
      borderBottom: '1px dashed rgba(35,31,32,0.26)'
    }
  }
})(BaseReceiptItem);

const ReceiptCard = ({ quote, isLoading, userProfile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const { id, premium, iof } = quote;
  const { minValue, maxValue } = userProfile.commission;
  const commission = quote.commissionedAgents.find(e => e.role === 'Broker').commission.percentage;

  const patchCommission = useCallback(
    newCommission =>
      new Promise(resolve => {
        if (newCommission >= minValue * 100 && newCommission <= maxValue * 100) {
          setError(false);

          if (newCommission / 100 !== commission) {
            resolve(dispatch(operations.patchCommission(newCommission / 100)));
          }
        } else {
          setError(true);
        }
      }),
    [dispatch, maxValue, minValue, commission]
  );

  const iofValue = premium - premium / (1 + iof);
  const netValue = premium - iofValue;
  const commissionValue = commission * (premium - iofValue);
  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid container direction="column" className={classes.container}>
        <Grid container direction="column" alignItems="center">
          <Avatar
            className={classes.avatar}
            src={`${process.env.PUBLIC_URL}/images/insurance_value.svg`}
            alt="ValuesAvatar"
          />
          <Typography className={classes.totalAmountLabel}>Valor do Seguro</Typography>
          <Typography className={classes.totalAmountValue}>
            <Currency color="text.primary">{premium}</Currency>
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <div>
          <ReceiptItem name="Participação">
            <ReceiptCardParticipationInput
              value={commission * 100}
              max={maxValue * 100}
              disabled={maxValue === 0 || isLoading}
              error={error}
              helperText={`De ${minValue * 100}% até ${maxValue * 100}%`}
              patchCommission={patchCommission}
            />
            <Currency>{commissionValue}</Currency>
          </ReceiptItem>

          <Divider className={classes.divider} />

          <ReceiptItem name="Prêmio Líquido">
            <Currency>{netValue}</Currency>
          </ReceiptItem>

          <ReceiptItem name="IOF">
            <Currency>{iofValue}</Currency>
          </ReceiptItem>

          <ReceiptItem name="Prêmio Total">
            <Currency>{premium}</Currency>
          </ReceiptItem>
        </div>

        <Box mt="35px">
          <ReceiptCardButtons allowHiring={!isLoading && !error} quoteId={id}></ReceiptCardButtons>
        </Box>
      </Grid>
    </Paper>
  );
};

ReceiptCard.propTypes = {
  quote: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ReceiptCard;
