export const CPF_SIZE = 11;
export const CNPJ_SIZE = 14;
export const ZIP_CODE_SIZE = 9;
export const POLICY_NUMBER_SIZE = 25;

export const DEFAULT_BILL_DUEDAYS = 7;

export const documentType = Object.freeze({
  CPF: '1',
  CNPJ: '2'
});

export const brazilianStates = Object.freeze({
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins'
});

export const DocumentType = {
  SusepRegistry: 'Registro SUSEP'
};

export const ProposalType = {
  Issuance: 'ISSUANCE'
};

export const fetchStatus = Object.freeze({
  pending: 1,
  fulfilled: 2,
  rejected: 3
});

export const roleType = Object.freeze({
  insured: 'Insured'
});
