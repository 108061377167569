import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MuiModal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';

const useStylesAction = makeStyles(theme => ({
  actionButton: {
    padding: '5px 20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem'
    },
    [theme.breakpoints.up('xs')]: {}
  },
  actionDeny: {
    color: '#30353B',
    '&:hover': {
      backgroundColor: '#dddddd'
    }
  }
}));

const ActionButton = function({ text, isConfirm, onClick, btnDisabled }) {
  const classes = useStylesAction();
  if (isConfirm)
    return (
      <Button
        disabled={btnDisabled}
        className={classes.actionButton}
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        {text}
      </Button>
    );
  else
    return (
      <Button
        disabled={btnDisabled}
        className={clsx(classes.actionButton, classes.actionDeny)}
        color="secondary"
        onClick={onClick}
      >
        {text}
      </Button>
    );
};

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  isConfirm: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

const useStyles = makeStyles(theme => ({
  paper: {
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
    [theme.breakpoints.up('sm')]: {
      width: 600
    }
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 10
    },
    [theme.breakpoints.up('sm')]: {
      padding: 30
    }
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#555A5D',
    cursor: 'pointer'
  },
  iconContainer: {
    marginTop: 20
  },
  icon: {
    color: theme.palette.primary.main,
    width: 40,
    height: 'auto'
  },
  message: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 70px'
    }
  },
  actionsContainer: {
    marginTop: 20
  }
}));

const Modal = function({ icon, message, actions, open, onClose }) {
  const classes = useStyles();
  const Icon = icon && icon.component;
  const style = icon ? icon.style : {};

  return (
    <MuiModal open={open} onClose={onClose} disableBackdropClick data-testid="modal">
      <Paper className={classes.paper} elevation={8}>
        <Close className={classes.closeButton} onClick={onClose} data-testid="modal-close-btn" />
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid className={classes.iconContainer} container justify="center" alignItems="center">
              <Grid item>{icon && <Icon className={classes.icon} style={style} />}</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Typography className={classes.message} align="center">
                  {message}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={classes.actionsContainer}
              container
              justify="flex-end"
              alignItems="center"
              spacing={1}
            >
              {actions &&
                actions.map(action => (
                  <Grid item key={_.kebabCase(action.text)}>
                    <ActionButton {...action} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </MuiModal>
  );
};

Modal.propTypes = {
  icon: PropTypes.object,
  message: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default Modal;
