import * as quoteActions from './quoteActions';

export const INITIAL_STATE = {
  questions: [],
  activeQuestion: 0,
  answers: {},
  quote: {
    coverages: []
  },
  userProfile: {},
  status: {
    quoteService: { hasError: false }
  },
  isLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case quoteActions.LIST_QUESTIONS:
      return { ...state, questions: action.payload };
    case quoteActions.SET_ACTIVE_QUESTION:
      return { ...state, activeQuestion: action.payload };
    case quoteActions.SET_ANSWER:
      return { ...state, answers: { ...state.answers, ...action.payload } };
    case quoteActions.SET_QUOTE:
      return { ...state, quote: { ...action.payload } };
    case quoteActions.SET_PRODUCT:
      return { ...state, product: { ...action.payload } };
    case quoteActions.SET_USER_PROFILE:
      return { ...state, userProfile: action.payload };
    case quoteActions.CLEAN_QUOTE:
      return { ...state, answers: {} };
    case quoteActions.SET_QUOTE_SERVICE:
      return {
        ...state,
        status: {
          ...state.status,
          quoteService: { ...state.status.quoteService, ...action.payload }
        }
      };
    case quoteActions.SET_LOADING_QUOTE:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
