import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { proposalActions } from '../redux';
import ClientAwareBlock from './ClientAwareBlock';

const mapStateToProps = ({ proposal }) => {
  return {
    clientAware: proposal.clientAware
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setClienteAware: proposalActions.setClientAware
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientAwareBlock);
