import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { ReactComponent as Wallet } from '../../../../shared/customSvgs/wallet.svg';
import CollapsableBlock from '../../../../shared/components/CollapsableBlock';
import LabeledText from '../../../../shared/components/LabeledText';

const formatToBrlMoney = money =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(money);

const useStyles = makeStyles({
  container: {
    margin: '20px 0px'
  }
});

const PricePaymentBlock = function({ quote }) {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={12}>
        <CollapsableBlock
          className={classes.container}
          title="Preço e pagamento"
          icon={{ component: Wallet }}
        >
          <Grid container justify="space-between" spacing={1}>
            <Grid item xs={4}>
              <LabeledText label={'Prêmio Líquido'} value={formatToBrlMoney(quote.premiumLiquid)} />
            </Grid>
            <Grid item xs={4}>
              <LabeledText label={'IOF'} value={formatToBrlMoney(quote.iofValue)} />
            </Grid>
            <Grid item xs={4}>
              <LabeledText label={'Prêmio Total'} value={formatToBrlMoney(quote.premium)} />
            </Grid>
          </Grid>
        </CollapsableBlock>
      </Grid>
    </Grid>
  );
};

PricePaymentBlock.propTypes = {
  quote: PropTypes.object.isRequired
};

export default PricePaymentBlock;
