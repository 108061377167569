import spreadsheetService from './spreadsheetService';
import { mountCoverages } from './normalize/mountCoverages';
import { propertyType } from './normalize/propertyType';
import { inhabitedProperty } from './normalize/inhabitedProperty';
import { dateFormat } from './normalize/dateFormat';
import { removeNonDigit } from '../../shared/functions/removeNonDigit';
import { authManager } from '../../features/auth/authManager.ig';

const createPolicyRequest = (row, userId) => {
  const arrayCoverages = mountCoverages(row[6], row[7], row[8], row[9], row[10]);

  const policy = {
    rowNumber: row['index'],

    commissionedAgents: [
      {
        commissionPercentage: row[12],
        documentNumber: removeNonDigit(row[3]),
        role: 'PolicyOwner',
        name: row[2]
      },
      {
        commissionPercentage: row[11],
        documentNumber: removeNonDigit(row[1]),
        role: 'Broker',
        name: row[0]
      }
    ],
    quiz: {
      propertyType: propertyType(row[4]),
      protectedProperty: true,
      sharedProperty: true,
      masonryProperty: true,
      inhabitedProperty: inhabitedProperty(row[5])
    },
    coverages: arrayCoverages,
    policyPeriodStart: dateFormat(row[20]),
    policyPeriodEnd: dateFormat(row[21]),
    address: {
      zipCode: removeNonDigit(row[13]),
      street: row[14],
      number: row[15],
      complement: row[16],
      district: row[17]
    },
    beneficiary: {
      documentNumber: removeNonDigit(row[19])
    },
    insured: {
      documentNumber: removeNonDigit(row[18])
    },
    installmentsNumber: removeNonDigit(row[22]),
    userId,
    profitRate: typeof row[26] === 'number' ? row[26] : undefined
  };

  if (row[23] && row[24] && row[25]) {
    policy.commissionedAgents.push({
      commissionPercentage: row[25],
      documentNumber: removeNonDigit(row[24]),
      role: 'Broker',
      name: row[23]
    });
  }

  return policy;
};

class SpreadsheetManager {
  createPolicies = async rows => {
    const user = await authManager.getUser();
    const policies = rows.map(row => createPolicyRequest(row, user.profile.person_id));

    try {
      const createPoliciesResult = await spreadsheetService.createPolicies({
        policies
      });
      return {
        result: createPoliciesResult
      };
    } catch {
      return {
        result: null
      };
    }
  };

  getIntegrationStatus = async () => {
    const getIntegrationStatusResult = await spreadsheetService.getIntegrationStatus();
    return getIntegrationStatusResult;
  };
}

export default new SpreadsheetManager();
