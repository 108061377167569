import { makeUserManager } from 'react-oidc';
import axios from 'axios';
import { WebStorageStateStore } from 'oidc-client';

const userManagerConfig = {
  client_id: window.env.REACT_APP_CLIENT_ID,
  redirect_uri: window.env.REACT_APP_REDIRECT_URI,
  response_type: 'token id_token',
  scope: window.env.REACT_APP_SCOPE,
  authority: window.env.REACT_APP_AUTHORITY,
  silent_redirect_uri: window.env.REACT_APP_SILENT_RENEW_REDIRECT_URI,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  post_logout_redirect_uri: window.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

const userManager = makeUserManager(userManagerConfig);

userManager.events.addAccessTokenExpiring(() => {
  userManager.signinSilent();
});

class AuthManager {
  getUserManager = () => userManager;

  setAxiosAuthorizationToken = async () => {
    const user = await userManager.getUser();
    if (user) {
      axios.interceptors.request.use(
        async config => {
          let headers = config.headers ? { ...config.headers } : {};
          headers = { ...headers, Authorization: `Bearer ${user.access_token}` };
          config.headers = headers;
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        response => response,
        async error => {
          if (error?.response?.status === 401) {
            await userManager.signoutRedirect();
          } else {
            return Promise.reject(error);
          }
        }
      );
    }
  };

  getUser = async () => {
    const user = await userManager.getUser();
    if (user?.profile?.organization_id) {
      user.profile.policy_owner_id = user.profile.organization_id;
    }
    return user;
  };
}

export const authManager = new AuthManager();
