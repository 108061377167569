import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DocumentTabs from './DocumentTabs';
import PolicyOwnerInput from './PolicyOwnerInput';
import PolicyNumberInput from './PolicyNumberInput';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 26,
    display: 'inline',
    paddingLeft: 10,
    color: '#303E59'
  },
  content: {
    marginTop: 80
  },
  title: {
    marginBottom: 20
  },
  paper: {
    padding: '15px 20px',
    borderRadius: 10,
    marginBottom: 40
  },
  form: {
    display: 'flex',
    marginTop: 10
  }
}));

const DocumentPageTemplate = function({ children }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.content}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={10} className={classes.title}>
            <Typography className={clsx(classes.text)}>Meus Documentos</Typography>
            <Grid item xs={12} className={classes.form}>
              <Grid item xs={6}>
                {children.props.isPolicy && <PolicyNumberInput />}
              </Grid>
              <Grid item xs={6}>
                <PolicyOwnerInput />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Paper elevation={4} className={classes.paper}>
              <DocumentTabs />
              {children}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DocumentPageTemplate.propTypes = {
  children: PropTypes.node.isRequired
};

export default DocumentPageTemplate;
