import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CollapsableBlock from '../../../shared/components/CollapsableBlock';
import { ReactComponent as Waypoint } from '../../../shared/customSvgs/waypoint.svg';
import AddressInputs from './AddressInputs';

const AddressBlock = function({ address, setAddress, getAddress, validateProposal }) {
  return (
    <CollapsableBlock title="Endereço do imóvel" icon={{ component: Waypoint }}>
      <Grid container justify="space-around">
        <AddressInputs
          address={address}
          setAddress={setAddress}
          getAddress={getAddress}
          validateProposal={validateProposal}
        />
      </Grid>
    </CollapsableBlock>
  );
};

AddressBlock.propTypes = {
  address: PropTypes.object.isRequired,
  setAddress: PropTypes.func.isRequired,
  getAddress: PropTypes.func.isRequired
};

export default AddressBlock;
