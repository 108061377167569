import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import CollapsableBlock from '../../../../shared/components/CollapsableBlock';
import LabeledText from '../../../../shared/components/LabeledText';
import { ReactComponent as Waypoint } from '../../../../shared/customSvgs/waypoint.svg';

const useStyles = makeStyles({
  container: {
    margin: '20px 0px'
  }
});

const AddressBlock = function({ address }) {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={12}>
        <CollapsableBlock
          title="Endereço do Imóvel"
          icon={{ component: Waypoint, style: { fill: '#ff6f0b' } }}
        >
          <Grid container justify="space-between" spacing={2}>
            <Grid item xs={3}>
              <LabeledText
                label={'CEP'}
                children={
                  <NumberFormat value={address.zipCode} displayType="text" format="#####-###" />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <LabeledText label={'Endereço'} value={address.street} />
            </Grid>
            <Grid item xs={3}>
              <LabeledText label={'Número'} value={address.number} />
            </Grid>
            <Grid item xs={3}>
              <LabeledText label={'Complemento'} value={address.complement || ''} />
            </Grid>
            <Grid item xs={3}>
              <LabeledText label={'Bairro'} value={address.district} />
            </Grid>
            <Grid item xs={3}>
              <LabeledText label={'Cidade'} value={address.city} />
            </Grid>
            <Grid item xs={3}>
              <LabeledText label={'Estado'} value={address.state} />
            </Grid>
          </Grid>
        </CollapsableBlock>
      </Grid>
    </Grid>
  );
};

AddressBlock.propTypes = {
  address: PropTypes.shape({
    zipCode: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    number: PropTypes.string,
    complement: PropTypes.string,
    district: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  })
};

export default AddressBlock;
