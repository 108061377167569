import { push } from 'connected-react-router';
import { batch } from 'react-redux';
import * as quoteActions from './quoteActions';
import quoteManager from '../quoteManager';
import { routes } from '../../../shared/constants/routes';
import { authManager } from '../../auth/authManager.ig';

const moveToQuestion = ({ dispatch, getState }) => ({ direction }) => {
  const {
    quote: { activeQuestion, questions }
  } = getState();

  const newPosition = quoteManager.moveToQuestion({
    activeQuestion,
    questionsSize: questions.length,
    direction
  });

  if (newPosition === -1) dispatch(push(routes.home.path));
  else if (activeQuestion !== newPosition) dispatch(quoteActions.setActiveQuestion(newPosition));
};

export const listQuestions = () => async dispatch => {
  const questions = await quoteManager.listQuestions();
  dispatch(quoteActions.listQuestions(questions));
};

export const goToNextQuestion = () => (dispatch, getState) => {
  moveToQuestion({ dispatch, getState })({ direction: 'NEXT' });
};

export const goToPreviousQuestion = () => (dispatch, getState) => {
  moveToQuestion({ dispatch, getState })({ direction: 'PREVIOUS' });
};

export const getQuote = () => async (dispatch, getState) => {
  const {
    quote: { quote }
  } = getState();

  const quoteId = quote.id;
  const productId = quote.product.id;

  try {
    dispatch(quoteActions.setLoadingQuote(true));
    const responseQuote = await quoteManager.getQuote({ quoteId, productId });
    dispatch(quoteActions.setQuote(responseQuote));
  } catch {
    dispatch(quoteActions.setQuoteService({ hasError: true }));
  } finally {
    dispatch(quoteActions.setLoadingQuote(false));
  }
};

export const updateCoverage = (coverageId, coverageToUpdate) => async (dispatch, getState) => {
  const {
    quote: { quote }
  } = getState();

  try {
    dispatch(quoteActions.setLoadingQuote(true));
    await quoteManager.updateCoverage({
      quoteId: quote.id,
      coverageId,
      coverageToUpdate
    });
    await getQuote()(dispatch, getState);
  } catch {
    dispatch(quoteActions.setQuoteService({ hasError: true }));
  } finally {
    dispatch(quoteActions.setLoadingQuote(false));
  }
};

export const coveragesValidator = (coverages, dispatch) => {
  const basicCoverageValue = coverages.find(coverage => coverage.type === 'BASIC')
    .totalInsuredAmount;

  let isValid = true;

  coverages.forEach(coverage => {
    if (
      coverage.type === 'ADDITIONAL' &&
      coverage.included &&
      coverage.totalInsuredAmount > basicCoverageValue * coverage.insuredAmount.max.percent
    ) {
      dispatch(quoteActions.setQuoteService({ hasError: true }));

      isValid = false;
    }
  });
  return isValid;
};

export const createNewQuote = () => async (dispatch, getState) => {
  const {
    quote: { activeQuestion, answers }
  } = getState();

  const productId = window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_ID;

  try {
    const product = await quoteManager.getProduct(productId);
    const user = await authManager.getUser();
    const userProfile = await quoteManager.getUserProfile(user.profile.policy_owner_id);
    const brokerId = userProfile.brokerId;
    const quote = await quoteManager.createNewQuote({
      answers,
      product,
      brokerId,
      commission: userProfile.commission.defaultValue
    });
    batch(() => {
      dispatch(quoteActions.setQuote(quote));
      dispatch(push(routes.newQuoteCoverage.path));
      dispatch(quoteActions.setActiveQuestion(activeQuestion + 1));
      dispatch(quoteActions.setProduct(product));
      dispatch(quoteActions.setUserProfile(userProfile));
    });
  } catch (err) {
    let errorMessage = 'Erro! Ocorreu algum problema interno. Tente novamente mais tarde.';
    if (err.response !== undefined && JSON.stringify(err.response).includes('04.104')) {
      errorMessage = 'Valor de aluguel fora do limite permitido.';
    }
    dispatch(quoteActions.setQuoteService({ hasError: true, message: errorMessage }));
  }
};

export const cleanQuote = () => dispatch => {
  dispatch(quoteActions.cleanQuote());
  dispatch(push(routes.newQuote.path));
};

export const patchCommission = commission => async (dispatch, getState) => {
  const quote = getState().quote.quote;
  dispatch(quoteActions.setLoadingQuote(true));

  try {
    let user = await authManager.getUser();
    let userProfile = await quoteManager.getUserProfile(user.profile.policy_owner_id);
    let brokerId = userProfile.brokerId;
    await quoteManager.patchCommission(quote.id, commission, brokerId);
    await dispatch(getQuote());
  } catch (err) {
    batch(() => {
      dispatch(quoteActions.setQuoteService({ hasError: true }));
      dispatch(quoteActions.setLoadingQuote(false));
    });

    return Promise.reject(err);
  }
};
