import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, Box, Select, MenuItem } from '@material-ui/core';
import { isValid } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import CustomDatePicker from '../../../shared/components/CustomDatePicker';
import { cancellationReasons } from '../../../shared/constants/cancellationReasons';

const useStyles = makeStyles({
  iconContainer: {
    marginTop: 20
  },
  datePicker: {
    width: '100%',
    fontWeight: 400
  },
  selectInput: {
    marginTop: 0,
    width: '100%',
    fontWeight: 400
  }
});

const CancelModalContainer = ({
  endorsementStartDate,
  handleSetEndorsementStartDate,
  cancellmentReason,
  handleSetCancellmentReason
}) => {
  const styles = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <Grid className={styles.iconContainer} container justify="flex-start" alignItems="center">
          <Typography variant="h6" align="left">
            <Box fontWeight="fontWeightBold">Cancelamento de apólice</Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="column">
        <Grid container direction="row">
          <Grid item xs={12} sm={8} container direction="column" justify="center">
            <Typography>Informe a data de início do cancelamento:</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomDatePicker
              required
              error={!isValid(endorsementStartDate)}
              helperText={!isValid(endorsementStartDate) ? 'Campo obrigatório' : null}
              className={styles.datePicker}
              value={endorsementStartDate}
              onChange={handleSetEndorsementStartDate}
            />
          </Grid>
          <Grid item sm={12} container direction="column" justify="flex-end">
            <Typography>Informe o motivo do cancelamento:</Typography>
          </Grid>
          <Grid item sm={12} container direction="column" justify="center">
            <Select
              onChange={handleSetCancellmentReason}
              value={cancellmentReason || ''}
              className={styles.selectInput}
            >
              {cancellationReasons.map((value, index) => {
                return (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CancelModalContainer;
