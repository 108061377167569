import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '../../shared/styles/theme';

const useStyles = makeStyles({
  inputLabel: {
    color: '#9DA8B6',
    fontSize: 12,
    display: 'block'
  },
  inputValue: {
    fontSize: 16,
    color: palette.title.main,
    fontWeight: 500,
    paddingBottom: 3
  }
});

const LabeledText = function({ label, value, children }) {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.inputLabel}>{label}</Typography>
      {children ? children : <Typography className={classes.inputValue}>{value}</Typography>}
    </div>
  );
};

LabeledText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

export default LabeledText;
