import React from 'react';
import PropTypes from 'prop-types';
import CustomQuestion from '../../../shared/components/Question';
import QuestionInputFactory from './QuestionInputFactory';

const Question = function({ question }) {
  const avatar = `${process.env.PUBLIC_URL}/images/avatar_assistant.svg`;
  return (
    <>
      {question ? (
        <CustomQuestion question={question} avatar={avatar}>
          <QuestionInputFactory question={question} />
        </CustomQuestion>
      ) : (
        <CustomQuestion avatar={avatar} />
      )}
    </>
  );
};

Question.propTypes = {
  question: PropTypes.object
};

export default Question;
