import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { routes } from '../../../shared/constants/routes';
import { useHistory } from 'react-router-dom';
import { palette } from '../../../shared/styles/theme';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 26,
    display: 'inline',
    paddingLeft: 30
  },
  content: {
    marginTop: 20,
    marginBottom: 70
  },
  title: {
    marginBottom: 5,
    marginTop: 10,
    color: palette.title.main,
    cursor: 'pointer',
    display: 'table',
    position: 'relative'
  },
  arrowLeft: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  titleLabel: {
    fontSize: 12,
    color: '#BBB',
    marginTop: 70,
    marginLeft: 35,
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  paper: {
    padding: 27,
    borderRadius: 10
  }
}));

const DetailsBackground = function({ children, title }) {
  const classes = useStyles();
  const { push } = useHistory();
  const handleClick = () => {
    push(routes.proposalsDocuments.path);
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.content}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={8}>
            <Typography className={classes.titleLabel}>Meus Documentos</Typography>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.title} onClick={handleClick}>
              <KeyboardArrowLeft className={classes.arrowLeft} />
              <Typography className={classes.text}>{title}</Typography>
            </div>
          </Grid>
          <Grid item xs={8} className={classes.content}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsBackground.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default DetailsBackground;
