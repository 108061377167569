import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import CustomMoneyInput from './MoneyInput';

const useMenuStyles = makeStyles({
  disabled: {
    opacity: 0.5,
    fill: '#676B73 !important',
    WebkitFilter: 'grayscale(100%)',
    filter: 'grayscale(100%)'
  },
  card: {
    position: 'relative',
    width: 300
  },
  title: {
    marginTop: 15,
    fontSize: 12
  },
  icon: {
    marginTop: 20,
    width: 55,
    height: 'auto'
  },
  content: {
    marginTop: 20,
    width: 240
  },
  readMore: {
    marginTop: 25,
    fontSize: 11,
    color: '#676B73'
  },
  divider: {
    marginTop: 22,
    color: '#979797'
  },
  switch: {
    position: 'absolute',
    top: 5,
    right: 10
  },
  inputLabel: {
    marginTop: 10,
    marginBottom: -5,
    fontSize: 10
  },
  input: {
    width: 180,
    marginBottom: 20
  }
});

function CoverageCard({
  hasSwitch,
  disabled,
  inputDisabled,
  error,
  title,
  imageTitle,
  content,
  switchValue,
  onChangeSwitch,
  inputValue,
  onChangeInput,
  onBlurInput,
  minInputValue,
  maxInputValue
}) {
  const classes = useMenuStyles();

  const optionalPropsCustomInput = {};
  if (onBlurInput) optionalPropsCustomInput.onBlur = () => onBlurInput(inputValue);

  return (
    <Paper className={classes.card}>
      {hasSwitch && (
        <Switch
          className={classes.switch}
          disabled={disabled}
          checked={switchValue}
          onChange={e => onChangeSwitch(e.target.checked)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography className={clsx(classes.title, { [classes.disabled]: disabled })}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <img
                className={clsx(classes.icon, { [classes.disabled]: disabled })}
                src={imageTitle}
                alt={title}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography
                className={clsx(classes.content, { [classes.disabled]: disabled })}
                align="center"
              >
                {content}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography
                className={clsx(classes.readMore, {
                  [classes.disabled]: disabled
                })}
                align="center"
              >
                Entre em contato para mais informações
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Typography
                className={clsx(classes.inputLabel, { [classes.disabled]: disabled })}
                align="center"
              >
                Valor da cobertura
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item className={classes.input}>
              <CustomMoneyInput
                {...optionalPropsCustomInput}
                value={inputValue}
                onChange={onChangeInput}
                disabled={disabled || inputDisabled || !switchValue}
                error={error}
                min={minInputValue}
                max={maxInputValue}
                showInputLimits={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

CoverageCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  switchValue: PropTypes.bool.isRequired,
  onChangeSwitch: PropTypes.func.isRequired,
  inputValue: PropTypes.number,
  onChangeInput: PropTypes.func.isRequired,
  onBlurInput: PropTypes.func,
  minInputValue: PropTypes.number,
  maxInputValue: PropTypes.number,
  disabled: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  hasSwich: PropTypes.bool
};

export default CoverageCard;
