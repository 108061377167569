import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiChip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    color: '#fff',
    padding: '8px 8px',
    width: 105,
    fontSize: 12,
    textTransform: 'none'
  }
});

const Chip = function({ name, color }) {
  const classes = useStyles();
  return (
    <MuiChip
      size="small"
      label={name}
      className={classes.root}
      style={{ backgroundColor: `${color}` }}
    />
  );
};

Chip.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default Chip;
