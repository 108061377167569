import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '../../shared/styles/theme';

const useMenuStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: 12
  },
  icon: {
    marginTop: 15,
    fontSize: 22,
    backgroundColor: 'white',
    color: palette.primary.main,
    display: 'flex',
    '&:active': {
      color: '#ffffff',
      backgroundColor: '#ff6300'
    },
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#ff6f0b'
    }
  }
}));

function ItemMenu({ title, icon, onClick, disabled }) {
  const classes = useMenuStyles();

  const adjustedProps = {
    disabled: disabled,
    component: disabled ? 'div' : undefined,
    onClick: disabled ? undefined : onClick
  };

  return (
    <Tooltip className={classes.tooltip} title={title} placement="right">
      <Fab disabled={disabled} className={classes.icon} {...adjustedProps}>
        <span className={icon}></span>
      </Fab>
    </Tooltip>
  );
}

ItemMenu.propTypes = {
  title: PropType.string.isRequired,
  icon: PropType.string.isRequired,
  onClick: PropType.func.isRequired,
  disabled: PropType.bool.isRequired
};

const useStyles = zIndex =>
  makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    fab: {
      margin: theme.spacing(1)
    },
    parentDiv: {
      display: 'inline-block',
      position: 'absolute',
      top: 30,
      right: 40,
      zIndex
    },
    btn: {
      fontSize: 20,
      textAlign: 'center'
    },
    btnClosed: {
      backgroundColor: 'white',
      color: palette.primary.main,
      '&:active': {
        color: '#ffffff',
        backgroundColor: '#ff6300'
      },
      '&:hover': {
        color: '#ffffff',
        backgroundColor: '#ff6f0b'
      }
    },
    btnOpened: {
      color: '#ffffff',
      backgroundColor: '#ff6300',
      '&:hover': {
        backgroundColor: '#ff6f0b'
      }
    },
    showIcons: {
      display: 'block',
      opacity: 1,
      transform: 'translateY(0px) scale(1)',
      transition: 'all 0.5s',
      transitionDelay: '0s'
    },
    hideIcons: {
      transform: 'translateY(-110px) scale(0)',
      opacity: 0.5,
      transition: 'all 0.5s',
      transitionDelay: '0s'
    },
    iconMenu: {
      transform: 'rotate(0deg)',
      transition: 'all 0.8s',
      fontSize: 22
    },
    iconMenuRotated: {
      transform: 'rotate(90deg)',
      transition: 'all 0.5s'
    }
  }));

const Menu = function({ zIndex, menuItens, open }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    open && setIsOpen(open);
  }, [open, setIsOpen]);

  const classes = useStyles(zIndex)();

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={classes.parentDiv}>
        <Fab
          className={clsx(
            classes.root,
            classes.btn,
            { [classes.btnOpened]: isOpen },
            { [classes.btnClosed]: !isOpen }
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span
            className={clsx(
              'icon-th-menu',
              { [classes.iconMenuRotated]: isOpen },
              { [classes.iconMenu]: !isOpen }
            )}
          />
        </Fab>

        <div className={clsx({ [classes.showIcons]: isOpen }, { [classes.hideIcons]: !isOpen })}>
          {menuItens.map(({ title, icon, onClick, disabled }) => (
            <ItemMenu key={icon} title={title} icon={icon} onClick={onClick} disabled={disabled} />
          ))}
        </div>
      </div>
    </ClickAwayListener>
  );
};

Menu.propTypes = {
  zIndex: PropType.number.isRequired,
  menuItens: PropType.arrayOf(PropType.object).isRequired,
  open: PropType.bool
};

export default Menu;
