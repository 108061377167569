import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import AddressBlockContainer from './AddressBlockContainer';
import PolicyPeriodBlockContainer from './PolicyPeriodBlockContainer';
import CoveragesBlockContainer from './CoveragesBlockContainer';
import PriceBlockContainer from './PriceBlockContainer';
import UserBeneficiaryBlockContainer from './UserBeneficiaryBlockContainer';
import UserInsuredBlockContainer from './UserInsuredBlockContainer';
import ProposalPageButtonsContainer from './ProposalPageButtonsContainer';
import ClientAwareBlockContainer from './ClientAwareBlockContainer';
import Question from '../../../shared/components/Question';
import { proposalOperations } from '../redux';
import { cleanProposal } from '../redux/proposalActions';
import { authManager } from '../../auth/authManager.ig';

const useStyles = makeStyles(theme => ({
  block: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: 800
    },
    margin: '0 16px'
  },
  page: {
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    margin: 0
  },
  footer: {
    marginBottom: 20,
    marginTop: 40
  },
  questionContainer: {
    marginTop: '9%'
  },
  text: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 14,
    display: 'inline'
  },
  textBlock: {
    marginBottom: 15,
    textAlign: 'right'
  },
  link: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
    paddingLeft: 5
  }
}));

const blocks = [
  AddressBlockContainer,
  UserInsuredBlockContainer,
  UserBeneficiaryBlockContainer,
  PolicyPeriodBlockContainer,
  CoveragesBlockContainer,
  PriceBlockContainer
];

export function ProposalFormPage() {
  const classes = useStyles();
  const { dispatch, getState } = useStore();
  const { quoteId } = useParams();

  useEffect(() => {
    dispatch(proposalOperations.getQuote({ quoteId }));

    return () => {
      dispatch(cleanProposal());
    };
  }, [dispatch, quoteId]);

  useEffect(() => {
    const { broker, policyOwner } = getState().proposal;

    if (!broker.id) {
      proposalOperations.getUserProfile().then(userProfile => {
        dispatch(proposalOperations.getBroker(userProfile.brokerId));
      });
    }

    if (!policyOwner.id) {
      authManager.getUser().then(user => {
        dispatch(proposalOperations.getPolicyOWner(user.profile.policy_owner_id));
      });
    }
  }, [dispatch, getState]);

  return (
    <Grid className={classes.page} container spacing={4}>
      <Grid item xs={12}>
        <Grid className={classes.questionContainer} container justify="center" direction="column">
          <Grid item>
            <Question
              avatar={`${process.env.PUBLIC_URL}/images/avatar_assistant.svg`}
              question={{ title: 'Vamos lá, faltam poucos dados para a emissão:' }}
            />
          </Grid>
        </Grid>
      </Grid>
      {blocks.map(ContainerBlock => (
        <Grid key={ContainerBlock.displayName} item xs={12}>
          <Grid container justify="center">
            <Grid item className={classes.block}>
              <ContainerBlock />
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sm={12}>
        <Grid container justify="center">
          <Grid item className={clsx(classes.block, classes.textBlock)}>
            <ClientAwareBlockContainer />
          </Grid>
        </Grid>

        <ProposalPageButtonsContainer />
      </Grid>

      <Grid item xs={12} className={classes.footer} />
    </Grid>
  );
}

export default ProposalFormPage;
