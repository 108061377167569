import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { icons, getIconFromImages } from '../../../shared/constants/icons';
import CustomMoneyInput from '../../../shared/components/MoneyInput';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: 15
  },
  item: {
    width: '100%'
  },
  icon: {
    width: 60,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 50
    }
  },
  title: {
    fontSize: 16,
    marginTop: 5,
    fontFamily: 'Inter, sans-serif',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1.4'
    }
  },
  type: {
    fontSize: 12,
    color: '#A7A7A7',
    marginTop: 10,
    fontFamily: 'Inter, sans-serif'
  },
  moneyInput: {
    marginTop: 15
  }
}));

const CoverageRow = function({ coverage }) {
  const classes = useStyles();

  const { icon, type, name, totalInsuredAmount } = coverage;

  return (
    <Grid container className={classes.item}>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <img className={classes.icon} src={getIconFromImages(icons[icon])} alt={name} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.type}>
              {type === 'BASIC' ? 'Cobertura Básica' : 'Cobertura Adicional'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.title}>{name}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item className={classes.moneyInput}>
            <CustomMoneyInput
              value={totalInsuredAmount}
              onChange={() => {}}
              disabled={true}
              showInputLimits={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CoverageRow.propTypes = {
  coverage: PropTypes.object.isRequired
};

export default CoverageRow;
