import { connect } from 'react-redux';
import { quoteSelectors } from '../redux';
import Question from './Question';

const mapStateToProps = ({ quote }) => {
  return {
    question: quoteSelectors.getActiveQuestion(quote)
  };
};

export default connect(mapStateToProps)(Question);
