import { connect } from 'react-redux';
import PriceBlock from './PriceBlock';

const mapStateToProps = ({ proposal }) => {
  return {
    quote: { ...proposal.quote }
  };
};

export default connect(mapStateToProps)(PriceBlock);
