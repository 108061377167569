import React from 'react';
import clsx from 'clsx';
import PropType from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  icon: {
    width: 55,
    height: 45,
    paddingTop: 25
  },
  checkbox: {
    fontSize: '1.5rem'
  },
  isChecked: {
    color: ({ color }) => (color != null ? `${color}` : theme.palette.primary.main)
  }
}));

const CustomCheckbox = withStyles(theme => ({
  root: {
    color: ({ newcolor }) => (newcolor != null ? `${newcolor}` : theme.palette.primary.main),
    fontSize: '1.5rem',
    '&:checked': {
      color: ({ newcolor }) => (newcolor != null ? `${newcolor}` : theme.palette.primary.main)
    },
    '&:hover': {
      backgroundColor: '#FFF'
    }
  },
  checked: {
    fontSize: '1.5rem'
  }
}))(props => <Checkbox color="default" {...props} />);

const ColorfulCheckbox = function({ color, checked, onChange }) {
  const classes = useStyles({ color });

  return (
    <CustomCheckbox
      className={clsx(classes.checkbox, { [classes.isChecked]: checked })}
      icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
      checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
      onChange={() => onChange(!checked)}
      checked={checked}
      newcolor={color}
    />
  );
};

ColorfulCheckbox.propTypes = {
  color: PropType.string,
  checked: PropType.bool.isRequired,
  onChange: PropType.func.isRequired
};

export default ColorfulCheckbox;
