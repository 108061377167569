import { connect } from 'react-redux';
import ReceiptCard from './ReceiptCard';

const mapStateToProps = ({ quote }) => {
  return {
    quote: quote.quote,
    isLoading: quote.isLoading,
    userProfile: quote.userProfile
  };
};

export default connect(mapStateToProps)(ReceiptCard);
