import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles, withStyles } from '@material-ui/core';
import CollapsableBlock from '../../../shared/components/CollapsableBlock';
import BaseReceiptItem from '../../../shared/components/ReceiptItem';
import Currency from '../../../shared/components/Currency';
import { ReactComponent as Wallet } from '../../../shared/customSvgs/wallet.svg';

const useStyles = makeStyles(theme => ({
  block: {
    margin: '0 auto'
  },
  text: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 12,
    textAlign: 'right',
    paddingTop: 15,
    paddingBottom: 25
  }
}));

const ReceiptItem = withStyles({
  name: {
    fontSize: 16
  }
})(BaseReceiptItem);

const PriceBlock = function({ quote }) {
  const classes = useStyles();

  const premiumLiquid = quote.premium / (1 + quote.iof);
  const iof = quote.premium - premiumLiquid;

  return (
    <CollapsableBlock title="Preço e pagamento" icon={{ component: Wallet }}>
      <>
        <Grid container>
          <Grid item xs={12} sm={5} className={classes.block}>
            <ReceiptItem name="Prêmio Líquido">
              <Currency>{quote.premium - iof}</Currency>
            </ReceiptItem>

            <ReceiptItem name="IOF">
              <Currency>{iof}</Currency>
            </ReceiptItem>

            <ReceiptItem name="Prêmio Total">
              <Currency>{quote.premium}</Currency>
            </ReceiptItem>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.text}>
              * Forma de pagamento: Boleto bancário com 15 dias de prazo.
            </Typography>
          </Grid>
        </Grid>
      </>
    </CollapsableBlock>
  );
};

PriceBlock.propTypes = {
  quote: PropTypes.object.isRequired
};

export default PriceBlock;
