import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CustomMoneyInput from '../../../shared/components/MoneyInput';
import QuestionButtons from './QuestionButtons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 70
  }
}));

const MoneyInput = function({ question, answers, setAnswer, nextQuestion, previousQuestion }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <CustomMoneyInput
            disabled={_.isEmpty(question)}
            value={answers[question.propertyName]}
            onChange={value => setAnswer({ [question.propertyName]: value })}
            placeholder="R$ 0.000,00"
            min={300}
            max={70000}
            type={question.type}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center" className={classes.button}>
          <QuestionButtons
            text="Continuar"
            disabled={!answers[question.propertyName]}
            nextQuestion={nextQuestion}
            previousQuestion={previousQuestion}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

MoneyInput.propTypes = {
  question: PropTypes.object,
  answers: PropTypes.object,
  setAnswer: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  previousQuestion: PropTypes.func.isRequired
};

export default MoneyInput;
