import * as proposalActions from './proposalActions';

export const INITIAL_STATE = {
  validateProposal: false,
  acceptedProposal: false,
  clientAware: false,
  address: {
    zipCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: ''
  },
  userInsured: {
    id: '',
    documentNumber: '',
    name: '',
    type: ''
  },
  userBeneficiary: {
    id: '',
    documentNumber: '',
    name: '',
    type: ''
  },
  policyPeriod: {
    start: null,
    end: null
  },
  broker: {
    id: ''
  },
  policyOwner: {
    id: ''
  },
  quote: {
    coverages: []
  },
  status: {
    personServiceError: false,
    proposalService: { hasError: false, hadSucceeded: false },
    policyService: { hasError: false, hadSucceeded: false }
  },
  isLoading: false,
  documentUrl: '',
  documentName: '',
  billUrl: '',
  billFileName: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case proposalActions.SET_VALIDATE_PROPOSAL:
      return { ...state, validateProposal: action.payload };
    case proposalActions.SET_ACCEPTED_PROPOSAL:
      return { ...state, acceptedProposal: action.payload };
    case proposalActions.SET_CLIENT_AWARE:
      return { ...state, clientAware: action.payload };
    case proposalActions.SET_ADDRESS:
      return { ...state, address: { ...state.address, ...action.payload } };
    case proposalActions.SET_USER_INSURED:
      return { ...state, userInsured: action.payload };
    case proposalActions.SET_USER_BENEFICIARY:
      return { ...state, userBeneficiary: action.payload };
    case proposalActions.SET_POLICY_PERIOD:
      return { ...state, policyPeriod: { ...state.policyPeriod, ...action.payload } };
    case proposalActions.SET_QUOTE:
      return { ...state, quote: { ...action.payload } };
    case proposalActions.SET_PERSON_SERVICE_ERROR:
      return { ...state, status: { ...state.status, ...action.payload } };
    case proposalActions.SET_PROPOSAL_CREATED:
      return {
        ...state,
        id: action.payload.id,
        proposalNumber: action.payload.proposalNumber,
        riskLocation: action.payload.riskLocation
      };
    case proposalActions.SET_POLICY:
      return { ...state, policyId: action.payload.id, policyNumber: action.payload.policyNumber };
    case proposalActions.SET_PROPOSAL_SERVICE:
      return {
        ...state,
        status: {
          ...state.status,
          proposalService: { ...state.status.proposalService, ...action.payload }
        }
      };
    case proposalActions.SET_POLICY_SERVICE:
      return {
        ...state,
        status: {
          ...state.status,
          policyService: { ...state.status.policyService, ...action.payload }
        }
      };
    case proposalActions.CLEAN_PROPOSAL:
      return { ...INITIAL_STATE, broker: state.broker, policyOwner: state.policyOwner };
    case proposalActions.SET_LOADING_PROPOSAL:
      return { ...state, isLoading: action.payload };
    case proposalActions.SET_PROPOSAL_DOCUMENT_URL:
      return { ...state, documentUrl: action.payload };
    case proposalActions.SET_PROPOSAL_DOCUMENT_NAME:
      return { ...state, documentName: action.payload };
    case proposalActions.SET_BILL_URL:
      return { ...state, billUrl: action.payload };
    case proposalActions.SET_BILL_FILENAME:
      return { ...state, billFileName: action.payload };
    case proposalActions.SET_BROKER:
      return { ...state, broker: action.payload };
    case proposalActions.SET_POLICY_OWNER:
      return { ...state, policyOwner: action.payload };
    default:
      return state;
  }
};
