import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';

const styles = {
  root: {
    display: 'flex',
    padding: '10px 0',
    fontSize: 14,
    lineHeight: 1.5
  },
  name: {
    margin: '0 10px 0 0',
    fontSize: 14,
    textAlign: 'right'
  }
};

const ReceiptItem = ({ name, classes, children }) => (
  <div className={classes.root} data-testid="receipt-item">
    <Grid item xs={6} className={classes.nameContainer}>
      <Typography className={classes.name}>{name}</Typography>
    </Grid>
    <Grid item xs={6} className={classes.detailsContainer}>
      {children}
    </Grid>
  </div>
);

ReceiptItem.propTypes = {
  name: PropTypes.string.isRequired
};

export default withStyles(styles)(ReceiptItem);
