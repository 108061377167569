import Warning from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Description from '@material-ui/icons/Description';
import CheckCircle from '@material-ui/icons/CheckCircle';

export const getIconFromImages = icon => `${process.env.PUBLIC_URL}/images/${icon}`;

export const icons = Object.freeze({
  HOUSE_HAND: 'inhabited_house.svg',
  HISTORICAL_HOUSE: 'historical_house.svg',
  COLLECTIVE_HOUSE: 'collective_house.svg',
  MASONRY_WALL: 'masonry_wall.svg',
  FIRE: 'basic_coverage.svg',
  HAND_COIN: 'additional_coverage.svg',
  ARROW_RIGHT: 'keyboard_arrow_right.svg',
  HOUSE: 'house.svg',
  APARTMENT: 'apartment.svg'
});

export const muiIcons = Object.freeze({
  WARNING: Warning,
  ERROR: ErrorIcon,
  SUCCESS: ThumbUp,
  DOCUMENT: Description,
  CHECKED: CheckCircle
});
