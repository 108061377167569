import axios from 'axios';

class QuoteService {
  listQuestions = async () => {
    const response = await axios.get(
      `${window.env.REACT_APP_PRODUCT_ENDPOINT}/api/quiz/${window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_ID}`
    );

    return response.data;
  };

  getProductById = async productId => {
    const response = await axios.get(
      `${window.env.REACT_APP_PRODUCT_ENDPOINT}/api/products/${productId}`
    );

    return response.data;
  };

  getCoveragesByProduct = async productId => {
    const response = await axios.get(
      `${window.env.REACT_APP_PRODUCT_ENDPOINT}/api/products/${productId}/coverages`
    );

    return response.data;
  };

  getQuote = async quoteId => {
    const response = await axios.get(
      `${window.env.REACT_APP_QUOTE_ENDPOINT}/api/quotes/${quoteId}`
    );

    return response.data;
  };

  getPersonById = async id => {
    const response = await axios.get(`${window.env.REACT_APP_PERSON_ENDPOINT}/api/persons/${id}`);

    return response.data;
  };

  createNewQuote = async quote => {
    const response = await axios.post(`${window.env.REACT_APP_QUOTE_ENDPOINT}/api/quotes/`, {
      ...quote
    });

    return response.data;
  };

  updateCoverage = async (quoteId, coverageId, coverageToUpdate) => {
    const response = await axios.put(
      `${window.env.REACT_APP_QUOTE_ENDPOINT}/api/quotes/${quoteId}/coverages/${coverageId}`,
      coverageToUpdate
    );

    return response.data;
  };

  getUserProfile = async organizationId => {
    const response = await axios.get(
      `${window.env.REACT_APP_USERPROFILE_ENDPOINT}/api/organization-profiles/${organizationId}`
    );
    return response.data;
  };

  patchCommission = async (quoteId, complement) => {
    const response = await axios.patch(
      `${window.env.REACT_APP_QUOTE_ENDPOINT}/api/quotes/${quoteId}`,
      complement
    );
    return response.data;
  };
}

export default new QuoteService();
