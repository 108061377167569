export const SET_ACTIVE_PROPOSAL = 'document/SET_ACTIVE_PROPOSAL';
export const SET_ACTIVE_POLICY = 'document/SET_ACTIVE_POLICY';
export const SET_PROPOSALS = 'document/SET_PROPOSALS';
export const UPDATE_DOCUMENT_SERVICE = 'document/UPDATE_DOCUMENT_SERVICE';
export const SET_LOADING_DOCUMENT = 'document/SET_LOADING_DOCUMENT';
export const SET_POLICIES = 'document/SET_POLICIES';
export const SET_DOCUMENT_PDF_URL = 'document/SET_DOCUMENT_PDF_URL';
export const SET_DOCUMENT_BOLETO_URL = 'document/SET_DOCUMENT_BOLETO_URL';
export const SET_STATUS_ACTIVE_PROPOSAL = 'document/SET_STATUS_ACTIVE_PROPOSAL';
export const SET_POLICY_OWNER_ID = 'document/SET_POLICY_OWNER_ID';
export const SET_DOCUMENT_NUMBER = 'document/SET_DOCUMENT_NUMBER';
export const SET_POLICY_NUMBER = 'document/SET_POLICY_NUMBER';
export const SET_POLICY_SEQUENTIAL_NUMBER = 'document/SET_POLICY_SEQUENTIAL_NUMBER';
export const SET_LABEL_NAME = 'document/SET_LABEL_NAME';
export const SET_LAST_PROPOSAL_POLICY_OWNER_ID = 'document/SET_LAST_PROPOSAL_POLICY_OWNER_ID';
export const SET_LAST_POLICY_POLICY_OWNER_ID = 'document/SET_LAST_POLICY_POLICY_OWNER_ID';

export const setLoadingDocument = isLoading => ({
  type: SET_LOADING_DOCUMENT,
  payload: isLoading
});

export const setProposals = proposals => ({
  type: SET_PROPOSALS,
  payload: proposals
});

export const setPolicies = policies => ({
  type: SET_POLICIES,
  payload: policies
});

export const updateDocumentService = documentService => ({
  type: UPDATE_DOCUMENT_SERVICE,
  payload: documentService
});

export const setActiveProposal = proposal => ({
  type: SET_ACTIVE_PROPOSAL,
  payload: proposal
});

export const setActivePolicy = policy => ({
  type: SET_ACTIVE_POLICY,
  payload: policy
});

export const setPolicyOwnerId = policyOwnerid => ({
  type: SET_POLICY_OWNER_ID,
  payload: policyOwnerid
});

export const setDocumentNumber = documentNumber => ({
  type: SET_DOCUMENT_NUMBER,
  payload: documentNumber
});

export const setPolicyNumber = policyNumber => ({
  type: SET_POLICY_NUMBER,
  payload: policyNumber
});

export const setPolicySequentialNumber = policySequentialNumber => ({
  type: SET_POLICY_SEQUENTIAL_NUMBER,
  payload: policySequentialNumber
});

export const setLabelName = labelName => ({
  type: SET_LABEL_NAME,
  payload: labelName
});

export const setLastProposalPolicyOwnerId = lastProposalPolicyOwnerId => ({
  type: SET_LAST_PROPOSAL_POLICY_OWNER_ID,
  payload: lastProposalPolicyOwnerId
});

export const setLastPolicyPolicyOwnerId = lastPolicyPolicyOwnerId => ({
  type: SET_LAST_POLICY_POLICY_OWNER_ID,
  payload: lastPolicyPolicyOwnerId
});
