import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  helperText: {
    fontSize: '0.585rem',
    marginTop: 10,
    textAlign: 'center',
    letterSpacing: 0
  },
  input: {
    fontWeight: 400
  },
  formControl: {
    width: '100%'
  }
}));

const NumberFormatCustom = function(props) {
  const { onChange, value, inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={value || ''}
      allowLeadingZeros={true}
      format="#####-###"
      placeholder="00000-000"
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function CepInput({ value, label, error, onChange, onBlur, disabled, required }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        {label && (
          <InputLabel error={error} required={required}>
            {label}
          </InputLabel>
        )}
        <Input
          error={!!error}
          className={classes.input}
          inputComponent={NumberFormatCustom}
          value={value}
          onChange={e => {
            onChange(e.target.value);
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
      </FormControl>
    </div>
  );
}

CepInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};
