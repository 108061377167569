import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as documentOperations from '../redux/documentOperations';
import DocumentTable from './DocumentTable';
import DocumentPageTemplate from './DocumentPageTemplate';
import * as documentActions from './../../document/redux/documentActions';
import {
  useProposalItems,
  useDocumentIsLoading,
  usePolicyOwnerId,
  useLastProposalPolicyOwnerId
} from '../redux/documentCustomHooks';
import { setLastProposalPolicyOwnerId } from '../redux/documentActions';

const ProposalsDocumentPage = function() {
  const dispatch = useDispatch();
  const isLoading = useDocumentIsLoading();
  const { items } = useProposalItems();
  const policyOwnerId = usePolicyOwnerId();
  const lastProposalPolicyOwnerId = useLastProposalPolicyOwnerId();

  const policyOwnerChanged = useCallback(() => {
    return lastProposalPolicyOwnerId !== policyOwnerId;
  }, [lastProposalPolicyOwnerId, policyOwnerId]);

  useEffect(() => {
    if (policyOwnerChanged() && !isLoading && policyOwnerId) {
      dispatch(setLastProposalPolicyOwnerId(policyOwnerId));
      dispatch(documentOperations.getProposals(policyOwnerId));
    } else if (policyOwnerId === undefined && policyOwnerId !== lastProposalPolicyOwnerId) {
      dispatch(setLastProposalPolicyOwnerId(policyOwnerId));
      dispatch(documentActions.setProposals([]));
    }
  }, [dispatch, policyOwnerChanged, isLoading, policyOwnerId, lastProposalPolicyOwnerId]);

  return (
    <DocumentPageTemplate>
      <DocumentTable items={items} isLoading={isLoading} isPolicy={false} />
    </DocumentPageTemplate>
  );
};

export default ProposalsDocumentPage;
