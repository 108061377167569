export const routes = Object.freeze({
  home: {
    path: '/'
  },
  newQuote: {
    path: '/nova-cotacao'
  },
  newQuoteCoverage: {
    path: '/nova-cotacao-coberturas'
  },
  proposal: {
    path: '/nova-proposta',
    params: {
      quoteId: ':quoteId'
    }
  },
  proposalsDocuments: {
    path: '/propostas',
    params: {
      proposalId: ':proposalId'
    }
  },
  policiesDocuments: {
    path: '/apolices',
    params: {
      policyId: ':policyId'
    }
  },
  auth: {
    errorLogin: '/error-login'
  },
  spreadsheet: {
    path: '/planilha'
  }
});
