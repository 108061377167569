import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import HtmlToReactParser from 'html-to-react';
import useModal from '../../../shared/hooks/useModal';
import QuestionButtons from './QuestionButtons';
import InputCheckbox from '../../../shared/components/InputCheckbox';
import { icons, muiIcons } from '../../../shared/constants/icons';
import { routes } from '../../../shared/constants/routes';
import Modal from '../../../shared/components/Modal';

const useStyles = makeStyles({
  button: {
    marginTop: 40
  }
});

const useUpdateAnswers = function({ options, setAnswer, answers }) {
  const memoizedSetAnswer = useCallback(() => {
    const propertyNames = options.map(option => option.propertyName);
    if (!propertyNames.every(key => Object.keys(answers).includes(key))) {
      if (!_.isEmpty(options)) {
        const updatedAnswers = {};
        options.forEach(({ propertyName }) => (updatedAnswers[propertyName] = true));
        setAnswer(updatedAnswers);
      }
    }
  }, [options, setAnswer, answers]);

  useEffect(() => {
    memoizedSetAnswer();
  }, [memoizedSetAnswer]);
};

const useErrorModal = function() {
  const [errorModalProps, setErrorModalProps] = useState({
    icon: { component: muiIcons.WARNING },
    action: [],
    message: '',
    isOpen: false
  });

  return { errorModalProps, setErrorModalProps };
};

export const handleNextQuestion = ({
  options,
  answers,
  requiredMessage,
  setModalProps,
  handleOpenModal,
  handleCloseModal,
  history,
  setLoading,
  createNewQuote
}) => () => {
  if (!_.isEmpty(options)) {
    const requiredOptions = options.filter(
      option => option.required && !answers[option.propertyName]
    );

    if (requiredOptions.length > 0 && !_.isEmpty(requiredMessage)) {
      const { description, icon } = requiredMessage;
      setModalProps({
        message: description,
        icon: { component: muiIcons[icon] },
        actions: [
          {
            text: 'Cancelar',
            isConfirm: false,
            onClick: () => {
              handleCloseModal();
            }
          },
          {
            text: 'Voltar para o início',
            isConfirm: true,
            onClick: () => {
              history.push(routes.home.path);
              handleCloseModal();
            }
          }
        ]
      });
      handleOpenModal();
    } else {
      setLoading(true);
      createNewQuote();
    }
  } else {
    setLoading(true);
    createNewQuote();
  }
};

export const handleInputChange = ({
  warning,
  propertyName,
  setModalProps,
  setAnswer,
  handleOpenModal,
  handleCloseModal
}) => checked => {
  if (!checked && warning) {
    const { description, icon } = warning;
    setModalProps({
      message: description,
      icon: { component: muiIcons[icon] },
      actions: [
        {
          text: 'Cancelar',
          isConfirm: false,
          onClick: () => {
            setAnswer({ [propertyName]: true });
            handleCloseModal();
          }
        },
        {
          text: 'Ok, estou ciente',
          isConfirm: true,
          onClick: () => {
            setAnswer({ [propertyName]: false });
            handleCloseModal();
          }
        }
      ]
    });
    handleOpenModal();
  } else setAnswer({ [propertyName]: checked });
};

const MultipleCheckInput = function({
  question: { options, requiredMessage },
  answers,
  setAnswer,
  createNewQuote,
  previousQuestion,
  quoteServiceError,
  setQuoteService
}) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { isModalOpen, handleOpenModal, handleCloseModal, modalProps, setModalProps } = useModal();
  const { errorModalProps, setErrorModalProps } = useErrorModal();
  useUpdateAnswers({ options, setAnswer, answers });
  const htmlToReactParser = HtmlToReactParser.Parser();

  const setErrorModal = useCallback(
    ({ actions, message, icon, isOpen }) => {
      setErrorModalProps({
        actions,
        message,
        icon,
        isOpen
      });
    },
    [setErrorModalProps]
  );

  useEffect(() => {
    if (quoteServiceError?.hasError) {
      setErrorModal({
        actions: [
          {
            text: 'Ok',
            isConfirm: true,
            onClick: () => {
              setQuoteService({ hasError: false, message: '' });
              setErrorModalProps({ isOpen: false });
            }
          }
        ],
        message: quoteServiceError.message,
        icon: { component: muiIcons.ERROR },
        isOpen: true
      });
    }
  }, [quoteServiceError, setQuoteService, setErrorModal, setErrorModalProps]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="center" spacing={2}>
            {options.map(({ title, icon, propertyName, warning }) => (
              <Grid item key={propertyName}>
                <InputCheckbox
                  icone={`${process.env.PUBLIC_URL}/images/${icons[icon]}`}
                  inputText={htmlToReactParser.parse(title)}
                  checked={answers[propertyName] != null ? answers[propertyName] : true}
                  onChange={handleInputChange({
                    warning,
                    propertyName,
                    setModalProps,
                    setAnswer,
                    handleOpenModal,
                    handleCloseModal
                  })}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="center" className={classes.button}>
            <Grid item>
              <QuestionButtons
                disabled={loading}
                text="Sim, continuar"
                nextQuestion={handleNextQuestion({
                  options,
                  answers,
                  requiredMessage,
                  setModalProps,
                  handleOpenModal,
                  handleCloseModal,
                  history,
                  setLoading,
                  createNewQuote
                })}
                previousQuestion={previousQuestion}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        icon={errorModalProps.icon || modalProps.icon}
        message={errorModalProps.message || modalProps.message}
        actions={errorModalProps.actions || modalProps.actions}
        open={errorModalProps.isOpen || isModalOpen}
        onClose={() => {
          setErrorModalProps({ isOpen: false });
          setQuoteService({ hasError: false, message: '' });
          handleCloseModal();
        }}
      />
    </>
  );
};

MultipleCheckInput.propTypes = {
  question: PropTypes.object,
  answers: PropTypes.object,
  setAnswer: PropTypes.func.isRequired,
  createNewQuote: PropTypes.func.isRequired,
  previousQuestion: PropTypes.func.isRequired,
  quoteServiceError: PropTypes.object,
  setQuoteService: PropTypes.func.isRequired
};

export default MultipleCheckInput;
