import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Stepper from './StepperContainer';
import QuestionContainer from './QuestionContainer';
import { makeStyles } from '@material-ui/core/styles';
import { quoteOperations, quoteActions } from '../redux';

const useStyles = makeStyles({
  questionContainer: {
    marginTop: 10
  }
});

const NewQuotePage = function() {
  const classes = useStyles();
  const { location, push } = useHistory();

  const dispatch = useDispatch();
  const { questions, activeQuestion } = useSelector(({ quote: { questions, activeQuestion } }) => ({
    questions,
    activeQuestion
  }));
  const questionsSize = useMemo(() => questions.length, [questions]);
  const allowedQuestion = useMemo(() => questions.length > activeQuestion, [
    questions,
    activeQuestion
  ]);

  useEffect(() => {
    if (questionsSize === 0) {
      dispatch(quoteOperations.listQuestions());
    }
  }, [dispatch, questionsSize]);

  useEffect(() => {
    if (location.search === '?new' || !allowedQuestion) {
      dispatch(quoteOperations.cleanQuote());
      dispatch(quoteActions.setActiveQuestion(0));
      push({ search: '' });
    }
  }, [dispatch, location.search, push, allowedQuestion]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item style={{ margin: '30px 0 0 0' }}>
            <Stepper />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid className={classes.questionContainer} container justify="center">
          <Grid item>
            <QuestionContainer />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewQuotePage;
