import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CollapsableBlock from '../../../../shared/components/CollapsableBlock';
import LabeledText from '../../../../shared/components/LabeledText';
import { ReactComponent as User } from '../../../../shared/customSvgs/user_beneficiary.svg';
import { makeStyles } from '@material-ui/core/styles';
import { getFormatCpfOrCnpj } from '../../../../shared/components/CpfCnpjInput';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px 0px'
  },
  item: {
    padding: '5px 0 5px 0'
  }
}));

const ParticipantsBlock = function({ insured, beneficiary, brokers }) {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={12}>
        <CollapsableBlock
          title="Dados dos participantes"
          icon={{ component: User, style: { fill: '#ff6f0b' } }}
        >
          <Grid container justify="space-between" spacing={9}>
            <Grid item xs={8} className={classes.item}>
              <LabeledText label={'Segurado'} value={insured.name} />
              <LabeledText label={'Beneficiário'} value={beneficiary.name} />
              {brokers.map((broker, i) =>
                i === 0 ? (
                  <LabeledText key={broker.id} label={'Corretor(es)'} value={broker.name} />
                ) : (
                  <LabeledText key={broker.id} value={broker.name} />
                )
              )}
            </Grid>

            <Grid item xs={4}>
              <LabeledText
                label={'CPF/CNPJ Segurado'}
                value={getFormatCpfOrCnpj(insured.documentNumber)}
              />
              <LabeledText
                label={'CPF/CNPJ Beneficiário'}
                value={getFormatCpfOrCnpj(beneficiary.documentNumber)}
              />
              {brokers.map((broker, i) =>
                i === 0 ? (
                  <LabeledText
                    key={broker.id}
                    label={'CPF/CNPJ Corretor(es)'}
                    value={getFormatCpfOrCnpj(broker.documentNumber)}
                  />
                ) : (
                  <LabeledText key={broker.id} value={getFormatCpfOrCnpj(broker.documentNumber)} />
                )
              )}
            </Grid>
          </Grid>
        </CollapsableBlock>
      </Grid>
    </Grid>
  );
};

ParticipantsBlock.propTypes = {
  insured: PropTypes.object.isRequired,
  beneficiary: PropTypes.object.isRequired,
  brokers: PropTypes.array.isRequired
};

export default ParticipantsBlock;
