import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DocumentTableCell from './DocumentTableCell';
import { DocumentStatusChip } from './DocumentStatusChip';

const StyledTableRow = withStyles({
  root: {
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:last-child td': {
      borderBottom: 0
    },
    '&:hover': {
      backgroundColor: '#F5F8FB'
    }
  }
})(TableRow);

const DocumentTableRow = function({ item }) {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleClick = useCallback(() => {
    push(`${pathname}/${item.id}`);
    if (document.getElementById('rootApp')) {
      document.getElementById('rootApp').scrollTop = 0;
    }
  }, [pathname, push, item.id]);

  return (
    <StyledTableRow onClick={handleClick}>
      {[
        item.createdAt,
        item.number,
        item.periodStart,
        item.periodEnd,
        item.insured,
        item.premium
      ].map((row, index) => (
        <DocumentTableCell align="left" key={`${item.id}${index}`}>
          {row}
        </DocumentTableCell>
      ))}
      <DocumentTableCell align="left">
        <DocumentStatusChip status={item.status} />
      </DocumentTableCell>
      <DocumentTableCell align="left">
        <KeyboardArrowRight />
      </DocumentTableCell>
    </StyledTableRow>
  );
};

DocumentTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    periodStart: PropTypes.string.isRequired,
    periodEnd: PropTypes.string.isRequired,
    insured: PropTypes.string.isRequired,
    premium: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  })
};

export default DocumentTableRow;
