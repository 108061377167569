import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as documentOperations from '../redux/documentOperations';
import DocumentPageTemplate from './DocumentPageTemplate';
import DocumentTable from './DocumentTable';
import * as documentActions from './../../document/redux/documentActions';
import {
  usePolicyItems,
  useDocumentIsLoading,
  usePolicyOwnerId,
  useLastPolicyPolicyOwnerId,
  usePolicySequentialNumber
} from '../redux/documentCustomHooks';
import { setLastPolicyPolicyOwnerId } from '../redux/documentActions';

const PoliciesDocumentPage = function() {
  const dispatch = useDispatch();
  const isLoading = useDocumentIsLoading();
  const { items } = usePolicyItems();
  const policyOwnerId = usePolicyOwnerId();
  const lastPolicyPolicyOwnerId = useLastPolicyPolicyOwnerId();
  const policySequentialNumber = usePolicySequentialNumber();

  const policyOwnerChanged = useCallback(() => {
    return lastPolicyPolicyOwnerId !== policyOwnerId;
  }, [lastPolicyPolicyOwnerId, policyOwnerId]);

  useEffect(() => {
    if (policyOwnerChanged() && !isLoading && policyOwnerId) {
      dispatch(setLastPolicyPolicyOwnerId(policyOwnerId));
      dispatch(documentOperations.getPolicies(policyOwnerId));
    } else if (policyOwnerId === undefined && policyOwnerId !== lastPolicyPolicyOwnerId) {
      dispatch(setLastPolicyPolicyOwnerId(policyOwnerId));
      dispatch(documentActions.setPolicies([]));
    } else if (isLoading && policySequentialNumber) {
      dispatch(documentOperations.getPoliciesBySequentialNumber(policySequentialNumber));
    }
  }, [
    dispatch,
    policyOwnerChanged,
    isLoading,
    policyOwnerId,
    lastPolicyPolicyOwnerId,
    policySequentialNumber
  ]);

  return (
    <DocumentPageTemplate>
      <DocumentTable items={items} isLoading={isLoading} isPolicy={true} />
    </DocumentPageTemplate>
  );
};

export default PoliciesDocumentPage;
