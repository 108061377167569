import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import makeStyles from '@material-ui/styles/makeStyles';
import ButtonMenu from './ButtonMenu';
import { routes } from '../../../shared/constants/routes';

const useStyles = makeStyles({
  bodyGrid: {
    height: '100%'
  },
  paper: {
    width: '570px',
    borderRadius: '6px'
  },
  pageContent: {
    textAlign: 'center'
  },
  logo: {
    width: '30px',
    height: '30px',
    marginTop: '16px'
  },
  greeting: {
    fontSize: '24px',
    marginTop: '30px'
  },
  welcome: {
    width: '320px',
    fontSize: '14px',
    marginTop: '25px'
  },
  avatar: {
    width: '90px',
    height: '90px',
    marginTop: '25px'
  },
  questionBalloonArrow: {
    width: 0,
    height: 0,
    border: '10px solid transparent',
    borderBottomColor: '#efeff1'
  },
  questionBalloon: {
    backgroundColor: '#efeff1',
    borderRadius: '35px'
  },
  question: {
    fontSize: '14px',
    padding: '10px 40px'
  },
  footer: {
    fontSize: '10px',
    margin: '40px 0 20px 0'
  }
});

const PageContent = function() {
  const classes = useStyles();

  return (
    <Grid className={classes.pageContent} container justify="center" alignItems="center">
      <Grid item xs={12}>
        <img
          className={classes.logo}
          alt="Logo Pottencial"
          src={`${process.env.PUBLIC_URL}/assets/logo_pottencial.svg`}
        ></img>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.greeting}>Olá, bem vindo!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Typography className={classes.welcome}>
            Estamos muito felizes de ter você na nossa nova experiência em seguros!
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Avatar
            className={classes.avatar}
            alt="Avatar"
            src={`${process.env.PUBLIC_URL}/images/avatar_assistant_welcome.svg`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <div className={classes.questionBalloonArrow}></div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={9} sm={6}>
            <div className={classes.questionBalloon}>
              <Typography className={classes.question}>O que vamos fazer hoje?</Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <ButtonMenu
            link={routes.spreadsheet.path}
            icon="icon-file-text"
            text="Importação de Planilhas"
          />
          <ButtonMenu
            link={routes.proposalsDocuments.path}
            icon="icon-folder-open"
            text="Meus Documentos"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.footer}>Pottencial Seguradora</Typography>
      </Grid>
    </Grid>
  );
};

export const Welcome = function({ width }) {
  const classes = useStyles();

  return (
    <Grid className={classes.bodyGrid} container justify="center" alignItems="center">
      {width !== 'xs' ? (
        <Paper className={classes.paper} elevation={3}>
          <PageContent />
        </Paper>
      ) : (
        <Grid item xs={12}>
          <PageContent />
        </Grid>
      )}
    </Grid>
  );
};

export default withWidth()(Welcome);
