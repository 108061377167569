import _ from 'lodash';
import quoteService from './quoteService';
import { DocumentType } from '../../shared/constants/constants';

const mapProductCoveragesToQuote = ({ quote, productCoverages }) => {
  const newCoverages = productCoverages.map(productCoverage => {
    const coverage = productCoverage.coverage;
    const quoteCoverage = quote.coverages.find(cov => cov.id === coverage.id);

    changeMaxValueAdditionalCoverages(productCoverage, productCoverages, quote);

    return {
      ...coverage,
      totalInsuredAmount: quoteCoverage?.insuredAmount ?? 0,
      disabled: !quoteCoverage,
      included: quoteCoverage?.included ?? false,
      type: productCoverage.type,
      insuredAmountEditable: productCoverage.insuredAmountEditable,
      premium: quoteCoverage?.premium ?? 0
    };
  });

  const sortedNewCoverages = _.chain(newCoverages)
    .sortBy(['type'])
    .reverse()
    .value();

  return { ...quote, coverages: sortedNewCoverages };
};

const parsePerson = person => {
  const { id, type, name, documentNumber, addresses, documents, ...remaining } = person;

  return {
    id: id || '',
    documentNumber: documentNumber.trim(),
    name: name,
    type: type,
    address: addresses,
    addresses,
    documents: documents || [],
    ...remaining
  };
};

class QuoteManager {
  listQuestions = async () => {
    const { questions } = await quoteService.listQuestions();

    return questions;
  };

  moveToQuestion = ({ activeQuestion, questionsSize, direction }) => {
    const sumDirection = direction === 'NEXT' ? 1 : -1;
    const newPosition = activeQuestion + sumDirection;
    if (newPosition >= -1 && newPosition < questionsSize) return newPosition;
    else return activeQuestion;
  };

  getQuote = async ({ quoteId, productId }) => {
    const quote = await quoteService.getQuote(quoteId);
    const productCoverages = await quoteService.getCoveragesByProduct(productId);
    return mapProductCoveragesToQuote({ quote, productCoverages });
  };

  updateCoverage = async ({ quoteId, coverageId, coverageToUpdate }) => {
    const response = await quoteService.updateCoverage(quoteId, coverageId, coverageToUpdate);
    return response;
  };

  getProduct = async productId => {
    return await quoteService.getProductById(productId);
  };

  createNewQuote = async ({ answers, product, brokerId, commission }) => {
    const productCoverages = await quoteService.getCoveragesByProduct(product.id);
    const quoteProductCoverages = productCoverages.map(productCoverage => {
      return {
        id: productCoverage.coverage.id,
        type: productCoverage.type,
        name: productCoverage.coverage.name,
        netRate: productCoverage.defaultRate,
        insuredAmountMin: productCoverage.coverage.insuredAmount.min.value,
        insuredAmountMax: productCoverage.coverage.insuredAmount.max.value,
        insuredAmountEditable: productCoverage.insuredAmountEditable,
        insuredAmountFormula: productCoverage.coverage.insuredAmountFormula,
        restrictionExpression: productCoverage.coverage.restrictionExpression
      };
    });

    let broker = await quoteService.getPersonById(String(brokerId)).then(parsePerson);

    let brokerDocument = broker.documents.find(e => e.type === DocumentType.SusepRegistry);

    const quote = {
      commissionedAgents: [
        {
          commission: {
            type: 'Percentage',
            value: commission
          },
          personId: broker.id,
          documentNumber: broker.documentNumber,
          licenseNumber: brokerDocument.documentNumber,
          role: 'Broker'
        }
      ],
      status: 0,
      properties: { ...answers },
      product: {
        id: product.id,
        productSlug: 'FireInsurance',
        name: product.name,
        coverages: quoteProductCoverages
      }
    };

    const newQuote = await quoteService.createNewQuote(quote);
    return mapProductCoveragesToQuote({ quote: newQuote, productCoverages });
  };

  getUserProfile = quoteService.getUserProfile;

  patchCommission(quoteId, commission, brokerId) {
    let complement = {
      brokerId: brokerId,
      commission: {
        type: 'Percentage',
        value: commission
      }
    };

    return quoteService.patchCommission(quoteId, complement);
  }
}

export default new QuoteManager();

function changeMaxValueAdditionalCoverages(productCoverage, productCoverages, quote) {
  if (productCoverage.type === 'ADDITIONAL') {
    const basicCoverageId = productCoverages.find(coverage => coverage.type === 'BASIC').coverage
      .id;
    const basicCoverageValue = quote.coverages.find(coverage => coverage.id === basicCoverageId)
      .insuredAmount;
    productCoverage.coverage.insuredAmount.max.value =
      basicCoverageValue * productCoverage.coverage.insuredAmount.max.percent;
  }
}
