export const mountCoverages = (
  basic,
  lossPayment,
  naturalDisaster,
  electricalDamage,
  vehicleImpact
) => {
  if (basic && basic !== 'IS INCENDIO E CONTEUDO') {
    let arrayCoverages = [];

    const coverageBasic = {
      type: 'BASIC',
      name: 'Incêndio e Conteúdo',
      insuredAmount: basic
    };
    arrayCoverages.push(coverageBasic);

    if (lossPayment) {
      const coverageLossPayment = {
        type: 'ADDITIONAL',
        name: 'Perda Pagamento de Aluguel',
        insuredAmount: lossPayment
      };
      arrayCoverages.push(coverageLossPayment);
    }

    if (naturalDisaster) {
      const coverageNaturalDisaster = {
        type: 'ADDITIONAL',
        name: 'Vendaval, Furacão, Ciclone, Tornado, Granizo',
        insuredAmount: naturalDisaster
      };
      arrayCoverages.push(coverageNaturalDisaster);
    }

    if (electricalDamage) {
      const coverageElectricalDamage = {
        type: 'ADDITIONAL',
        name: 'Danos elétricos',
        insuredAmount: electricalDamage
      };
      arrayCoverages.push(coverageElectricalDamage);
    }

    if (vehicleImpact) {
      const coverageVehicleImpact = {
        type: 'ADDITIONAL',
        name: 'Impacto de veículos terrestres',
        insuredAmount: vehicleImpact
      };
      arrayCoverages.push(coverageVehicleImpact);
    }

    return arrayCoverages;
  } else return [];
};
