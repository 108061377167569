import axios from 'axios';

class DocumentService {
  getProposalsByUser = async userId => {
    const response = await axios.get(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/?policyOwnerId=${userId}`
    );
    return response.data;
  };

  getPoliciesByUser = async userId => {
    const response = await axios.get(
      `${window.env.REACT_APP_ISSUANCE_ENDPOINT}/api/policies/?policyOwnerId=${userId}`
    );
    return response.data;
  };

  getPoliciesBySequentialNumber = async sequentialNumber => {
    const response = await axios.get(
      `${window.env.REACT_APP_ISSUANCE_ENDPOINT}/api/policies/?sequentialNumber=${sequentialNumber}`
    );
    return response.data;
  };

  getProposalById = async proposalId => {
    const response = await axios.get(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/${proposalId}`
    );
    return response.data;
  };

  getPersonById = async id => {
    const response = await axios.get(`${window.env.REACT_APP_PERSON_ENDPOINT}/api/persons/${id}`);

    return response.data;
  };

  getQuoteById = async ({ quoteId }) => {
    const response = await axios.get(
      `${window.env.REACT_APP_QUOTE_ENDPOINT}/api/quotes/${quoteId}`
    );
    return response.data;
  };

  getProductById = async ({ productId }) => {
    const response = await axios.get(
      `${window.env.REACT_APP_PRODUCT_ENDPOINT}/api/products/${productId}`
    );
    return response.data;
  };

  addPaymentProposal = async ({ proposalId, paymentPayload }) => {
    const paymentProposal = await axios.post(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/${proposalId}/payments`,
      paymentPayload
    );
    return paymentProposal.data;
  };

  acceptProposal = async ({ proposalId, acceptPayload }) => {
    const proposalResponse = await axios.post(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/${proposalId}/accept`,
      acceptPayload
    );
    return proposalResponse.data;
  };

  getDocumentPdfUrl = async ({ documentFileName, templateKey }) => {
    const documentResponse = await axios.get(
      `${window.env.REACT_APP_DOCUMENT_ENDPOINT}/api/documents/storage/${documentFileName}?templateKey=${templateKey}`
    );
    return documentResponse.data;
  };

  getProposalBoletoUrl = async ({ proposalId }) => {
    const paymentsResponse = await axios.get(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/${proposalId}/payments`
    );
    return paymentsResponse.data;
  };

  getPolicyById = async ({ policyId }) => {
    const response = await axios.get(
      `${window.env.REACT_APP_ISSUANCE_ENDPOINT}/api/policies/${policyId}`
    );
    return response.data;
  };

  createProposal = async ({ proposal }) => {
    const proposalResponse = await axios.post(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals`,
      proposal
    );

    return proposalResponse.data;
  };

  createProposalPdf = async ({ proposalId }) => {
    const proposalPdf = await axios.post(
      `${window.env.REACT_APP_PROPOSAL_ENDPOINT}/api/proposals/${proposalId}/pdf`
    );
    return proposalPdf.data;
  };

  cancelPolicy = async (policyId, cancelPolicyRequest) => {
    const response = await axios.delete(
      `${window.env.REACT_APP_ISSUANCE_ENDPOINT}/api/policies/${policyId}`,
      { data: cancelPolicyRequest }
    );
    return response.data;
  };

  issuePolicy = async ({ policyId }) =>
    await axios.post(`${window.env.REACT_APP_ISSUANCE_ENDPOINT}/api/policies/${policyId}/issuance`);
}

export default new DocumentService();
