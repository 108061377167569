import { batch } from 'react-redux';
import documentManager from '../documentManager';
import * as documentActions from './documentActions';
import { push } from 'connected-react-router';
import { authManager } from '../../auth/authManager.ig';
import { tabStates } from '../documentConstants';
import { roleType } from '../../../shared/constants/constants';

export const setTabState = tabState => dispatch => {
  dispatch(push(tabStates[tabState]));
};

export const getProposals = policyOwnerId => async dispatch => {
  dispatch(documentActions.setLoadingDocument(true));

  let responseProposals = await documentManager.getProposalsByUser(policyOwnerId);

  if (responseProposals) {
    for (let responseProposal of responseProposals) {
      const insured = responseProposal.participants.find(
        participant => participant.role === roleType.insured
      );

      const insuredName = (await documentManager.getPersonById(insured)).name;
      responseProposal.participants = responseProposal.participants.map(participant => {
        return participant.role === roleType.insured
          ? {
              ...participant,
              name: insuredName
            }
          : participant;
      });
    }
  }

  batch(() => {
    dispatch(documentActions.setLoadingDocument(false));
    dispatch(documentActions.setProposals(responseProposals));
  });
};

export const getPolicies = policyOwnerId => async dispatch => {
  try {
    dispatch(documentActions.setLoadingDocument(true));

    let responsePolicies = await documentManager.getPoliciesByUser(policyOwnerId);

    if (responsePolicies) {
      for (let responsePolicy of responsePolicies) {
        const insured = responsePolicy.participants.find(
          participant => participant.role === roleType.insured
        );

        const insuredName = (await documentManager.getPersonById(insured)).name;
        responsePolicy.participants = responsePolicy.participants.map(participant => {
          return participant.role === roleType.insured
            ? {
                ...participant,
                name: insuredName
              }
            : participant;
        });
      }
    }

    batch(() => {
      dispatch(documentActions.setLoadingDocument(false));
      dispatch(documentActions.setPolicies(responsePolicies));
    });
  } catch {
    dispatch(documentActions.updateDocumentService({ hasError: true }));
  }
};

export const getPoliciesBySequentialNumber = sequentialNumber => async dispatch => {
  try {
    dispatch(documentActions.setLoadingDocument(true));

    let responsePolicies = await documentManager.getPoliciesBySequentialNumber(sequentialNumber);

    if (responsePolicies) {
      for (let responsePolicy of responsePolicies) {
        const insured = responsePolicy.participants.find(
          participant => participant.role === roleType.insured
        );

        const insuredName = (await documentManager.getPersonById(insured)).name;
        responsePolicy.participants = responsePolicy.participants.map(participant => {
          return participant.role === roleType.insured
            ? {
                ...participant,
                name: insuredName
              }
            : participant;
        });
      }
    }

    batch(() => {
      dispatch(documentActions.setLoadingDocument(false));
      dispatch(documentActions.setPolicies(responsePolicies));
    });
  } catch {
    dispatch(documentActions.updateDocumentService({ hasError: true }));
  }
};

export const getProposalById = proposalId => async (dispatch, getState) => {
  try {
    const {
      document: { proposals, activeProposal }
    } = getState();

    const newProposal = proposals.find(proposal => proposal.id === proposalId);

    if (newProposal && newProposal.id !== activeProposal.id) {
      dispatch(documentActions.setActiveProposal({ ...newProposal }));
    } else {
      const proposal = await documentManager.getProposalById(proposalId);
      dispatch(documentActions.setActiveProposal({ ...proposal }));
    }
  } catch {
    dispatch(documentActions.updateDocumentService({ hasError: true }));
  }
};

export const downloadProposalPdfUrl = () => async (dispatch, getState) => {
  const {
    document: { activeProposal }
  } = getState();

  try {
    dispatch(documentActions.setLoadingDocument(true));

    if (activeProposal.documentPdfUrl)
      documentManager.downloadDocument({ documentUrl: activeProposal.documentPdfUrl });
    else {
      const documentFileName = activeProposal.documentName;

      const documentResponse = await documentManager.getDocumentPdfUrl({
        documentFileName,
        templateKey: 'fireinsurance-proposal'
      });

      dispatch(
        documentActions.setActiveProposal({
          ...activeProposal,
          documentPdfUrl: documentResponse.url
        })
      );
      documentManager.downloadDocument({ documentUrl: documentResponse.url });
    }
  } catch {
    dispatch(documentActions.updateDocumentService({ hasError: true }));
  } finally {
    dispatch(documentActions.setLoadingDocument(false));
  }
};

export const downloadDocumentBoletoUrl = () => async (dispatch, getState) => {
  const {
    document: { activeProposal }
  } = getState();

  try {
    dispatch(documentActions.setLoadingDocument(true));

    if (activeProposal.documentBoletoUrl)
      documentManager.downloadDocument({ documentUrl: activeProposal.documentBoletoUrl });
    else {
      const paymentsResponse = await documentManager.getProposalBoletoUrl({
        proposalId: activeProposal.id
      });

      const documentFileName = paymentsResponse[0].receipt.documentName;

      const documentResponse = await documentManager.getDocumentPdfUrl({
        documentFileName,
        templateKey: 'boleto'
      });

      dispatch(
        documentActions.setActiveProposal({
          ...activeProposal,
          documentBoletoUrl: documentResponse.url
        })
      );
      documentManager.downloadDocument({ documentUrl: documentResponse.url });
    }
  } catch {
    dispatch(documentActions.updateDocumentService({ hasError: true }));
  } finally {
    dispatch(documentActions.setLoadingDocument(false));
  }
};

export const acceptProposal = () => async (dispatch, getState) => {
  const {
    document: { activeProposal }
  } = getState();

  try {
    dispatch(documentActions.setLoadingDocument(true));

    let { quote, product } = activeProposal;

    let insured = activeProposal.participants.find(participant => participant.role === 'Insured');

    [insured, product, quote] = await Promise.all([
      documentManager.getPersonById({ personId: insured.id }),
      documentManager.getProductById({ productId: product.id }),
      documentManager.getQuoteById({ quoteId: quote.id })
    ]);

    quote.product = product;

    await documentManager.addPaymentToProposal({
      proposalId: activeProposal.id,
      userInsured: insured,
      amount: quote.premium,
      product: quote.product,
      address: activeProposal.properties.riskLocation
    });

    const user = await authManager.getUser();

    await documentManager.acceptProposal({
      proposalId: activeProposal.id,
      userId: user.profile.person_id
    });

    batch(() => {
      dispatch(
        documentActions.setActiveProposal({
          ...activeProposal,
          status: 'ACCEPTED'
        })
      );
      dispatch(documentActions.updateDocumentService({ hadSucceeded: true }));
    });
  } catch {
    dispatch(documentActions.updateDocumentService({ hasError: true }));
  } finally {
    dispatch(documentActions.setLoadingDocument(false));
  }
};

export const cancelPolicy = (cancellmentReason, endorsementStartDate) => async (
  dispatch,
  getState
) => {
  try {
    const {
      document: { activePolicy }
    } = getState();

    const cancelPolicyData = {
      lastProposalId: activePolicy.proposal.id,
      lastPolicyId: activePolicy.id,
      lastPolicyNumber: activePolicy.policyNumber,
      cancellmentReason,
      endorsementStartDate
    };

    dispatch(documentActions.setLoadingDocument(true));
    await documentManager.cancelPolicy(cancelPolicyData);
    dispatch(documentActions.updateDocumentService({ hasError: false, hadSucceeded: true }));
  } catch (ex) {
    console.log(ex); //TODO remove this log when show message on error
    dispatch(documentActions.updateDocumentService({ hasError: true }));
  } finally {
    dispatch(documentActions.setLoadingDocument(false));
  }
};
