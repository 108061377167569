import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  secondSkeleton: {
    marginTop: 20
  }
});

export const DocumentDetailsSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton animation="wave" height={150} variant="rect" />
      <Skeleton animation="wave" height={250} variant="rect" className={classes.secondSkeleton} />
    </>
  );
};
