import * as documentActions from '../redux/documentActions';

export const INITIAL_STATE = {
  proposals: [],
  policies: [],
  activeProposal: {},
  activePolicy: {},
  status: {
    documentService: { hasError: false, hadSucceeded: false }
  },
  policyOwnerId: '',
  lastProposalPolicyOwnerId: '',
  lastPolicyPolicyOwnerId: '',
  isLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case documentActions.SET_LOADING_DOCUMENT:
      return { ...state, isLoading: action.payload };
    case documentActions.SET_PROPOSALS:
      return { ...state, proposals: action.payload };
    case documentActions.SET_POLICIES:
      return { ...state, policies: action.payload };
    case documentActions.SET_ACTIVE_PROPOSAL:
      return { ...state, activeProposal: action.payload };
    case documentActions.SET_ACTIVE_POLICY:
      return { ...state, activePolicy: action.payload };
    case documentActions.UPDATE_DOCUMENT_SERVICE:
      return {
        ...state,
        status: {
          ...state.status,
          documentService: { ...state.status.documentService, ...action.payload }
        }
      };
    case documentActions.SET_POLICY_OWNER_ID:
      return { ...state, policyOwnerId: action.payload, isLoading: false };
    case documentActions.SET_DOCUMENT_NUMBER:
      return { ...state, documentNumber: action.payload };
    case documentActions.SET_POLICY_NUMBER:
      return { ...state, policyNumber: action.payload };
    case documentActions.SET_POLICY_SEQUENTIAL_NUMBER:
      return { ...state, policySequentialNumber: action.payload };
    case documentActions.SET_LABEL_NAME:
      return { ...state, labelName: action.payload };
    case documentActions.SET_LAST_PROPOSAL_POLICY_OWNER_ID:
      return { ...state, lastProposalPolicyOwnerId: action.payload };
    case documentActions.SET_LAST_POLICY_POLICY_OWNER_ID:
      return { ...state, lastPolicyPolicyOwnerId: action.payload };
    default:
      return state;
  }
};
