import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as selectors from './quoteSelectors';
import * as actions from './quoteActions';
import * as operations from './quoteOperations';

export const useActiveQuestion = () =>
  useSelector(state => selectors.getActiveQuestion(state.quote));

export const useAnswer = name => {
  const dispatch = useDispatch();
  const answer = useSelector(selectors.getAnswer(name));
  const setAnswer = useCallback(answer => dispatch(actions.setAnswer({ [name]: answer })), [
    dispatch,
    name
  ]);

  return { answer, setAnswer };
};

export const useStepperActions = () => {
  const dispatch = useDispatch();
  const nextQuestion = useCallback(() => dispatch(operations.goToNextQuestion()), [dispatch]);
  const previousQuestion = useCallback(() => dispatch(operations.goToPreviousQuestion()), [
    dispatch
  ]);

  return { nextQuestion, previousQuestion };
};
