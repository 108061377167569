import documentService from './documentService';
import { addDays } from 'date-fns';
import { memoize } from 'lodash';
import {
  CPF_SIZE,
  CNPJ_SIZE,
  documentType,
  DEFAULT_BILL_DUEDAYS
} from '../../shared/constants/constants';
import { authManager } from '../auth/authManager.ig';

const parsePerson = person => {
  const { id, type, name, documentNumber, addresses, documents, ...remaining } = person;

  return {
    id: id || '',
    documentNumber: documentNumber.trim(),
    name: name,
    type: type,
    address: addresses,
    addresses,
    documents: documents || [],
    ...remaining
  };
};

const getDocumentType = ({ documentNumber }) => {
  if (documentNumber.length <= CPF_SIZE) return documentType.CPF;
  else if (documentNumber.length > CPF_SIZE && documentNumber.length <= CNPJ_SIZE)
    return documentType.CNPJ;
};

const getDateAddingDays = (days = DEFAULT_BILL_DUEDAYS) => {
  const today = new Date();
  const dueDate = addDays(today, days);
  return dueDate.toISOString();
};

const download = ({ url }) => window.open(url, '_self');

class DocumentManager {
  getPolicyById = async policyId => {
    const policy = await documentService.getPolicyById({ policyId });

    const participantsPromises = policy.participants.map(async participant => {
      return {
        ...participant,
        name: (await this.getPersonById(participant)).name
      };
    });
    const commissionedAgentsPromises = policy.commissionedAgents.map(async commissionedAgent => {
      return {
        ...commissionedAgent,
        name: (await this.getPersonById(commissionedAgent)).name
      };
    });

    policy.participants = await Promise.all(participantsPromises);
    policy.commissionedAgents = await Promise.all(commissionedAgentsPromises);
    return policy;
  };

  getProposalsByUser = async userId => {
    const proposals = await documentService.getProposalsByUser(userId);
    return proposals;
  };

  getProposalById = async proposalId => {
    const proposal = await documentService.getProposalById(proposalId);
    const participantsPromises = proposal.participants.map(async participant => {
      return {
        ...participant,
        name: (await this.getPersonById(participant)).name
      };
    });
    const commissionedAgentsPromises = proposal.commissionedAgents.map(async commissionedAgent => {
      return {
        ...commissionedAgent,
        name: (await this.getPersonById(commissionedAgent)).name
      };
    });

    proposal.participants = await Promise.all(participantsPromises);
    proposal.commissionedAgents = await Promise.all(commissionedAgentsPromises);
    return proposal;
  };

  getPoliciesByUser = async userId => {
    const policies = await documentService.getPoliciesByUser(userId);
    return policies;
  };

  getPoliciesBySequentialNumber = async sequentialNumber => {
    const policies = await documentService.getPoliciesBySequentialNumber(sequentialNumber);
    return policies;
  };

  getPersonById = ({ personId }) => documentService.getPersonById(personId).then(parsePerson);

  getQuoteById = async ({ quoteId }) => {
    return await documentService.getQuoteById({ quoteId });
  };

  getProductById = async ({ productId }) => {
    return await documentService.getProductById({ productId });
  };

  acceptProposal = async ({ proposalId, userId }) => {
    return await documentService.acceptProposal({
      proposalId: proposalId,
      acceptPayload: { userId: userId }
    });
  };

  getDocumentPdfUrl = async ({ documentFileName, templateKey }) => {
    return await documentService.getDocumentPdfUrl({ documentFileName, templateKey });
  };

  getProposalBoletoUrl = async ({ proposalId }) => {
    return await documentService.getProposalBoletoUrl({ proposalId });
  };

  downloadDocument = ({ documentUrl }) => {
    window.open(`${documentUrl}`, '_self');
  };

  addPaymentToProposal = async ({ proposalId, userInsured, amount, product, address }) => {
    const payload = {
      Type: 'Boleto',
      Bank: 'Bradesco',
      Instructions:
        '<b>Instruções de responsabilidade do cedente.<br>*** Valores expressos em R$ ***<br>Após vencimento mora dia R$ 0.34</b>' +
        '<br>NÃO RECEBER APÓS 7 DIAS DE VENCIDO<br>Documento: 9999 / Parcela: 1 de 1',
      DueDate: getDateAddingDays(product.billDueDays),
      InterestAmount: null,
      DelayAmount: null,
      TaxesAmount: null,
      ProviderName: 'Pottencial',
      Amount: amount,
      Currency: 'BRL',
      Options: {},
      Customer: {
        CustomerCode: userInsured.id,
        DocumentType: getDocumentType(userInsured),
        Name: userInsured.name,
        DocumentNumber: userInsured.documentNumber,
        BillingAddress: {
          Street: address.street,
          Number: address.number,
          District: address.district,
          Complement: address.complement,
          City: address.city,
          State: address.state,
          Country: 'Brasil',
          ZipCode: address.zipCode
        }
      }
    };

    return await documentService.addPaymentProposal({
      proposalId: proposalId,
      paymentPayload: payload
    });
  };

  downloadPolicyPdf = memoize(documentName =>
    this.getDocumentPdfUrl({
      documentFileName: documentName,
      templateKey: 'fireinsurance-policy'
    }).then(download)
  );

  cancelPolicy = async ({
    lastProposalId,
    lastPolicyId,
    lastPolicyNumber,
    cancellmentReason,
    endorsementStartDate
  }) => {
    const user = await authManager.getUser();
    const personId = user.profile.person_id;

    const newProposalRequest = {
      type: 'CANCELLMENT',
      lastProposalId: lastProposalId,
      lastPolicyId: lastPolicyId,
      lastPolicyNumber: lastPolicyNumber,
      cancellmentReason: cancellmentReason,
      personId: personId,
      endorsementStartDate: endorsementStartDate
    };

    const newProposal = await documentService.createProposal({ proposal: newProposalRequest });
    await documentService.createProposalPdf({ proposalId: newProposal.id });
    await documentService.acceptProposal({
      proposalId: newProposal.id,
      acceptPayload: { personId }
    });

    const cancelPolicyRequest = {
      proposalId: newProposal.id,
      cancellmentReason: cancellmentReason,
      userId: personId,
      proposalNumber: newProposal.proposalNumber,
      proposalAmount: newProposal.amount,
      endorsementStartDate: endorsementStartDate
    };

    const newPolicy = await documentService.cancelPolicy(lastPolicyId, cancelPolicyRequest);
    await documentService.issuePolicy({ policyId: newPolicy.id });
  };
}

export default new DocumentManager();
