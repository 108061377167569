import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { quoteActions, quoteOperations, quoteSelectors } from '../redux';
import MultipleCheckInput from './MultipleCheckInput';

const mapStateToProps = ({ quote }) => {
  return {
    question: quoteSelectors.getActiveQuestion(quote),
    answers: { ...quote.answers },
    quoteServiceError: { ...quote.status.quoteService }
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAnswer: quoteActions.setAnswer,
      createNewQuote: quoteOperations.createNewQuote,
      previousQuestion: quoteOperations.goToPreviousQuestion,
      setQuoteService: quoteActions.setQuoteService
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MultipleCheckInput);
