import React from 'react';
import { withRouter } from 'react-router';
import Menu from '../components/Menu';
import { zIndex } from '../constants/zIndex';
import { routes } from '../constants/routes';
import { authManager } from '../../features/auth/authManager.ig';

export default function withMenu(WrappedComponent) {
  return withRouter(function({ history }) {
    return (
      <>
        <WrappedComponent />
        <Menu
          zIndex={zIndex.FLOATING_MENU}
          menuItens={[
            {
              title: 'Home',
              icon: 'icon-home',
              onClick: () => history.push(routes.home.path),
              disabled: false
            },
            {
              title: 'Nova Cotação',
              icon: 'icon-calculator',
              onClick: () => history.push(`${routes.newQuote.path}?new`),
              disabled: true
            },
            {
              title: 'Meus Documentos',
              icon: 'icon-folder-open',
              onClick: () => history.push(routes.proposalsDocuments.path),
              disabled: false
            },
            {
              title: 'Importação de Planilhas',
              icon: 'icon-file-text',
              onClick: () => history.push(routes.spreadsheet.path),
              disabled: false
            },
            {
              title: 'Sair',
              icon: 'icon-sign-out',
              onClick: () => authManager.getUserManager().signoutRedirect(),
              disabled: false
            }
          ]}
        />
      </>
    );
  });
}
