import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authManager } from './authManager.ig';
import { routes } from '../../shared/constants/routes';

const userManager = authManager.getUserManager();

export const useLoginStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const { push } = useHistory();

  if (loggedIn) return true;

  authManager.getUser().then(user => {
    if (!user) return userManager.signinRedirect();

    if (!(user.profile.person_id && user.profile.policy_owner_id)) {
      push(routes.auth.errorLogin);
    } else {
      return setLoggedIn(true);
    }
  });
};
