import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CoverageRow from './CoverageRow';
import CollapsableBlock from '../../../shared/components/CollapsableBlock';
import { ReactComponent as Home } from '../../../shared/customSvgs/home.svg';

const useStyles = makeStyles(theme => ({
  block: {
    margin: '0 auto'
  },
  formControl: {
    marginTop: 15
  },
  input: {
    width: '85%',
    fontWeight: 400
  },
  divider: {
    marginTop: 20,
    marginBottom: 40,
    color: '#979797'
  }
}));

const CoveragesBlock = function({ includedCoverages }) {
  const classes = useStyles();

  return (
    <CollapsableBlock title="Coberturas" icon={{ component: Home }}>
      <Grid container>
        {includedCoverages.map((coverage, index) => (
          <Grid item xs={12} key={coverage.id}>
            <CoverageRow coverage={coverage} />
            {index !== includedCoverages.length - 1 ? (
              <Grid item xs={12}>
                <Divider className={classes.divider} variant="middle" />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        ))}
      </Grid>
    </CollapsableBlock>
  );
};

CoveragesBlock.propTypes = {
  includedCoverages: PropTypes.array.isRequired
};

export default CoveragesBlock;
