import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CustomCoverageCard from '../../../shared/components/CoverageCard';
import { icons, getIconFromImages } from '../../../shared/constants/icons';

const titleImages = {
  BASIC: {
    title: 'Cobertura Básica',
    hasSwitch: false
  },
  ADDITIONAL: {
    title: 'Cobertura Adicional',
    hasSwitch: true
  }
};

const CoverageCard = function({ coverage, updateCoverage, isLoading, quoteServiceError }) {
  const { type, name, totalInsuredAmount, icon, disabled, id, included } = coverage;

  const [inputValue, setInputValue] = useState(totalInsuredAmount);
  const [error, setError] = useState(false);

  const minCoverageValue = coverage.insuredAmount.min.value;
  const maxCoverageValue = coverage.insuredAmount.max.value;

  useEffect(() => {
    setInputValue(totalInsuredAmount);
  }, [totalInsuredAmount]);

  useEffect(() => {
    if (quoteServiceError && inputValue < totalInsuredAmount) setInputValue(totalInsuredAmount);
  }, [quoteServiceError, inputValue, setInputValue, totalInsuredAmount]);

  const isEditable = useCallback(() => coverage?.insuredAmountEditable ?? true, [coverage]);

  const handleChangeSwitch = value => {
    updateCoverageIfEditable(value, inputValue);
  };

  const updateCoverageIfEditable = useCallback(
    (included, insuredAmount) => {
      const updatedCoverage = isEditable() ? { included, insuredAmount } : { included };
      updateCoverage(id, { ...updatedCoverage });
    },
    [id, isEditable, updateCoverage]
  );

  const handleBlurInput = useCallback(
    newValue => {
      if (newValue >= minCoverageValue && newValue <= maxCoverageValue) {
        setError(false);

        if (newValue !== totalInsuredAmount) {
          updateCoverageIfEditable(included, newValue);
        }
      } else {
        setError(true);
      }
    },
    [minCoverageValue, maxCoverageValue, updateCoverageIfEditable, totalInsuredAmount, included]
  );

  return (
    <CustomCoverageCard
      {...titleImages[type]}
      imageTitle={getIconFromImages(icons[icon])}
      disabled={isLoading || disabled}
      inputDisabled={!isEditable()}
      content={name}
      inputValue={inputValue}
      onChangeInput={setInputValue}
      onBlurInput={handleBlurInput}
      switchValue={included}
      onChangeSwitch={handleChangeSwitch}
      updateCoverage={updateCoverage}
      minInputValue={minCoverageValue}
      maxInputValue={maxCoverageValue}
      error={error}
    />
  );
};

CoverageCard.propTypes = {
  coverage: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  quoteServiceError: PropTypes.bool.isRequired
};

export default CoverageCard;
