import { isEmpty } from 'lodash';
import { tabPaths, documentStatus } from '../documentConstants';
import { getQuoteConverted } from '../documentShared';
import { formatToBrlDate } from '../../../shared/functions/formatToBrlDate';
import { formatToBrlMoney } from '../../../shared/functions/formatToBrlMoney';

export function getProposalsForTable({ document }) {
  return document.proposals.map(item => ({
    id: item.id,
    createdAt: formatToBrlDate(item.createdAt),
    broker: item.commissionedAgents.find(agent => agent.role === 'Broker').name,
    policyOwner: item.commissionedAgents.find(agent => agent.role === 'PolicyOwner').name,
    number: item.proposalNumber,
    periodStart: formatToBrlDate(item.policyPeriodStart),
    periodEnd: formatToBrlDate(item.policyPeriodEnd),
    insured: item.participants.find(participant => participant.role === 'Insured').name,
    beneficiary: item.participants.find(participant => participant.role === 'Beneficiary').name,
    premium: formatToBrlMoney(item.quote.premium),
    status: item.status
  }));
}

export function getPoliciesForTable({ document }) {
  return document.policies.map(item => ({
    id: item.id,
    createdAt: formatToBrlDate(item.createdAt),
    broker: item.commissionedAgents.find(agent => agent.role === 'Broker').name,
    policyOwner: item.commissionedAgents.find(agent => agent.role === 'PolicyOwner').name,
    number: !item.policyNumber ? '-' : item.policyNumber.toString().slice(18),
    periodStart: formatToBrlDate(item.policyPeriodStart),
    periodEnd: formatToBrlDate(item.policyPeriodEnd),
    insured: item.participants.find(participant => participant.role === 'Insured').name,
    beneficiary: item.participants.find(participant => participant.role === 'Beneficiary').name,
    premium: formatToBrlMoney(item.quote.premium),
    status: item.issuanceAt ? documentStatus.ISSUED : documentStatus.EMITTING
  }));
}

export function getStateByPath({ router }) {
  return tabPaths[router.location.pathname];
}

export function getProposalForDetails({ document }) {
  const proposal = document.activeProposal;
  if (!isEmpty(proposal)) {
    return {
      ...proposal,
      quote: getQuoteConverted(proposal.quote),
      status: proposal.status
    };
  } else {
    return proposal;
  }
}
