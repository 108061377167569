import React from 'react';
import { useHistory } from 'react-router-dom';
import { Callback } from 'react-oidc';
import { authManager } from '../authManager.ig';
import { routes } from '../../../shared/constants/routes';

const CallbackPage = () => {
  const { push } = useHistory();

  return (
    <Callback
      onSuccess={() => {
        authManager.setAxiosAuthorizationToken();
        push(routes.home.path);
      }}
      userManager={authManager.getUserManager()}
    />
  );
};

export default CallbackPage;
