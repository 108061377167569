import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { quoteActions, quoteOperations, quoteSelectors } from '../redux';
import CustomMoneyInput from './MoneyInput';

const mapStateToProps = ({ quote }) => {
  return {
    question: quoteSelectors.getActiveQuestion(quote),
    answers: { ...quote.answers }
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAnswer: quoteActions.setAnswer,
      nextQuestion: quoteOperations.goToNextQuestion,
      previousQuestion: quoteOperations.goToPreviousQuestion
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomMoneyInput);
