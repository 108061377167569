import { useState } from 'react';

export default function() {
  const [open, setOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return {
    isModalOpen: open,
    handleOpenModal,
    handleCloseModal,
    modalProps,
    setModalProps
  };
}
