import React from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    width: 220,
    height: 45,
    textAlign: 'center',
    fontSize: 14,
    margin: '10px 0 5px 0',
    borderRadius: 12,
    letterSpacing: '1px',
    '&:not(:last-child)': {
      marginRight: 20
    }
  }
});

export const DocumentDetailsButton = props => {
  const classes = useStyles();

  const Wrapper = props.tooltip ? Tooltip : React.Fragment;

  return (
    <Wrapper {...props.tooltip}>
      <div>
        <Button {...props} className={clsx(classes.root, props.className)} />
      </div>
    </Wrapper>
  );
};

DocumentDetailsButton.propTypes = {
  tooltip: PropTypes.object
};
