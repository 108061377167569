import PropTypes from 'prop-types';

const Currency = function({ children }) {
  return isNaN(children)
    ? ''
    : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(children);
};

Currency.propTypes = {
  children: PropTypes.number
};

export default Currency;
