import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CPF_SIZE, CNPJ_SIZE } from '../constants/constants';
import { isValidCpf, formatCpf, isValidCnpj, formatCnpj } from '../helper/cpfCnpjImportHelper.ig';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    width: '100%',
    fontWeight: 400
  },
  helperText: {
    fontSize: '0.585rem',
    marginTop: 10,
    textAlign: 'center',
    letterSpacing: 0
  },
  input: {
    marginTop: 1
  }
}));

export const validatorCpfOrCnpj = value => {
  if (!value) return { type: '', value: true };

  let returnValue = { type: '', value: false };

  if (value.length <= CPF_SIZE) returnValue = { ...returnValue, type: 'CPF' };
  else if (value.length > CPF_SIZE && value.length <= CNPJ_SIZE)
    returnValue = { ...returnValue, type: 'CNPJ' };
  if (isValidCpf(value) || isValidCnpj(value)) returnValue = { ...returnValue, value: true };

  return returnValue;
};

export const getFormatCpfOrCnpj = value => {
  if (!value) return;
  if (value.length <= CPF_SIZE) {
    return formatCpf(value);
  } else if (value.length > CPF_SIZE && value.length <= CNPJ_SIZE) {
    return formatCnpj(value);
  } else {
    return value;
  }
};

const NumberFormatCustom = function(props) {
  const { onChange, value, inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={value || ''}
      allowLeadingZeros={true}
      format={values => getFormatCpfOrCnpj(values)}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isAllowed={values => values.value.length <= CNPJ_SIZE}
      getInputRef={inputRef}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function CpfCnpjInput({
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  disabled,
  required,
  helperError
}) {
  const classes = useStyles();
  const [error, setError] = useState('');

  const hasError = !!error || !!helperError;
  const errorMessage = error || helperError;

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        {label && (
          <InputLabel error={hasError} required={required}>
            {label}
          </InputLabel>
        )}
        <Input
          error={hasError}
          placeholder={placeholder || ''}
          value={value}
          className={classes.input}
          onChange={e => {
            onChange(e.target.value);
            setError('');
          }}
          onBlur={() => {
            const { value: validatorValue, type: validatorType } = validatorCpfOrCnpj(value);
            if (!validatorValue) setError(`${validatorType} inválido`);
            onBlur && onBlur();
          }}
          inputComponent={NumberFormatCustom}
          disabled={disabled}
        />
        {hasError && <FormHelperText error={hasError}>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}

CpfCnpjInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};
