import { createMuiTheme } from '@material-ui/core/styles';

export const palette = {
  primary: {
    main: '#ff6f0b',
    contrastText: '#ffffff',
    cancel: {
      light: '#de4f44',
      main: '#db3c30',
      dark: '#c5362b',
      contrastText: '#ffffff'
    }
  },
  secondary: {
    main: '#efeff1'
  },
  error: {
    main: '#f44336'
  },
  background: {
    main: '#fafafa'
  },
  title: {
    main: '#43425D'
  },
  chipColor: {
    orange: '#ff6f0b',
    blue: '#87AAE1',
    green: '#43CBA1',
    red: 'f44336'
  }
};

export const muiTheme = createMuiTheme({
  palette: {
    primary: palette.primary,
    secondary: palette.secondary,
    error: palette.error,
    chipColor: palette.chipColor,
    title: palette.title
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: "'Inter', sans-serif"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "'Inter', sans-serif"
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: "'Inter', sans-serif"
      }
    },
    MuiChip: {
      root: {
        fontFamily: "'Inter', sans-serif"
      }
    },
    MuiInput: {
      root: {
        fontFamily: "'Inter', sans-serif"
      }
    }
  }
});
