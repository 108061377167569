export const SET_MESSAGE_LOG = 'spreadsheet/SET_MESSAGE_LOG';
export const SET_LOADING = 'spreadsheet/SET_LOADING';
export const CLEAN_MESSAGE_LOG = 'spreadsheet/CLEAN_MESSAGE_LOG';
export const SET_MODAL = 'spreadsheet/SET_MODAL';
export const SET_INTEGRATION_STATUS_DATA = 'spreadsheet/SET_INTEGRATION_STATUS_DATA';
export const SET_INTEGRATION_FETCH_STATUS = 'spreadsheet/SET_INTEGRATION_FETCH_STATUS';
export const SET_LOADING_INTEGRATION_STATUS = 'spreadsheet/SET_LOADING_INTEGRATION_STATUS';
export const setMessageLog = message => ({
  type: SET_MESSAGE_LOG,
  payload: message
});

export const setLoading = loading => ({
  type: SET_LOADING,
  payload: loading
});

export const setModal = modal => ({
  type: SET_MODAL,
  payload: modal
});

export const cleanMessageLog = () => ({
  type: CLEAN_MESSAGE_LOG
});

export const setIntegrationStatusData = rows => ({
  type: SET_INTEGRATION_STATUS_DATA,
  payload: rows
});

export const setIntegrationFetchStatus = rows => ({
  type: SET_INTEGRATION_FETCH_STATUS,
  payload: rows
});

export const setLoadingIntegrationStatus = status => ({
  type: SET_LOADING_INTEGRATION_STATUS,
  payload: status
});
