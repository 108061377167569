import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import proposalManager from '../proposalManager';
import { routes } from '../../../shared/constants/routes';
import Modal from '../../../shared/components/Modal';
import { muiIcons } from '../../../shared/constants/icons';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px 0px 20px 0'
  },
  button: {
    width: '235px',
    height: 50,
    textAlign: 'center',
    fontSize: '14px',
    margin: '10px 0 5px 0',
    borderRadius: 12
  },
  saveAndExitButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: '1px solid',
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#ff6f0b'
    }
  },
  lastButton: {
    width: '150px',
    color: 'inherit',
    textTransform: 'capitalize'
  }
}));

const useModal = function() {
  const [modalProps, setModalProps] = useState({
    icon: { component: muiIcons.WARNING },
    action: [],
    message: '',
    isOpen: false
  });

  return { modalProps, setModalProps };
};

const ProposalPageButtons = function({
  proposal,
  saveProposal,
  setValidateProposal,
  isLoading,
  cleanProposal,
  setProposalService,
  proposalAccepted,
  acceptProposalAndCreateBill,
  proposalServiceError,
  proposalServiceSucceeded,
  policyServiceError,
  policyServiceSucceded,
  documentUrl,
  proposalNumber,
  billUrl
}) {
  const classes = useStyles();
  const [typeAction, setTypeAction] = useState('');
  const { push } = useHistory();
  const { modalProps, setModalProps } = useModal();

  const handleCleanProposal = useCallback(() => {
    push(`${routes.home.path}`);
    cleanProposal();
    setModalProps({ isOpen: false });
  }, [setModalProps, cleanProposal, push]);

  const handleDownloadProposal = useCallback(() => {
    window.open(`${documentUrl}`, '_self');
  }, [documentUrl]);

  const handleDownloadBill = useCallback(() => {
    window.open(`${billUrl}`, '_self');
  }, [billUrl]);

  const setModal = useCallback(
    ({ actions, message, icon, isOpen }) => {
      setModalProps({
        actions,
        message,
        icon,
        isOpen
      });
    },
    [setModalProps]
  );

  useEffect(() => {
    if (proposalServiceError || policyServiceError) {
      setModal({
        actions: [
          {
            text: 'Voltar ao início',
            isConfirm: false,
            onClick: () => {
              setProposalService({ hasError: false });
              handleCleanProposal();
            }
          },
          {
            text: 'Ok',
            isConfirm: true,
            onClick: () => {
              setProposalService({ hasError: false });
              handleCleanProposal();
            }
          }
        ],
        message: 'Erro! Ocorreu algum problema interno. Tente novamente mais tarde.',
        icon: { component: muiIcons.ERROR },
        isOpen: true
      });
    } else if (proposalServiceSucceeded && proposalAccepted) {
      setModal({
        actions: [
          {
            text: `Proposta de Nº ${proposalNumber}`,
            isConfirm: false,
            onClick: async () => {
              handleDownloadProposal();
            }
          },
          {
            text: 'Baixar o Boleto',
            isConfirm: true,
            onClick: async () => {
              handleDownloadBill();
            }
          }
        ],
        message:
          'Obrigado! A emissão estará disponível após o pagamento do Boleto e avaliação da Seguradora',
        icon: { component: muiIcons.SUCCESS },
        isOpen: true
      });
    } else if (proposalServiceSucceeded && typeAction === 'SAVE') {
      setModal({
        actions: [
          {
            text: 'Download da Proposta',
            isConfirm: true,
            onClick: async () => {
              handleDownloadProposal();
            },
            btnDisabled: isLoading
          },
          {
            text: 'Aceitar a Proposta',
            isConfirm: true,
            onClick: () => {
              acceptProposalAndCreateBill();
              setModalProps({ isOpen: false });
            },
            btnDisabled: isLoading
          }
        ],
        message: 'Proposta salva com sucesso!',
        icon: { component: muiIcons.DOCUMENT },
        isOpen: true
      });
    }
  }, [
    proposalServiceError,
    policyServiceError,
    setModal,
    setModalProps,
    handleCleanProposal,
    setProposalService,
    proposalServiceSucceeded,
    policyServiceSucceded,
    typeAction,
    proposalAccepted,
    acceptProposalAndCreateBill,
    proposal.id,
    isLoading,
    handleDownloadProposal,
    handleDownloadBill,
    proposalNumber
  ]);

  const handleSaveProposal = () => {
    const isValid = proposalManager.validateProposal({ proposal });

    if (isValid) {
      if (!proposal.clientAware) {
        setModal({
          actions: [
            {
              text: 'Ok',
              isConfirm: true,
              onClick: () => {
                setModalProps({ isOpen: false });
              }
            }
          ],
          message:
            'Você precisa aceitar os termos de contrato antes de prosseguir! Marque o checkbox ao final da página.',
          icon: { component: muiIcons.WARNING },
          isOpen: true
        });
      } else {
        saveProposal();
      }
    } else {
      setValidateProposal(true);
      setModal({
        actions: [
          {
            text: 'Ok',
            isConfirm: true,
            onClick: () => {
              setModalProps({ isOpen: false });
            }
          }
        ],
        message: 'Existem alguns campos inválidos ou vazios! Favor inserí-los ou corrigí-los.',
        icon: { component: muiIcons.WARNING },
        isOpen: true
      });
    }
  };

  return (
    <>
      <Grid container direction="row-reverse" className={classes.container}>
        <Grid item sm={1}></Grid>

        <Grid item xs={12} sm={3}>
          <Grid container justify="center">
            <Grid item>
              <Button
                disabled={isLoading}
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => {
                  handleSaveProposal();
                  setTypeAction('SAVE');
                }}
              >
                Salvar proposta
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Grid container justify="center">
            <Grid item>
              <Button
                disabled={isLoading}
                className={clsx(classes.button, classes.lastButton)}
                onClick={() => {
                  setModal({
                    actions: [
                      {
                        text: 'Cancelar',
                        isConfirm: false,
                        onClick: () => {
                          setModalProps({ isOpen: false });
                        }
                      },
                      {
                        text: 'Sair da emissão',
                        isConfirm: true,
                        onClick: () => {
                          handleCleanProposal();
                        }
                      }
                    ],
                    message:
                      'Deseja realmente sair da proposta atual? Todos os dados não salvos serão perdidos!',
                    icon: { component: muiIcons.WARNING },
                    isOpen: true
                  });
                }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        icon={modalProps.icon}
        message={modalProps.message}
        actions={modalProps.actions}
        open={modalProps.isOpen}
        onClose={() => {
          setModalProps({ isOpen: false });
          if (proposalServiceError) setProposalService({ hasError: false });
          else if (proposalServiceSucceeded && proposalAccepted) handleCleanProposal();
        }}
      />
    </>
  );
};

export default ProposalPageButtons;
