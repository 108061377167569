import * as spreadsheetActions from './spreadsheetActions';
import { fetchStatus } from '../../../shared/constants/constants';

export const INITIAL_STATE = {
  messagesLog: [],
  loading: false,
  modalIsOpen: false,
  integration: {
    rows: [],
    fetchStatus: fetchStatus.pending,
    loading: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case spreadsheetActions.SET_MESSAGE_LOG:
      return { ...state, messagesLog: [...state.messagesLog, action.payload] };
    case spreadsheetActions.CLEAN_MESSAGE_LOG:
      return { ...state, messagesLog: [] };
    case spreadsheetActions.SET_LOADING:
      return { ...state, loading: action.payload };
    case spreadsheetActions.SET_MODAL:
      return { ...state, modalIsOpen: action.payload };
    case spreadsheetActions.SET_INTEGRATION_STATUS_DATA:
      return {
        ...state,
        integration: {
          ...state.integration,
          rows: action.payload
        }
      };
    case spreadsheetActions.SET_INTEGRATION_FETCH_STATUS:
      return {
        ...state,
        integration: {
          ...state.integration,
          fetchStatus: action.payload
        }
      };
    case spreadsheetActions.SET_LOADING_INTEGRATION_STATUS:
      return {
        ...state,
        integration: {
          ...state.integration,
          loading: action.payload
        }
      };
    default:
      return state;
  }
};
