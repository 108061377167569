import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { palette } from '../../shared/styles/theme';

const useStepIconStyles = makeStyles(theme => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: theme.palette.primary.main
  },
  circle: {
    width: '0.9em',
    height: '0.9em',
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 16
  }
}));

function StepIcon({ active, completed }) {
  const classes = useStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <CheckCircle className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

const useCustomStepper = makeStyles(theme => ({
  root: {
    backgroundColor: palette.background.main
  }
}));

const CustomStepper = function({ stepperSize, activeStep }) {
  const classes = useCustomStepper();
  return (
    <Stepper className={classes.root} activeStep={activeStep} alternativeLabel connector={<></>}>
      {_.times(stepperSize, id => (
        <Step data-testid="step" key={id}>
          <StepLabel StepIconComponent={StepIcon}></StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

CustomStepper.propTypes = {
  stepperSize: PropTypes.number,
  activeStep: PropTypes.number
};

export default CustomStepper;
