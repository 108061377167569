import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { subDays } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import isValid from 'date-fns/isValid';
import addYears from 'date-fns/addYears';
import CollapsableBlock from '../../../shared/components/CollapsableBlock';
import { ReactComponent as Calendar } from '../../../shared/customSvgs/calendar.svg';

const useStyles = makeStyles({
  datePicker: {
    width: '100%',
    fontWeight: 400
  }
});

function CustomDatePicker(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
      <KeyboardDatePicker
        {...props}
        autoOk
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        minDate={subDays(new Date(), 30)}
        invalidDateMessage="Data inválida"
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

const handleSetStartDate = ({ setPolicyPeriod }) => date => {
  const dateNow = isValid(date) ? new Date(date.setHours(0, 0, 0, 0)) : null;
  const dateYearFromNow = isValid(date) ? addYears(dateNow, 1) : null;
  setPolicyPeriod({ start: dateNow, end: dateYearFromNow });
};

export const validateInput = ({ send, date }) => {
  return send && !isValid(date);
};

function PolicyPeriodBlock({ policyPeriod: { start, end }, validateProposal, setPolicyPeriod }) {
  const classes = useStyles();

  return (
    <CollapsableBlock title="Vigência do Seguro" icon={{ component: Calendar }}>
      <Grid container justify="space-around">
        <Grid item xs={12} sm={5}>
          {/*
            // TODO import this component from shared
          */}
          <CustomDatePicker
            required
            error={validateInput({ send: validateProposal, date: start })}
            helperText={
              validateInput({ send: validateProposal, date: start }) ? 'Campo obrigatório' : null
            }
            className={classes.datePicker}
            label="Início da vigência"
            value={start}
            onChange={handleSetStartDate({ setPolicyPeriod })}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          {/*
            // TODO import this component from shared
          */}
          <CustomDatePicker
            disabled
            className={classes.datePicker}
            label="Final da vigência"
            value={end}
          />
        </Grid>
      </Grid>
    </CollapsableBlock>
  );
}

PolicyPeriodBlock.propTypes = {
  policyPeriod: PropTypes.object.isRequired,
  validateProposal: PropTypes.bool.isRequired,
  setPolicyPeriod: PropTypes.func.isRequired
};

export default PolicyPeriodBlock;
