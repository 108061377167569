import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { RadioQuestionOption } from './RadioQuestionOption';
import QuestionButtons from './QuestionButtons';
import { muiTheme } from '../../../shared/styles/theme';
import { useActiveQuestion, useStepperActions, useAnswer } from '../redux/quoteCustomHook';

const useClasses = makeStyles({
  root: {
    paddingBottom: 20
  },
  radioGroup: {
    flexDirection: 'column',
    '&> *:not(:last-child)': {
      marginBottom: 16
    },
    [muiTheme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      '&> *:not(:last-child)': {
        marginBottom: 0,
        marginRight: 16
      }
    }
  },
  buttons: {
    marginTop: 16,
    [muiTheme.breakpoints.up('sm')]: {
      marginTop: 40
    }
  }
});

export const RadioQuestion = () => {
  const question = useActiveQuestion();
  const { answer, setAnswer } = useAnswer(question.propertyName);
  const { nextQuestion, previousQuestion } = useStepperActions();
  const classes = useClasses();

  const handleChange = useCallback(event => setAnswer(event.target.value), [setAnswer]);

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Grid className={classes.radioGroup} container justify="center" alignItems="center">
          {question.options.map(option => (
            <RadioQuestionOption
              key={option.value}
              name={question.propertyName}
              checked={answer === option.value}
              onChange={handleChange}
              {...option}
            />
          ))}
        </Grid>
      </Grid>
      <Grid className={classes.buttons} item xs={12}>
        <Grid container justify="center" alignItems="center">
          <QuestionButtons
            text="Continuar"
            disabled={!answer}
            nextQuestion={nextQuestion}
            previousQuestion={previousQuestion}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
