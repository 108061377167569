import { batch } from 'react-redux';
import * as proposalActions from './proposalActions';
import { setQuoteService } from '../../quote/redux/quoteActions';
import proposalManager from '../proposalManager';
import quoteManager from '../../quote/quoteManager';
import { authManager } from '../../auth/authManager.ig';
import { DocumentType } from '../../../shared/constants/constants';

const getPersonAndDispatchAction = ({ id, document, action }) => {
  const getPerson = () =>
    id ? proposalManager.getPersonById(id) : proposalManager.getPersonByDocument(document);

  return async dispatch => {
    try {
      const person = await getPerson();

      dispatch(action(person));
    } catch {
      dispatch(proposalActions.setPersonServiceError({ personServiceError: true }));
    }
  };
};

export const getInsuredPerson = document =>
  getPersonAndDispatchAction({
    document,
    action: proposalActions.setUserInsured
  });

export const getBeneficiaryPerson = document =>
  getPersonAndDispatchAction({
    document,
    action: proposalActions.setUserBeneficiary
  });

const isSusepDocument = document => document.type === DocumentType.SusepRegistry;

const getSusepNumberFromPerson = person => person.documents?.find(isSusepDocument)?.documentNumber;

export const getBroker = id =>
  getPersonAndDispatchAction({
    id,
    action: person =>
      proposalActions.setBroker({
        ...person,
        susep: getSusepNumberFromPerson(person)
      })
  });

export const getPolicyOWner = id =>
  getPersonAndDispatchAction({
    id,
    action: proposalActions.setPolicyOwner
  });

export const getUserProfile = async () => {
  const user = await authManager.getUser();
  const userProfile = await proposalManager.getUserProfile({
    organizationId: user.profile.policy_owner_id
  });

  return userProfile;
};

export const getAddress = zipCode => async dispatch => {
  const address = await proposalManager.getAddress({ zipCode });

  dispatch(proposalActions.setAddress(address));
};

export const getQuote = ({ quoteId }) => async dispatch => {
  const productId = window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_ID;

  try {
    const [responseQuote, product] = await Promise.all([
      quoteManager.getQuote({ quoteId, productId }),
      quoteManager.getProduct(productId)
    ]);
    dispatch(proposalActions.setQuote({ ...responseQuote, product }));
  } catch {
    dispatch(setQuoteService({ hasError: true }));
  }
};

export const saveProposal = () => async (dispatch, getState) => {
  const { proposal } = getState();
  const { userInsured, userBeneficiary } = proposal;

  try {
    dispatch(proposalActions.setLoadingProposal(true));

    const [createdUserInsured, createdUserBeneficiary] = await proposalManager.createPeople(
      userInsured,
      userBeneficiary
    );

    batch(() => {
      dispatch(proposalActions.setUserInsured(createdUserInsured));
      dispatch(proposalActions.setUserBeneficiary(createdUserBeneficiary));
    });

    const user = await authManager.getUser();

    const createdProposal = await proposalManager.createProposal({
      ...proposal,
      userId: user.profile.person_id,
      userInsured: createdUserInsured,
      userBeneficiary: createdUserBeneficiary
    });
    dispatch(proposalActions.setProposalCreated(createdProposal));

    const proposalPdf = await proposalManager.createProposalPdf({ proposalId: createdProposal.id });

    dispatch(proposalActions.setProposalDocumentName(proposalPdf.documentName));

    const documentResponse = await proposalManager.getDocumentUrl({
      documentFileName: proposalPdf.documentName,
      templateKey: 'fireinsurance-proposal'
    });

    batch(() => {
      dispatch(proposalActions.setProposalDocumentUrl(documentResponse.url));
      dispatch(proposalActions.setProposalService({ hadSucceeded: true }));
    });
  } catch {
    dispatch(proposalActions.setProposalService({ hasError: true }));
  } finally {
    dispatch(proposalActions.setLoadingProposal(false));
  }
};

export const acceptProposalAndCreateBill = () => async (dispatch, getState) => {
  const { proposal } = getState();

  try {
    dispatch(proposalActions.setLoadingProposal(true));

    await proposalManager.addPaymentToProposal({
      proposalId: proposal.id,
      userInsured: proposal.userInsured,
      amount: proposal.quote.premium,
      product: proposal.quote.product,
      address: proposal.address
    });

    const user = await authManager.getUser();

    const acceptResponse = await proposalManager.acceptProposal({
      proposalId: proposal.id,
      userId: user.profile.person_id
    });

    const documentFileName = acceptResponse.receipts[0].documentName;

    const documentResponse = await proposalManager.getDocumentUrl({
      documentFileName,
      templateKey: 'boleto'
    });

    batch(() => {
      dispatch(proposalActions.setBillFileName(documentFileName));
      dispatch(proposalActions.setBillUrl(documentResponse.url));
      dispatch(proposalActions.setAcceptedProposal(true));
      dispatch(proposalActions.setProposalService({ hadSucceeded: true }));
    });
  } catch (err) {
    dispatch(proposalActions.setProposalService({ hasError: true }));
  } finally {
    dispatch(proposalActions.setLoadingProposal(false));
  }
};
