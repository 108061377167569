import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { palette } from '../styles/theme';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: 10,
    [theme.breakpoints.down('sm')]: {
      width: 56,
      height: 56
    },
    [theme.breakpoints.up('sm')]: {
      width: 70,
      height: 70
    }
  },
  title: {
    marginLeft: 6,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 20px',
      borderRadius: '30px 30px 30px 0'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '23px 70px',
      borderRadius: '30px 30px 30px 0'
    },
    backgroundColor: palette.secondary.main
  },
  typography: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    },
    fontFamily: '"Inter", sans-serif',
    fontSize: 18
  },
  children: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 30
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 50
    }
  }
}));

const Question = function({ avatar, question, children }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Avatar alt="Avatar" src={avatar} className={classes.avatar} />
          <div className={classes.title}>
            <Typography className={classes.typography}>
              {question ? question.title : '...'}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {children && (
        <Grid item xs={12}>
          <Grid className={classes.children} container justify="center" alignItems="center">
            {children}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

Question.propTypes = {
  avatar: PropTypes.string.isRequired,
  question: PropTypes.object,
  children: PropTypes.element
};

export default Question;
