import groupBy from 'lodash/groupBy';

export const splitDuplicateRows = rows => {
  const groupRows = groupBy(rows, row => {
    return `${row[15]}${row[16]}${row[17]}${row[18]}${row[20]}`;
  });
  const uniqueRows = Object.values(groupRows)
    .filter(e => e.length === 1)
    .map(item => item[0]);
  const duplicateRows = Object.values(groupRows)
    .filter(e => e.length > 1)
    .reduce((acc, val) => [...acc, ...val], []);
  return [uniqueRows, duplicateRows];
};
