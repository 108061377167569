import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CoveragesBlock from './CoveragesBlock';
import { getIncludedCoverages } from '../redux/proposalSelectors';

const mapStateToProps = ({
  proposal: {
    quote: { coverages }
  }
}) => {
  return {
    includedCoverages: getIncludedCoverages({ coverages })
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoveragesBlock);
