import axios from 'axios';

class SpreadsheetService {
  createPolicies = async ({ policies }) => {
    const policyResponse = await axios.post(
      `${window.env.REACT_APP_PARTNERINTEGRATION_ENDPOINT}/api/policies/batch`,
      policies
    );
    return policyResponse.data;
  };

  getIntegrationStatus = async () => {
    const integrationStatusResponse = await axios.get(
      `${window.env.REACT_APP_PARTNERINTEGRATION_ENDPOINT}/api/integration_status`
    );
    return integrationStatusResponse.data;
  };
}

export default new SpreadsheetService();
