import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { icons, getIconFromImages } from '../../../../shared/constants/icons';
import CustomMoneyInput from '../../../../shared/components/MoneyInput';
import { palette } from '../../../../shared/styles/theme';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: 15
  },
  item: {
    width: '100%'
  },
  icon: {
    width: 60,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 50
    }
  },
  title: {
    fontSize: 16,
    color: palette.title.main,
    marginTop: 5,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1.4'
    }
  },
  type: {
    fontSize: 10,
    color: '#A7A7A7',
    marginTop: 10,
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  }
}));

const CoverageRow = function({ coverage }) {
  const classes = useStyles();

  const { type, name, insuredAmount } = coverage;

  let icon = '';

  if (type) icon = type === 'BASIC' ? 'FIRE' : 'HAND_COIN';
  else icon = 'FIRE';

  return (
    <Grid container className={classes.item}>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <img className={classes.icon} src={getIconFromImages(icons[icon])} alt={name} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.type}>
              {type === 'BASIC' ? 'Cobertura Básica' : 'Cobertura Inclusa'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.title}>{name}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item>
            <CustomMoneyInput
              value={insuredAmount}
              onChange={() => {}}
              showInputLimits={false}
              noPointerEvents={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CoverageRow.propTypes = {
  coverage: PropTypes.object.isRequired
};

export default CoverageRow;
