import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CpfCnpjInput from '../../../shared/components/CpfCnpjInput';
import { isValidCpf, isValidCnpj } from '../../../shared/helper/cpfCnpjImportHelper';
import { DocumentDetailsButton } from './details/DocumentDetailsButton';
import proposalManager from '../../proposal/proposalManager';
import {
  setPolicyOwnerId,
  setLoadingDocument,
  setDocumentNumber,
  setLabelName,
  setPolicySequentialNumber,
  setPolicyNumber
} from '../redux/documentActions';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentNumber, useLabelName, usePolicyNumber } from '../redux/documentCustomHooks';
import { POLICY_NUMBER_SIZE } from '../../../shared/constants/constants';

const useStyles = makeStyles(() => ({
  input: {
    fontWeight: 500,
    marginTop: 10
  },
  inputAlign: {
    fontWeight: 500,
    marginTop: 25
  },
  button: {
    width: 130,
    height: 33,
    marginTop: 1,
    marginLeft: 10
  },
  buttonAlign: {
    width: 130,
    height: 33,
    marginTop: 16,
    marginLeft: 10
  },
  form: {
    display: 'flex'
  }
}));

const PolicyOwnerInput = function() {
  const documentNumber = useDocumentNumber();
  const isLoading = useSelector(state => state.document.isLoading);
  const classes = useStyles();
  const label = 'CPF / CNPJ';
  const inputLabel = useLabelName();
  const dispatch = useDispatch();
  const policyNumber = usePolicyNumber();

  const validateDocument = documentNumber =>
    (!documentNumber && policyNumber) ||
    (documentNumber && (isValidCpf(documentNumber) || isValidCnpj(documentNumber)));

  const validatePolicyNumber = policyNumber =>
    (!policyNumber && documentNumber) || policyNumber.length === POLICY_NUMBER_SIZE;

  const search = async documentNumber => {
    dispatch(setLoadingDocument(true));

    if (documentNumber) {
      var policyOwner = await proposalManager.getPersonByDocument(documentNumber);
      dispatch(setLabelName(policyOwner.name ?? label));
      var policyOwnerId = policyOwner.id ? policyOwner.id : undefined;
      dispatch(setPolicyOwnerId(policyOwnerId));
    } else if (policyNumber) {
      dispatch(setLoadingDocument(true));
      var sequentialNumber = policyNumber.substring(15, policyNumber.length - 3);
      dispatch(setPolicySequentialNumber(sequentialNumber));
    }
  };

  const onChange = documentNumber => {
    dispatch(setDocumentNumber(documentNumber));
    dispatch(setLabelName(label));
  };

  const onBlur = () => {
    dispatch(setPolicyNumber(''));
    dispatch(setPolicySequentialNumber(''));
  };

  return (
    <Grid item className={classes.form} xs={12}>
      <Grid item xs={2}>
        <InputLabel className={documentNumber ? classes.inputAlign : classes.input}>
          Estipulante
        </InputLabel>
      </Grid>
      <Grid item xs={7}>
        <CpfCnpjInput
          value={documentNumber}
          label={inputLabel}
          placeholder="Busque pelo documento do estipulante"
          onChange={onChange}
          onBlur={onBlur}
          helperError={
            !validateDocument(documentNumber) && documentNumber ? 'Campo obrigatório' : ''
          }
        />
      </Grid>
      <Grid item xs={3}>
        <DocumentDetailsButton
          disabled={
            !validateDocument(documentNumber) || isLoading || !validatePolicyNumber(policyNumber)
          }
          color="primary"
          variant="contained"
          className={documentNumber ? classes.buttonAlign : classes.button}
          onClick={() => search(documentNumber)}
        >
          {'Pesquisar'}
        </DocumentDetailsButton>
      </Grid>
    </Grid>
  );
};

export default PolicyOwnerInput;
