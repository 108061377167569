import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import configureStore, { history } from './store';
import ErrorBoundary from './shared/components/ErrorBoundary.ig';
import CallbackPage from './features/auth/views/CallbackPage.ig';
import Login from './features/auth/views/Login.ig';
import NewQuotePage from './features/quote/views/NewQuotePage';
import NewQuoteCoveragesPage from './features/quote/views/NewQuoteCoveragesPage';
import Welcome from './features/welcome/views/Welcome';
import SpreadsheetImport from './features/spreadsheet/views/SpreadsheetPage';
import ProposalFormPage from './features/proposal/views/ProposalFormPage';
import { muiTheme, palette } from './shared/styles/theme';
import { routes } from './shared/constants/routes';
import withMenu from './shared/hoc/withMenu';
import withLogo from './shared/hoc/withFloatingLogo';
import ErrorLogin from './features/auth/views/ErrorLogin.ig';
import ProposalDetailsPage from './features/document/views/ProposalDetailsPage';
import ProposalsDocumentPage from './features/document/views/ProposalsDocumentPage';
import PoliciesDocumentPage from './features/document/views/PoliciesDocumentPage';
import SignoutCallbackPage from './features/auth/views/SignoutCallbackPage.ig';
import SilentRenewPage from './features/auth/views/SilentRenewPage.ig';
import PolicyDetailsPage from './features/document/views/PolicyDetailsPage';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflowX: 'hidden',
    height: '100%',
    backgroundColor: palette.background.main,
    backgroundImage: `url("${process.env.PUBLIC_URL + '/images/bg_houses.png'}")`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'repeat-x',
    backgroundSize: 1200
  }
});

const store = configureStore();

const App = function() {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <div className={classes.root} id={'rootApp'}>
          <ConnectedRouter history={history}>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/callback" component={CallbackPage} />
                <Route exact path="/error-login" component={ErrorLogin} />
                <Route exact path="/signout-callback" component={SignoutCallbackPage} />
                <Route exact path="/silent-renew" component={SilentRenewPage} />
                <Route path="*">
                  <Login>
                    <Switch>
                      <Route exact path={routes.home.path} render={withMenu(Welcome)} />
                      <Route
                        exact
                        path={routes.newQuote.path}
                        render={withLogo(withMenu(NewQuotePage))}
                      />
                      <Route
                        exact
                        path={routes.newQuoteCoverage.path}
                        render={withLogo(withMenu(NewQuoteCoveragesPage))}
                      />
                      <Route
                        exact
                        path={`${routes.proposal.path}/${routes.proposal.params.quoteId}`}
                        render={withLogo(withMenu(ProposalFormPage))}
                      />
                      <Route
                        exact
                        path={routes.proposalsDocuments.path}
                        render={withLogo(withMenu(ProposalsDocumentPage))}
                      />
                      <Route
                        exact
                        path={routes.policiesDocuments.path}
                        render={withLogo(withMenu(PoliciesDocumentPage))}
                      />
                      <Route
                        exact
                        path={`${routes.proposalsDocuments.path}/${routes.proposalsDocuments.params.proposalId}`}
                        render={withLogo(withMenu(ProposalDetailsPage))}
                      />
                      <Route
                        exact
                        path={`${routes.policiesDocuments.path}/${routes.policiesDocuments.params.policyId}`}
                        render={withLogo(withMenu(PolicyDetailsPage))}
                      />
                      <Route
                        exact
                        path={routes.spreadsheet.path}
                        render={withLogo(withMenu(SpreadsheetImport))}
                      />
                      <Redirect path="*" to={routes.home.path} />
                    </Switch>
                  </Login>
                </Route>
              </Switch>
            </ErrorBoundary>
          </ConnectedRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
