import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    width: 200,
    fontSize: 12
  },
  previousArrowButton: {
    position: 'absolute',
    left: 100,
    top: '50%',
    transform: 'translate(0%, -50%)',
    fontSize: '40px',
    color: '#bfc1c2',
    cursor: 'pointer'
  },
  previousButtonContainer: {
    marginTop: 20
  },
  previousButton: {
    color: '#2B3034'
  }
}));

export const QuestionButtons = function({ width, nextQuestion, previousQuestion, disabled, text }) {
  const classes = useStyles();
  return (
    <Grid container>
      {nextQuestion && (
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="center">
            <Button
              role="nextButton"
              className={classes.button}
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={nextQuestion}
            >
              {text}
            </Button>
          </Grid>
        </Grid>
      )}

      {previousQuestion && width === 'xs' ? (
        <Grid item xs={12}>
          <Grid
            className={classes.previousButtonContainer}
            container
            justify="center"
            alignItems="center"
          >
            <Button
              role="previousButton"
              className={clsx(classes.button, classes.previousButton)}
              color="primary"
              onClick={previousQuestion}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      ) : (
        <ArrowBackIos
          role="previousButton"
          className={classes.previousArrowButton}
          onClick={previousQuestion}
        />
      )}
    </Grid>
  );
};

QuestionButtons.propTypes = {
  width: PropTypes.string.isRequired,
  nextQuestion: PropTypes.func,
  previousQuestion: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string
};

export default withWidth()(QuestionButtons);
