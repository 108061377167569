import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { useTabState } from '../redux/documentCustomHooks';

const useStyles = makeStyles(theme => ({
  tabs: {
    borderBottom: '1px solid rgba(215,225,233,0.7)'
  },
  tabLabel: {
    fontSize: 16,
    textTransform: 'capitalize'
  }
}));

const DocumentTabs = function() {
  const { tabState, setTabState } = useTabState();
  const classes = useStyles();
  const handleChange = (_, newValue) => {
    setTabState(newValue);
  };

  return (
    <Tabs
      textColor="primary"
      indicatorColor="primary"
      value={tabState || 0}
      onChange={handleChange}
      className={classes.tabs}
    >
      <Tab label="Propostas" className={classes.tabLabel} />
      <Tab label="Apólices" className={classes.tabLabel} />
    </Tabs>
  );
};

export default DocumentTabs;
