import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as spreadsheetOperations from '../redux/spreadsheetOperations';
import IntegrationStatusTable from './IntegrationStatusTable';
import RefreshIcon from '@material-ui/icons/Refresh';
import { fetchStatus } from '../../../shared/constants/constants';

const useStyles = makeStyles({
  pageContent: {
    textAlign: 'center',
    padding: '13px 0'
  },
  title: {
    fontSize: '26px',
    marginTop: '5px'
  },
  refreshIcon: {
    marginTop: 15
  }
});

const IntegrationStatusContainer = function() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const integrationStatusData = useSelector(state => state.spreadsheet.integration.rows);
  const integrationFetchStatus = useSelector(state => state.spreadsheet.integration.fetchStatus);
  const integrationStatusLoading = useSelector(state => state.spreadsheet.integration.loading);

  const handleUpdateIntegrationStatus = () => {
    spreadsheetOperations.getIntegrationStatus(dispatch);
  };

  useEffect(() => {
    if (integrationFetchStatus === fetchStatus.pending && !integrationStatusLoading) {
      spreadsheetOperations.getIntegrationStatus(dispatch);
    }
  }, [dispatch, integrationFetchStatus, integrationStatusLoading]);

  return (
    <Grid className={classes.pageContent} container justify="center" alignItems="center">
      <Grid item container direction="row" justify="space-between" xs={12}>
        <Grid item xs={2}></Grid>
        <Grid>
          <Typography className={classes.title}>Status da Integração</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={handleUpdateIntegrationStatus}
            className={classes.refreshIcon}
            endIcon={<RefreshIcon />}
            color="primary"
          >
            Atualizar
          </Button>
        </Grid>
      </Grid>

      <IntegrationStatusTable items={integrationStatusData} isLoading={integrationStatusLoading} />
    </Grid>
  );
};

export default IntegrationStatusContainer;
