import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import { setPolicyNumber, setDocumentNumber, setPolicyOwnerId } from '../redux/documentActions';
import { useDispatch } from 'react-redux';
import { usePolicyNumber, useDocumentNumber } from '../redux/documentCustomHooks';
import NumberFormat from 'react-number-format';
import { POLICY_NUMBER_SIZE } from '../../../shared/constants/constants';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 500,
    marginTop: 10
  },
  buttonContainer: {
    width: 130,
    height: 33
  },
  input: {
    width: 300
  },
  container: {
    display: 'flex',
    marginLeft: 10
  },
  containerAlign: {
    display: 'flex',
    marginLeft: 10,
    marginTop: 16
  }
}));

const NumberFormatCustom = function(props) {
  const { onChange, value, inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={value || ''}
      allowLeadingZeros={true}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isAllowed={values => values.value.length <= POLICY_NUMBER_SIZE}
      getInputRef={inputRef}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const PolicyNumberInput = function() {
  const policyNumber = usePolicyNumber();
  const classes = useStyles();
  const dispatch = useDispatch();
  const documentNumber = useDocumentNumber();

  const onChange = policyNumber => {
    dispatch(setPolicyNumber(policyNumber));
  };

  const onBlur = () => {
    dispatch(setDocumentNumber(''));
    dispatch(setPolicyOwnerId(''));
  };

  return (
    <Grid item className={documentNumber ? classes.containerAlign : classes.container} xs={8}>
      <Grid item xs={5}>
        <InputLabel className={classes.label}>Número da apólice</InputLabel>
      </Grid>
      <Grid item xs={3}>
        <Input
          className={classes.input}
          value={policyNumber}
          placeholder="Busque pelo número da apólice"
          onChange={e => {
            onChange(e.target.value);
          }}
          onBlur={onBlur}
          inputComponent={NumberFormatCustom}
        />
      </Grid>
    </Grid>
  );
};

export default PolicyNumberInput;
