import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CollapsableBlock from '../../../shared/components/CollapsableBlock';
import CpfCnpjInput from '../../../shared/components/CpfCnpjInput';
import Modal from '../../../shared/components/Modal';
import { muiIcons } from '../../../shared/constants/icons';
import { ReactComponent as UserInsured } from '../../../shared/customSvgs/user_insured.svg';
import { isValidCpf, isValidCnpj } from '../../../shared/helper/cpfCnpjImportHelper';

const useStyles = makeStyles(theme => ({
  bloco: {
    margin: '0 auto'
  },
  formControl: {
    paddingBottom: 40
  },
  input: {
    width: '100%',
    fontWeight: 400,
    paddingBottom: 50
  },
  itemInput: {
    height: 60
  },
  secondItemInput: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20
    }
  }
}));

const validateDocument = documentNumber =>
  isValidCpf(documentNumber) || isValidCnpj(documentNumber);

const UserInsuredBlock = function({
  userInsured,
  setUserInsured,
  getInsuredPerson,
  personServiceError,
  setPersonServiceError,
  validateProposal
}) {
  const classes = useStyles();

  const handleBlurInput = () => {
    let documentNumber = userInsured.documentNumber;
    if (validateDocument(documentNumber)) getInsuredPerson(documentNumber);
  };

  return (
    <>
      <CollapsableBlock
        title="Dados do Inquilino"
        icon={{ component: UserInsured }}
        className={classes.formControl}
      >
        <Grid container justify="space-around">
          <Grid item xs={12} sm={5} className={classes.itemInput}>
            <CpfCnpjInput
              value={userInsured.documentNumber}
              label="CPF / CNPJ"
              placeholder="Digite o número do documento"
              className={classes.input}
              onChange={documentNumber => setUserInsured({ ...userInsured, documentNumber })}
              onBlur={handleBlurInput}
              helperError={
                !validateDocument(userInsured.documentNumber) && validateProposal
                  ? 'Campo obrigatório'
                  : ''
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={5} className={clsx(classes.itemInput, classes.secondItemInput)}>
            <TextField
              label="Nome"
              value={userInsured.name}
              className={classes.input}
              disabled={true}
              required={true}
            />
          </Grid>
        </Grid>
      </CollapsableBlock>

      <Modal
        icon={{ component: muiIcons.ERROR }}
        message={'Oops! Tivemos um problema, tente novamente mais tarde :('}
        actions={[
          {
            text: 'OK',
            isConfirm: true,
            onClick: () => {
              setPersonServiceError({ personServiceError: false });
            }
          }
        ]}
        open={personServiceError}
        onClose={() => {
          setPersonServiceError({ personServiceError: false });
        }}
      />
    </>
  );
};

UserInsuredBlock.propTypes = {
  userInsured: PropTypes.object.isRequired,
  setUserInsured: PropTypes.func.isRequired,
  getInsuredPerson: PropTypes.func.isRequired,
  personServiceError: PropTypes.bool.isRequired,
  setPersonServiceError: PropTypes.func.isRequired
};

export default UserInsuredBlock;
