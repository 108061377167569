import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as documentCustomHooks from '../redux/documentCustomHooks';
import * as documentActions from '../redux/documentActions';
import DetailsBackground from './DetailsBackground';
import GeneralInfoBlock from './details/GeneralInfoBlock';
import AddressBlock from './details/AddressBlock';
import ParticipantsBlock from './details/ParticipantsBlock';
import PricePaymentBlock from './details/PricePaymentBlock';
import CoveragesBlock from './details/CoveragesBlock';
import ProposalButtonsBlock from './details/ProposalButtonsBlock';
import Modal from '../../../shared/components/Modal';
import { muiIcons } from '../../../shared/constants/icons';
import { DocumentDetailsSkeleton } from './DocumentDetailsSkeleton';

const ProposalDetailsPage = function() {
  const dispatch = useDispatch();
  const [modalProps, setModalProps] = useState({
    icon: { component: muiIcons.WARNING },
    action: [],
    message: '',
    isOpen: false
  });

  const { proposalId } = useParams();

  const proposal = documentCustomHooks.useProposalDetails(proposalId);
  const {
    acceptProposal,
    downloadProposalPdfUrl,
    downloadProposalBoletoUrl
  } = documentCustomHooks.useOperations();
  const isLoading = documentCustomHooks.useDocumentIsLoading();
  const proposalDocumentStatus = documentCustomHooks.useProposalDocumentStatus();

  useEffect(() => {
    if (proposalDocumentStatus.hasError) {
      setModalProps({
        actions: [
          {
            text: 'OK',
            isConfirm: true,
            onClick: () => {
              dispatch(documentActions.updateDocumentService({ hasError: false }));
              setModalProps({ isOpen: false });
            }
          }
        ],
        message: 'Erro! Ocorreu algum problema interno. Tente novamente mais tarde.',
        icon: { component: muiIcons.ERROR },
        isOpen: true
      });
    }
  }, [proposalDocumentStatus, dispatch]);

  return (
    <DetailsBackground title="Detalhes da Proposta">
      {isLoading || !proposal ? (
        <DocumentDetailsSkeleton />
      ) : (
        <>
          <GeneralInfoBlock
            document={proposal}
            documentType={'Proposta'}
            documentNumber={proposal.proposalNumber}
          />
          <AddressBlock address={proposal.properties.riskLocation} />
          <ParticipantsBlock
            insured={proposal.participants.find(participant => participant.role === 'Insured')}
            beneficiary={proposal.participants.find(
              participant => participant.role === 'Beneficiary'
            )}
            brokers={proposal.commissionedAgents.filter(agent => agent.role === 'Broker')}
          />
          <CoveragesBlock coverages={proposal.coverages} />
          <PricePaymentBlock quote={proposal.quote} />
          <ProposalButtonsBlock
            proposal={proposal}
            isLoading={isLoading}
            acceptProposal={acceptProposal}
            downloadProposalPdfUrl={downloadProposalPdfUrl}
            downloadProposalBoletoUrl={downloadProposalBoletoUrl}
            documentService={proposalDocumentStatus}
          />

          <Modal
            icon={modalProps.icon}
            message={modalProps.message}
            actions={modalProps.actions}
            open={modalProps.isOpen}
            onClose={() => {
              setModalProps({ isOpen: false });
              if (proposalDocumentStatus.hasError) {
                dispatch(documentActions.updateDocumentService({ hasError: false }));
              }
            }}
          />
        </>
      )}
    </DetailsBackground>
  );
};

export default ProposalDetailsPage;
