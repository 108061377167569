import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import CoverageCardGridContainer from './CoverageCardGridContainer';
import Question from '../../../shared/components/Question';
import Stepper from './StepperContainer';
import ReceiptCardContainer from './ReceiptCardContainer';

const useStyles = makeStyles({
  questionContainer: {
    marginTop: 10
  }
});

export const NewQuoteCoveragesPage = function({ width }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item style={{ margin: '30px 0 0 0' }}>
            <Stepper />
          </Grid>
        </Grid>
      </Grid>
      {width !== 'xs' && <Grid item sm={1} />}
      <Grid item xs={12} sm={7}>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              className={classes.questionContainer}
              container
              justify="center"
              direction="column"
            >
              <Grid item>
                <Question
                  avatar={`${process.env.PUBLIC_URL}/images/avatar_assistant.svg`}
                  question={{ title: 'Beleza! Agora é só ajustar as coberturas!' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CoverageCardGridContainer />
          </Grid>
        </Grid>
      </Grid>
      {width !== 'xs' && (
        <Grid item xs={12} sm={4}>
          <ReceiptCardContainer></ReceiptCardContainer>
        </Grid>
      )}
    </Grid>
  );
};

NewQuoteCoveragesPage.propTypes = {};

export default withWidth()(NewQuoteCoveragesPage);
