import React from 'react';

import { useLoginStatus } from '../useLoginStatus';

const Login = ({ children }) => {
  const loggedIn = useLoginStatus();

  return <>{loggedIn && children}</>;
};

export default Login;
