import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { formatToBrlDate } from '../../../shared/functions/formatToBrlDate';
import IntegrationStatusTableCell from './IntegrationStatusTableCell';

const StyledTableRow = withStyles({
  root: {
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:last-child td': {
      borderBottom: 0
    },
    '&:hover': {
      backgroundColor: '#F5F8FB'
    }
  }
})(TableRow);

const IntegrationStatusTableRow = function({ item }) {
  return (
    <StyledTableRow>
      {[
        formatToBrlDate(item.createdAt),
        item.brokerName,
        item.policyOwnerName,
        item.insuredDocumentNumber,
        item.street,
        item.status === 'Failed' ? 'Falha' : 'Pendente'
      ].map((row, index) => (
        <IntegrationStatusTableCell align="left" key={`${item.id}${index}`}>
          {row}
        </IntegrationStatusTableCell>
      ))}
    </StyledTableRow>
  );
};

IntegrationStatusTableRow.propTypes = {
  item: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    brokerName: PropTypes.string.isRequired,
    policyOwnerName: PropTypes.string.isRequired,
    insuredDocumentNumber: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  })
};

export default IntegrationStatusTableRow;
