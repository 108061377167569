import React from 'react';
import { authManager } from '../authManager.ig';

const SignoutCallbackPage = () => {
  const userManager = authManager.getUserManager();

  userManager.signoutRedirectCallback().then(() => userManager.signinRedirect());

  return <></>;
};

export default SignoutCallbackPage;
