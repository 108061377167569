import React from 'react';
import clsx from 'clsx';
import PropType from 'prop-types';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { palette } from '../../shared/styles/theme';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  card: {
    width: 200,
    height: 220,
    textAlign: 'center',
    borderRadius: '44px 44px 0 44px',
    border: '1px solid #DEDEDE'
  },
  icon: {
    width: 55,
    height: 45,
    paddingTop: 25
  },
  inputText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.5,
    height: 50,
    padding: '0 20px 0',
    marginTop: 25,
    marginBottom: 0
  },
  checkbox: {
    fontSize: '1.7rem'
  },
  isChecked: {
    color: palette.secondary.main
  }
}));

const OrangeCheckBox = withStyles({
  root: {
    color: palette.primary.main,
    marginTop: 20,
    fontSize: '1.7rem',
    '&$checked': {
      color: palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#FFF'
    }
  },
  checked: {
    fontSize: '1.7rem'
  }
})(props => <Checkbox color="default" {...props} />);

const InputCheckbox = function({ icone, inputText, checked, onChange }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <img className={classes.icon} src={icone} alt="icon-card" />

      <Typography variant="h4" className={classes.inputText}>
        {inputText}
      </Typography>

      <OrangeCheckBox
        className={clsx(classes.checkbox, { [classes.isChecked]: checked })}
        icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
        checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
        onChange={() => onChange(!checked)}
        checked={checked}
      />
    </Card>
  );
};

InputCheckbox.propTypes = {
  icone: PropType.string.isRequired,
  inputText: PropType.arrayOf(PropType.oneOfType([PropType.string, PropType.object])).isRequired,
  checked: PropType.bool.isRequired,
  onChange: PropType.func.isRequired
};

export default InputCheckbox;
