import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField as BaseTextField, withStyles, makeStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const TextField = withStyles({
  root: {
    marginBottom: 10,
    '& input': {
      fontSize: 14,
      paddingTop: 3
    }
  }
})(BaseTextField);

const useFormHelperTextClasses = makeStyles({
  root: {
    fontSize: '0.585rem'
  }
});

const ReceiptCardParticipationInput = ({
  value: initialValue,
  max,
  disabled,
  error,
  helperText,
  patchCommission
}) => {
  const [value, setValue] = useState(initialValue);
  const formHelperTextClasses = useFormHelperTextClasses();

  const validator = useCallback(({ floatValue = 0 }) => floatValue <= max, [max]);
  const handleValueChange = useCallback(({ floatValue }) => setValue(floatValue), []);
  const handleBlur = useCallback(() => {
    if (value === undefined) {
      setValue(0);
    }

    patchCommission(value || 0).catch(() => setValue(initialValue));
  }, [value, initialValue, patchCommission]);

  return (
    <NumberFormat
      suffix="%"
      thousandSeparator="."
      decimalSeparator=","
      value={value}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onValueChange={handleValueChange}
      onBlur={handleBlur}
      isAllowed={validator}
      customInput={TextField}
      FormHelperTextProps={{ classes: formHelperTextClasses }}
    />
  );
};

ReceiptCardParticipationInput.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
  patchCommission: PropTypes.func.isRequired
};

export default ReceiptCardParticipationInput;
