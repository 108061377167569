import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CollapsableBlock from '../../../../shared/components/CollapsableBlock';
import LabeledText from '../../../../shared/components/LabeledText';
import { muiIcons } from '../../../../shared/constants/icons';
import { DocumentStatusChip } from '../DocumentStatusChip';
import { formatToBrlDate } from '../../../../shared/functions/formatToBrlDate';

const isEmptyString = value => !value || value.length === 0;

const useStyles = makeStyles({
  container: {
    margin: '20px 0px'
  },
  margin: {
    marginBottom: 500
  }
});

const GeneralInfoBlock = function({ document, documentType, documentNumber }) {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={12}>
        <CollapsableBlock
          className={classes.container}
          title="Dados Gerais"
          icon={{ component: muiIcons.DOCUMENT, style: { fill: '#ff6f0b' } }}
        >
          <Grid container justify="space-between" spacing={1}>
            <Grid item xs={6}>
              <LabeledText
                label={`Número ${documentType}`}
                value={isEmptyString(documentNumber) ? '-' : documentNumber}
              />
            </Grid>
            <Grid item xs={2}>
              <LabeledText
                label={'Início da Vigência'}
                value={formatToBrlDate(document.policyPeriodStart)}
              />
            </Grid>
            <Grid item xs={2}>
              <LabeledText
                label={'Final da Vigência'}
                value={formatToBrlDate(document.policyPeriodEnd)}
              />
            </Grid>
            <Grid item xs={2}>
              <LabeledText
                label={'Status'}
                children={<DocumentStatusChip status={document.status} />}
              />
            </Grid>
          </Grid>
        </CollapsableBlock>
      </Grid>
    </Grid>
  );
};

GeneralInfoBlock.propTypes = {
  document: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  documentNumber: PropTypes.string.isRequired
};

export default GeneralInfoBlock;
