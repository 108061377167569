import { connect } from 'react-redux';
import CustomStepper from '../../../shared/components/CustomStepper';

const mapStateToProps = ({ quote }) => {
  return {
    stepperSize: quote.questions.length + 1,
    activeStep: quote.activeQuestion
  };
};

export default connect(mapStateToProps)(CustomStepper);
