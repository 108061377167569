let isValidCpf = null;
let formatCpf = null;
let isValidCnpj = null;
let formatCnpj = null;

if (process.env.NODE_ENV === 'test') {
  const { isValid: _isValidCpf, format: _formatCpf } = require('@fnando/cpf/dist/node');
  const { isValid: _isValidCnpj, format: _formatCnpj } = require('@fnando/cnpj/dist/node');
  isValidCpf = _isValidCpf;
  formatCpf = _formatCpf;
  isValidCnpj = _isValidCnpj;
  formatCnpj = _formatCnpj;
} else {
  const { isValid: _isValidCpf, format: _formatCpf } = require('@fnando/cpf');
  const { isValid: _isValidCnpj, format: _formatCnpj } = require('@fnando/cnpj');
  isValidCpf = _isValidCpf;
  formatCpf = _formatCpf;
  isValidCnpj = _isValidCnpj;
  formatCnpj = _formatCnpj;
}

export { isValidCpf, formatCpf, isValidCnpj, formatCnpj };
