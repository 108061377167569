import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { proposalActions, proposalOperations } from '../redux';
import UserInsuredBlock from './UserInsuredBlock';

const mapStateToProps = ({ proposal }) => {
  return {
    userInsured: { ...proposal.userInsured },
    personServiceError: proposal.status.personServiceError,
    validateProposal: proposal.validateProposal
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserInsured: proposalActions.setUserInsured,
      getInsuredPerson: proposalOperations.getInsuredPerson,
      setPersonServiceError: proposalActions.setPersonServiceError,
      cleanProposal: proposalActions.cleanProposal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserInsuredBlock);
