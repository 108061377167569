import React from 'react';
import PropTypes from 'prop-types';
import { palette } from '../../../shared/styles/theme';
import Chip from '../../../shared/components/Chip';

const chipPropsByDocumentStatus = {
  ACCEPTED: { name: 'Aceita', color: palette.chipColor.blue },
  PENDING: { name: 'Pendente', color: palette.chipColor.orange },
  DONE: { name: 'Emitida', color: palette.chipColor.green },
  ISSUED: { name: 'Emitida', color: palette.chipColor.green },
  EMITTING: { name: 'Em emissão', color: palette.chipColor.blue },
  REJECTED: { name: 'Rejeitada', color: palette.chipColor.red },
  READY: { name: 'Não emitida', color: palette.chipColor.green }
};

export const DocumentStatusChip = ({ status }) => <Chip {...chipPropsByDocumentStatus[status]} />;

DocumentStatusChip.propTypes = {
  status: PropTypes.oneOf(Object.keys(chipPropsByDocumentStatus))
};
