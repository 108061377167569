import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { quoteActions, quoteOperations } from '../redux';
import CoverageCardGrid from './CoverageCardGrid';

const mapStateToProps = ({ quote: { quote, isLoading, status } }) => {
  return {
    coverages: [...quote.coverages],
    isLoading: isLoading,
    quoteServiceError: status.quoteService.hasError
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAnswer: quoteActions.setAnswer,
      getQuote: quoteOperations.getQuote,
      updateCoverage: quoteOperations.updateCoverage,
      setQuoteService: quoteActions.setQuoteService
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CoverageCardGrid);
