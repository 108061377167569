import React from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';

import { icons } from '../../../shared/constants/icons';
import { palette } from '../../../shared/styles/theme';

const useClasses = makeStyles({
  root: {
    height: 220,
    width: 197,
    border: '2px solid #DEDEDE',
    borderRadius: '44px 44px 4px 44px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  title: {
    fontSize: 14,
    letterSpacing: 0.16,
    lineHeight: '28px',
    textAlign: 'center'
  },
  radio: {
    '&$checked': {
      color: palette.primary.main
    }
  },
  checked: {}
});

export const RadioQuestionOption = ({ name, value, title, icon, checked, onChange }) => {
  const classes = useClasses();

  return (
    <Grid item key={value} className={classes.root} data-testid="radio-question-option">
      <img src={`/images/${icons[icon]}`} alt="" />
      <p className={classes.title}>{title}</p>
      <Radio
        classes={{ root: classes.radio, checked: classes.checked }}
        checked={checked}
        name={name}
        value={value}
        onChange={onChange}
      />
    </Grid>
  );
};
