import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';

class ErrorLogin extends React.Component {
  render() {
    return (
      <Grid
        container
        style={{ padding: '25%' }}
        justify="center"
        alignItems="center"
        align="center"
      >
        <Grid item style={{ padding: '20px 0' }}>
          <Typography variant="h4">
            Erro ao realizar o login. Favor entrar em contato conosco.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ErrorLogin);
