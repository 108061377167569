import _ from 'lodash';
import { addDays } from 'date-fns';
import proposalService from './proposalService';
import {
  CPF_SIZE,
  CNPJ_SIZE,
  documentType,
  DEFAULT_BILL_DUEDAYS,
  ProposalType
} from '../../shared/constants/constants';

const getDocumentType = ({ documentNumber }) => {
  if (documentNumber.length <= CPF_SIZE) return documentType.CPF;
  else if (documentNumber.length > CPF_SIZE && documentNumber.length <= CNPJ_SIZE)
    return documentType.CNPJ;
};

const getDateAddingDays = (days = DEFAULT_BILL_DUEDAYS) => {
  const today = new Date();
  const dueDate = addDays(today, days);
  return dueDate.toISOString();
};

const mapCoverages = coverages =>
  coverages
    .map(coverage => ({
      ..._.omit(coverage, ['totalInsuredAmount', 'deductible']),
      insuredAmount: coverage.totalInsuredAmount,
      deductible: coverage.deductible.percent,
      deductibleValue: coverage.deductible.value,
      netPremium: coverage.premium
    }))
    .filter(coverage => coverage.included);

const parsePerson = person => {
  const { id, type, name, documentNumber, addresses, documents, ...remaining } = person;

  return {
    id: id || '',
    documentNumber: documentNumber.trim(),
    name: name,
    type: type,
    address: addresses,
    addresses,
    documents: documents || [],
    ...remaining
  };
};

class ProposalManager {
  getAddress = async ({ zipCode }) => {
    const { cep, logradouro, bairro, localidade, uf } = await proposalService.getAddress(zipCode);

    return {
      zipCode: cep ? cep.replace('-', '') : zipCode,
      street: logradouro || '',
      district: bairro || '',
      city: localidade || '',
      state: uf || '',
      number: '',
      complement: ''
    };
  };

  getPersonByDocument = document => proposalService.getPersonByDocument(document).then(parsePerson);

  getPersonById = id => proposalService.getPersonById(id).then(parsePerson);

  validateProposal = ({ proposal }) => {
    const { address, userInsured, userBeneficiary, policyPeriod } = proposal;

    const fields = [
      ...Object.values(_.omit(address, ['complement'])),
      ...Object.values(_.omit(userInsured, ['id', 'gender'])),
      ...Object.values(_.omit(userBeneficiary, ['id', 'gender'])),
      ...Object.values(policyPeriod)
    ];

    return fields.every(field => field);
  };

  createPerson = async ({ person }) => {
    if (person.id) {
      return person;
    }

    return await proposalService.createPerson({ person });
  };

  createPeople = (...people) => {
    const requestsCache = new Map();

    const requests = people.map(person => {
      if (!requestsCache.has(person.documentNumber)) {
        requestsCache.set(person.documentNumber, this.createPerson({ person }));
      }

      return requestsCache.get(person.documentNumber);
    });

    return Promise.all(requests);
  };

  createProposal = ({
    policyOwner,
    quote,
    policyPeriod,
    address,
    clientAware,
    userInsured,
    userBeneficiary,
    userId
  }) => {
    const commissionedAgents = quote.commissionedAgents;
    const participants = [];
    const quiz = {
      inhabitedProperty: quote.properties.IMOVEL_HABITADO,
      propertyType: quote.properties.TIPO_IMOVEL
    };

    commissionedAgents.push({
      commission: {
        amount: 0,
        percentage: 0
      },
      documentNumber: policyOwner.documentNumber,
      personId: policyOwner.id,
      role: 'PolicyOwner'
    });

    participants.push({
      documentNumber: userInsured.documentNumber,
      personId: userInsured.id,
      role: 'Insured'
    });

    participants.push({
      documentNumber: userBeneficiary.documentNumber,
      personId: userBeneficiary.id,
      role: 'Beneficiary'
    });

    const newProposal = {
      type: ProposalType.Issuance,
      userId,
      product: {
        name: window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_NAME,
        id: window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_ID,
        key: window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_KEY
      },
      quote,
      coverages: mapCoverages(quote.coverages),
      commissionedAgents: commissionedAgents,
      participants: participants,
      policyPeriodStart: policyPeriod.start,
      policyPeriodEnd: policyPeriod.end,
      properties: {
        riskLocation: address,
        quiz: quiz
      },
      aware: clientAware
    };

    return proposalService.createProposal({ proposal: newProposal });
  };

  createProposalPdf = async ({ proposalId }) => {
    return await proposalService.createProposalPdf({ proposalId: proposalId });
  };

  acceptProposal = async ({ proposalId, userId }) => {
    return await proposalService.acceptProposal({
      proposalId: proposalId,
      acceptPayload: { userId: userId }
    });
  };

  addPaymentToProposal = async ({ proposalId, userInsured, amount, product, address }) => {
    const payload = {
      Type: 'Boleto',
      Bank: 'Bradesco',
      Instructions:
        '<b>Instruções de responsabilidade do cedente.<br>*** Valores expressos em R$ ***<br>Após vencimento mora dia R$ 0.34</b>' +
        '<br>NÃO RECEBER APÓS 7 DIAS DE VENCIDO<br>Documento: 9999 / Parcela: 1 de 1',
      DueDate: getDateAddingDays(product.billDueDays),
      InterestAmount: null, // TODO: Get from quote.product
      DelayAmount: null,
      TaxesAmount: null,
      ProviderName: 'Pottencial',
      Amount: amount,
      Currency: 'BRL',
      Options: {},
      Customer: {
        CustomerCode: userInsured.id,
        DocumentType: getDocumentType(userInsured),
        Name: userInsured.name,
        DocumentNumber: userInsured.documentNumber,
        BillingAddress: {
          Street: address.street,
          Number: address.number,
          District: address.district,
          Complement: address.complement,
          City: address.city,
          State: address.state,
          Country: 'Brasil',
          ZipCode: address.zipCode
        }
      }
    };

    return await proposalService.addPaymentProposal({
      proposalId: proposalId,
      paymentPayload: payload
    });
  };

  getDocumentUrl = async ({ documentFileName, templateKey }) => {
    return await proposalService.getDocumentUrl({ documentFileName, templateKey });
  };

  getUserProfile = async organizationId => {
    return await proposalService.getUserProfile(organizationId);
  };

  createPolicy = async ({ proposal }) => {
    const { quote, userInsured, userBeneficiary, broker, policyOwner, policyPeriod } = proposal;

    const policyRequest = {
      product: {
        name: window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_NAME,
        id: window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_ID,
        key: window.env.REACT_APP_FIRE_INSURANCE_PRODUCT_KEY,
        groupCode: window.env.REACT_APP_FIRE_INSURANCE_GROUP_CODE,
        lineCode: window.env.REACT_APP_FIRE_INSURANCE_LINE_CODE
      },
      quote: quote,
      coverages: mapCoverages(quote.coverages),
      insured: userInsured,
      beneficiary: userBeneficiary,
      broker,
      policyOwner,
      policyPeriodStart: policyPeriod.start,
      policyPeriodEnd: policyPeriod.end,
      proposal: {
        id: proposal.id,
        proposalNumber: proposal.proposalNumber
      },
      attributes: {
        riskLocation: proposal.riskLocation
      }
    };

    return await proposalService.createPolicy({ policy: policyRequest });
  };

  issuePolicy = async ({ policyId }) => proposalService.issuePolicy({ policyId: policyId });
}

export default new ProposalManager();
