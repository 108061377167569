export const SET_VALIDATE_PROPOSAL = 'proposal/SET_VALIDATE_PROPOSAL';
export const SET_ACCEPTED_PROPOSAL = 'proposal/SET_ACCEPTED_PROPOSAL';
export const SET_ADDRESS = 'proposal/SET_ADDRESS';
export const SET_USER_INSURED = 'proposal/SET_USER_INSURED';
export const SET_USER_BENEFICIARY = 'proposal/SET_USER_BENEFICIARY';
export const SET_POLICY_PERIOD = 'proposal/SET_POLICY_PERIOD';
export const SET_QUOTE = 'proposal/SET_QUOTE';
export const SET_CLIENT_AWARE = 'proposal/SET_CLIENT_AWARE';
export const SET_PERSON_SERVICE_ERROR = 'proposal/SET_PERSON_SERVICE_ERROR';
export const SET_PROPOSAL_CREATED = 'proposal/SET_PROPOSAL_CREATED';
export const SET_POLICY = 'proposal/SET_POLICY';
export const SET_PROPOSAL_SERVICE = 'proposal/SET_PROPOSAL_SERVICE';
export const SET_POLICY_SERVICE = 'proposal/SET_POLICY_SERVICE';
export const CLEAN_PROPOSAL = 'proposal/CLEAN_PROPOSAL';
export const SET_LOADING_PROPOSAL = 'proposal/SET_LOADING_PROPOSAL';
export const SET_PROPOSAL_DOCUMENT_URL = 'proposal/SET_PROPOSAL_DOCUMENT_URL';
export const SET_PROPOSAL_DOCUMENT_NAME = 'proposal/SET_PROPOSAL_DOCUMENT_NAME';
export const SET_BILL_URL = 'proposal/SET_BILL_URL';
export const SET_BILL_FILENAME = 'proposal/SET_BILL_FILENAME';
export const SET_BROKER = 'proposal/SET_BROKER';
export const SET_POLICY_OWNER = 'proposal/SET_POLICY_OWNER';

export const setValidateProposal = bool => ({
  type: SET_VALIDATE_PROPOSAL,
  payload: bool
});

export const setAcceptedProposal = bool => ({
  type: SET_ACCEPTED_PROPOSAL,
  payload: bool
});

export const setAddress = address => ({
  type: SET_ADDRESS,
  payload: address
});

export const setUserInsured = userInsured => ({
  type: SET_USER_INSURED,
  payload: userInsured
});

export const setUserBeneficiary = userBeneficiary => ({
  type: SET_USER_BENEFICIARY,
  payload: userBeneficiary
});

export const setPolicyPeriod = policyPeriod => ({
  type: SET_POLICY_PERIOD,
  payload: policyPeriod
});

export const setQuote = quote => ({
  type: SET_QUOTE,
  payload: quote
});

export const setClientAware = clientAware => ({
  type: SET_CLIENT_AWARE,
  payload: clientAware
});

export const setProposalCreated = proposal => ({
  type: SET_PROPOSAL_CREATED,
  payload: proposal
});

export const setPolicy = policy => ({
  type: SET_POLICY,
  payload: policy
});

export const setPersonServiceError = personServiceError => ({
  type: SET_PERSON_SERVICE_ERROR,
  payload: personServiceError
});

export const setProposalService = proposalService => ({
  type: SET_PROPOSAL_SERVICE,
  payload: proposalService
});

export const setPolicyService = policyService => ({
  type: SET_POLICY_SERVICE,
  payload: policyService
});

export const cleanProposal = () => ({
  type: CLEAN_PROPOSAL
});

export const setLoadingProposal = isLoading => ({
  type: SET_LOADING_PROPOSAL,
  payload: isLoading
});

export const setProposalDocumentUrl = documentUrl => ({
  type: SET_PROPOSAL_DOCUMENT_URL,
  payload: documentUrl
});

export const setProposalDocumentName = documentName => ({
  type: SET_PROPOSAL_DOCUMENT_NAME,
  payload: documentName
});

export const setBillUrl = billUrl => ({
  type: SET_BILL_URL,
  payload: billUrl
});

export const setBillFileName = billFileName => ({
  type: SET_BILL_FILENAME,
  payload: billFileName
});

export const setBroker = broker => ({
  type: SET_BROKER,
  payload: broker
});

export const setPolicyOwner = policyOwner => ({
  type: SET_POLICY_OWNER,
  payload: policyOwner
});
