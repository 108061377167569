import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import CepInput from '../../../shared/components/CepInput';
import FormInput from './FormInput';
import { ZIP_CODE_SIZE, brazilianStates } from '../../../shared/constants/constants';

export const validateInput = (sent, field) => {
  return sent && field === '';
};

const useStyles = makeStyles(theme => ({
  inputLabel: {
    fontSize: '0.75rem'
  },
  selectItem: {
    marginTop: 29
  },
  selectInput: {
    marginTop: 0,
    width: '100%',
    fontWeight: 400
  }
}));

const AddressInput = function({ address, setAddress, getAddress, validateProposal }) {
  const classes = useStyles();

  const { zipCode, street, complement, number, district, city, state } = address;

  const handleZipCodeBlurInput = ({ target }) => {
    if (target.value && target.value.length === ZIP_CODE_SIZE) getAddress(target.value);
  };

  const handleSetAddress = inputName => e => {
    setAddress({ [inputName]: e.target.value });
  };

  return (
    <>
      <Grid item xs={12} sm={5}>
        <CepInput
          value={zipCode}
          onChange={zipCode => {
            setAddress({ zipCode });
          }}
          label="CEP"
          onBlur={handleZipCodeBlurInput}
          error={validateInput(validateProposal, zipCode)}
          required={true}
        />
        {validateInput(validateProposal, zipCode) && (
          <FormHelperText error={validateInput(validateProposal, zipCode)}>
            Campo obrigatório
          </FormHelperText>
        )}
      </Grid>

      <Grid item xs={12} sm={5}></Grid>
      {[
        { label: 'Endereço', value: street, name: 'street' },
        { label: 'Número', value: number, name: 'number' },
        { label: 'Complemento', value: complement, name: 'complement', required: false },
        { label: 'Bairro', value: district, name: 'district' },
        { label: 'Cidade', value: city, name: 'city' }
      ].map(props => (
        <FormInput
          key={props.name}
          validateProposal={validateProposal}
          setAddress={setAddress}
          required={true}
          {...props}
        />
      ))}

      <Grid item xs={12} sm={5} className={classes.selectItem}>
        <InputLabel
          className={classes.inputLabel}
          required={true}
          error={validateInput(validateProposal, state)}
        >
          Estado
        </InputLabel>
        <Select
          value={state || ''}
          onChange={handleSetAddress('state')}
          className={classes.selectInput}
          required={true}
          error={validateInput(validateProposal, state)}
        >
          {Object.entries(brazilianStates).map(([acronym, stateName]) => {
            return (
              <MenuItem key={acronym} value={acronym}>
                {stateName}
              </MenuItem>
            );
          })}
        </Select>
        {validateInput(validateProposal, state) && (
          <FormHelperText error={validateInput(validateProposal, zipCode)}>
            Campo obrigatório
          </FormHelperText>
        )}
      </Grid>
    </>
  );
};

AddressInput.propTypes = {
  address: PropTypes.object.isRequired,
  setAddress: PropTypes.func.isRequired,
  getAddress: PropTypes.func.isRequired
};

export default React.memo(AddressInput);
