import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import IntegrationStatusTableRow from './IntegrationStatusTableRow';
import IntegrationStatusTableCell from './IntegrationStatusTableCell';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    marginTop: 3
  },
  errorMessage: {
    fontSize: 16,
    color: '#5E6981',
    textAlign: 'center',
    paddingTop: 20
  },
  customTableCell: {
    minWidth: 60,
    padding: 8
  },
  card: {
    width: 400,
    height: 300,
    margin: '0 auto',
    marginTop: 20
  },
  image: {
    width: '100%',
    height: '100%'
  }
});

const IntegrationStatusTable = function({ items, isLoading }) {
  const classes = useStyles();

  if (isLoading) {
    return (
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <div style={{ marginTop: 20 }}>
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
        </div>
      </TableContainer>
    );
  } else if (!isLoading && items?.length) {
    return (
      <>
        <div>{items?.length} itens pendentes</div>
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {[
                  'DATA IMPORTAÇÃO',
                  'CORRETOR',
                  'ESTIPULANTE',
                  'SEGURADO',
                  'ENDEREÇO',
                  'STATUS'
                ].map(title => (
                  <IntegrationStatusTableCell
                    key={title}
                    align="left"
                    className={classes.customTableCell}
                  >
                    {title}
                  </IntegrationStatusTableCell>
                ))}
                <IntegrationStatusTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(item => (
                <IntegrationStatusTableRow item={item} key={item.id}></IntegrationStatusTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
      <p className={classes.errorMessage}>Nenhuma pendência de integração.</p>
    </TableContainer>
  );
};

IntegrationStatusTable.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default IntegrationStatusTable;
