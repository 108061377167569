import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import DocumentTableRow from './DocumentTableRow';
import DocumentTableCell from './DocumentTableCell';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    marginTop: 3
  },
  errorMessage: {
    fontSize: 16,
    color: '#5E6981',
    textAlign: 'center',
    paddingTop: 20
  },
  customTableCell: {
    minWidth: 60,
    padding: 8
  },
  card: {
    width: 400,
    height: 300,
    margin: '0 auto',
    marginTop: 20
  },
  image: {
    width: '100%',
    height: '100%'
  }
});

const DocumentTable = function({ items, isLoading }) {
  const classes = useStyles();

  if (isLoading) {
    return (
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <div style={{ marginTop: 20 }}>
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
        </div>
      </TableContainer>
    );
  } else if (!isLoading && items.length) {
    return (
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {[
                'DATA CRIAÇÃO',
                'NÚMERO',
                'INÍCIO VIGÊNCIA',
                'FIM VIGÊNCIA',
                'INQUILINO',
                'PRÊMIO',
                'STATUS'
              ].map(title => (
                <DocumentTableCell key={title} align="left" className={classes.customTableCell}>
                  {title}
                </DocumentTableCell>
              ))}
              <DocumentTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <DocumentTableRow item={item} key={item.id}></DocumentTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
      <p className={classes.errorMessage}>
        Não encontramos nenhum documento deste tipo vinculado à você :(
      </p>
    </TableContainer>
  );
};

DocumentTable.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default DocumentTable;
