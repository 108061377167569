import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1)
  },
  noPointerEvents: {
    pointerEvents: 'none'
  },
  helperText: {
    fontSize: '0.585rem',
    marginTop: 10,
    textAlign: 'center',
    letterSpacing: 0
  },
  input: {
    color: '#43425D'
  }
}));

export const validateInput = (min, max) => values => {
  const { floatValue } = values;

  const lowerBoundary = _.isNumber(min) ? floatValue >= min : true;
  const upperBoundary = _.isNumber(max) ? floatValue <= max : true;

  return !floatValue || (lowerBoundary && upperBoundary);
};

const NumberFormatCustom = function(props) {
  const { inputRef, onChange, value, min, max, ...other } = props;

  return (
    <NumberFormat
      data-testid="number-format"
      {...other}
      value={value || ''}
      getInputRef={inputRef}
      style={{ textAlign: 'center' }}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isAllowed={validateInput(0, max)}
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      decimalScale={2}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const getHelperText = function(showInputLimits, min, max) {
  const hasMin = showInputLimits && _.isNumber(min);
  const hasMax = showInputLimits && _.isNumber(max);

  const currencyMin = hasMin
    ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
        .format(min)
        .replace(/\D00$/, '')
    : '';
  const currencyMax = hasMax
    ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
        .format(max)
        .replace(/\D00$/, '')
    : '';

  if (hasMin && hasMax) {
    return `De ${currencyMin} até ${currencyMax}`;
  } else if (!hasMin && hasMax) {
    return `Até ${currencyMax}`;
  } else if (hasMin && !hasMax) {
    return `A partir de ${currencyMin}`;
  } else {
    return '';
  }
};

export default function MoneyInput({
  value,
  onChange,
  onBlur,
  min,
  max,
  placeholder,
  showInputLimits,
  disabled,
  noPointerEvents,
  error
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControl
        className={clsx(classes.formControl, { [classes.noPointerEvents]: noPointerEvents })}
      >
        <Input
          error={error}
          className={classes.input}
          placeholder={placeholder || ''}
          value={value}
          onChange={e => {
            onChange(Number(e.target.value));
          }}
          onBlur={onBlur}
          inputComponent={NumberFormatCustom}
          inputProps={{ min: min, max: max }}
          disabled={disabled}
        />
        <FormHelperText error={error} className={classes.helperText}>
          {getHelperText(showInputLimits, min, max)}
        </FormHelperText>
      </FormControl>
    </div>
  );
}

MoneyInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  showInputLimits: PropTypes.bool,
  disabled: PropTypes.bool,
  noPointerEvents: PropTypes.bool
};
