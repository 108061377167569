import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { proposalOperations, proposalActions } from '../redux';
import ProposalPageButtons from './ProposalPageButtons';

const mapStateToProps = ({ proposal }) => {
  return {
    proposal,
    isLoading: proposal.isLoading,
    proposalServiceError: proposal.status.proposalService.hasError,
    proposalServiceSucceeded: proposal.status.proposalService.hadSucceeded,
    proposalAccepted: proposal.acceptedProposal,
    policyServiceError: proposal.status.policyService.hasError,
    policyServiceSucceded: proposal.status.policyService.hadSucceeded,
    documentNumber: proposal.documentNumber,
    documentUrl: proposal.documentUrl,
    proposalNumber: proposal.proposalNumber,
    billUrl: proposal.billUrl
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveProposal: proposalOperations.saveProposal,
      setValidateProposal: proposalActions.setValidateProposal,
      cleanProposal: proposalActions.cleanProposal,
      setProposalService: proposalActions.setProposalService,
      acceptProposalAndCreateBill: proposalOperations.acceptProposalAndCreateBill
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProposalPageButtons);
