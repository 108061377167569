import React from 'react';
import FloatingLogo from '../components/FloatingLogo';
import { zIndex } from '../constants/zIndex';

export default function withMenu(WrappedComponent) {
  return function() {
    return (
      <>
        <WrappedComponent />
        <FloatingLogo zIndex={zIndex.FLOATING_LOGO} />
      </>
    );
  };
}
