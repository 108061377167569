import React from 'react';
import PropTypes from 'prop-types';
import MoneyInputContainer from './MoneyInputContainer';
import MultipleCheckInputContainer from './MultipleCheckInputContainer';
import { questionTypes } from '../../../shared/constants/questionTypes';
import { RadioQuestion } from './RadioQuestion';

const QuestionInputFactory = function({ question }) {
  switch (question.type) {
    case questionTypes.MONEY:
      return <MoneyInputContainer question={question} />;
    case questionTypes.MULTIPLE_CHECK:
      return <MultipleCheckInputContainer question={question} />;
    case questionTypes.RADIO:
      return <RadioQuestion />;
    default:
      return <></>;
  }
};

QuestionInputFactory.propTypes = {
  question: PropTypes.object
};

export default QuestionInputFactory;
